
<template>
  <v-container fluid>
    <v-row>
      <v-col cols='12' sm='12' md='6'>
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>SEUS DADOS BÁSICOS</v-card-title>
          <v-card-subtitle>MANTENHA SEMPRE SEUS DADOS ATUALIZADOS</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            
          </div>
        </v-card>
      </v-col>
      <v-col cols='12' sm='12' md='6'>
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>MUDAR SENHA DE ACESSO</v-card-title>
          <v-card-subtitle>UTILIZE SEMPRE UMA SENHA SEGURA, NÃO INFORME A NINGUEM!</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-form v-model="form.valid" ref="formPass" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.currentPass" type="password" label="SENHA ATUAL" :rules="form.rules.password" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.newPass" type="password" label="SENHA NOVA" :rules="form.rules.password" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.reNewPass" type="password" label="CONFIRME A NOVA SENHA" :rules="form.rules.rePassword" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-divider />
            <v-btn color="#662929" class="white--text mt-4" @click="changePass()">SALVAR NOVA SENHA</v-btn>
            <v-alert v-model="form.alert.show" class="mt-6" dense text dismissible :type="form.alert.type">{{ form.alert.message }}</v-alert>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ConsultantSetup',
  data() {
    return {
      form: {
        valid: true,
        currentPass: '',
        newPass: '',
        reNewPass: '',
        rules: {
          password: [
            v => !!v || 'Senha obrigatória.',
            v => v.length >= 6 || 'Senha não pode conter menos que 6 caracteres.',
          ],
          rePassword: [
            v => v == this.form.newPass || 'As senhas devem ser iguais.',
          ],
        },
        alert: {
          show: false,
          type: '',
          message: ''
        }
      }
    }
  },
  methods: {
    // Alterar senha de acesso. //
    changePass() {
      if (this.$refs.formPass.validate()) {
        this.$axios.put(`/users/pssw`, {
          currentPass: this.form.currentPass,
	        newPass: this.form.newPass
        }).then(success => {
          this.form.alert.type = 'success'
          this.form.alert.message = 'SENHA ALTERADA COM SUCESSO!'
          this.form.alert.show = true
          this.form.currentPass = ''
          this.form.newPass = ''
          this.form.reNewPass = ''
        }).catch(err => {
          this.form.alert.type = 'error'
          this.form.alert.message = 'ERRO AO ALTERAR SENHA!'
          this.form.alert.show = true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>


import Vue from 'vue'
import VueRouter from 'vue-router'

// VueS. //
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Registry from '../views/Registry.vue'
// Candidatos. //
import Candidate from '../views/candidate/Candidate.vue'
// Painel. //
import Dashboard from '../views/dashboard/Dashboard.vue'
import Resume from '../views/dashboard/Resume.vue'
import Users from '../views/dashboard/users/Users.vue'
import User from '../views/dashboard/users/User.vue'
import Recovery from '../views/Recovery.vue'
import NewUser from '../views/dashboard/users/New.vue'
import EditUser from '../views/dashboard/users/Edit.vue'
import Companies from '../views/dashboard/companies/Companies.vue'
import Company from '../views/dashboard/companies/Company.vue'
import SearchCompany from '../views/dashboard/companies/Search.vue'
import ContractCompany from '../views/dashboard/companies/Contract.vue'
import Candidates from '../views/dashboard/candidates/Candidates.vue'
import CandidateJobs from '../views/candidate/Jobs.vue'
import CandidateProfile from '../views/candidate/Profile.vue'
import CandidateSetup from '../views/candidate/Setup.vue'
import Consultant from '../views/consultant/Consultant.vue'
import ConsultantCandidates from '../views/consultant/Candidates.vue'
import ConsultantCandidate from '../views/consultant/Candidate.vue'
import ConsultantJobs from '../views/consultant/Jobs.vue'
import ConsultantAllJobs from '../views/consultant/AllJobs.vue'
import ConsultantJob from '../views/consultant/Job.vue'
import ConsultantClosedJobs from '../views/consultant/ClosedJobs.vue'
import ConsultantNewJob from '../views/consultant/NewJob.vue'
import ConsultantEditJob from '../views/consultant/EditJob.vue'
import ConsultantCompanies from '../views/consultant/Companies.vue'
import ConsultantCompany from '../views/consultant/Company.vue'
import ConsultantSearchCandidade from '../views/consultant/CandidateSearch.vue'
import ConsultantSetup from '../views/consultant/Setup.vue'
import NewCandidate from '../views/dashboard/candidates/New.vue'
import ShowCandidate from '../views/dashboard/candidates/Candidate.vue'
import EditCandidate from '../views/dashboard/candidates/Edit.vue'
import CandidateSearch from '../views/dashboard/candidates/Search.vue'
import NewCompany from '../views/dashboard/companies/New.vue'
import EditCompany from '../views/dashboard/companies/Edit.vue'
import Jobs from '../views/dashboard/jobs/Jobs.vue'
import NewJob from '../views/dashboard/jobs/New.vue'
import EditJob from '../views/dashboard/jobs/Edit.vue'
import SearchJob from '../views/dashboard/jobs/Search.vue'
import ClosedJob from '../views/dashboard/jobs/Closed.vue'
import Job from '../views/dashboard/jobs/Job.vue'
import DetailsJob from '../views/jobs/Details.vue'
import IframeJobs from '../views/jobs/Iframe.vue'
import Reports from '../views/dashboard/reports/Reports.vue'
import ConsultantReports from '../views/consultant/Reports.vue'
import Setup from '../views/dashboard/Setup.vue'
import NewProspects from '../views/dashboard/prospects/New.vue'
import ShowProspects from '../views/dashboard/prospects/Show.vue'
import EditProspects from '../views/dashboard/prospects/Edit.vue'
// Cadastros. //
import Benefits from '../views/dashboard/registrations/Benefits.vue'
import Branches from '../views/dashboard/registrations/Branches.vue'
import Cities from '../views/dashboard/registrations/Cities.vue'
import Computing from '../views/dashboard/registrations/Computing.vue'
import Deadlines from '../views/dashboard/registrations/Deadlines.vue'
import Languages from '../views/dashboard/registrations/Languages.vue'
import Professions from '../views/dashboard/registrations/Professions.vue'
import Salary from '../views/dashboard/registrations/Salary.vue'
import Schooling from '../views/dashboard/registrations/Schooling.vue'
import Tests from '../views/dashboard/registrations/Tests.vue'
import Units from '../views/dashboard/registrations/Units.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Grupo Resolve - Vagas.'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Grupo Resolve - Acesso ao Sistema.'
    }
  },
  {
    path: '/registry',
    name: 'Registry',
    component: Registry,
    meta: {
      title: 'Grupo Resolve - Cadastro de Candidato.'
    }
  },
  {
    path: '/recovery/:token',
    name: 'Recovery',
    component: Recovery,
    props: true,
    meta: {
      title: 'Grupo Resolve - Recuperação de Senha.'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: '/',
        name: 'Resume',
        component: Resume,
        meta: {
          title: 'Grupo Resolve - Painel de Controle.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
        meta: {
          title: 'Grupo Resolve - Usuários.',
          auth: [4, 6]
        }
      },
      {
        path: 'users/new',
        name: 'NewUser',
        component: NewUser,
        meta: {
          title: 'Grupo Resolve - Novo Usuário.',
          auth: [4, 6]
        }
      },
      {
        path: 'users/:id/edit',
        name: 'EditUser',
        component: EditUser,
        props: true,
        meta: {
          title: 'Grupo Resolve - Edição de Usuário.',
          auth: [4, 6]
        }
      },
      {
        path: 'users/:id',
        name: 'User',
        component: User,
        props: true,
        meta: {
          title: 'Grupo Resolve - Detalhes de Usuário.',
          auth: [4, 6]
        }
      },
      {
        path: 'companies',
        name: 'Companies',
        component: Companies,
        meta: {
          title: 'Grupo Resolve - Empresas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'companies/new',
        name: 'NewCompany',
        component: NewCompany,
        meta: {
          title: 'Grupo Resolve - Nova empresas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'companies/search',
        name: 'SearchCompany',
        component: SearchCompany,
        meta: {
          title: 'Grupo Resolve - Pesquisar empresas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'companies/:code/contract',
        name: 'ContractCompany',
        component: ContractCompany,
        props: true,
        meta: {
          title: 'Grupo Resolve - Contrato da empresa.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'companies/:id',
        name: 'Company',
        component: Company,
        props: true,
        meta: {
          title: 'Grupo Resolve - Empresa.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'companies/:id/edit',
        name: 'EditCompany',
        component: EditCompany,
        props: true,
        meta: {
          title: 'Grupo Resolve - Editar empresas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'candidates',
        name: 'Candidates',
        component: Candidates,
        meta: {
          title: 'Grupo Resolve - Candidatos.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'candidates/new',
        name: 'NewCandidate',
        component: NewCandidate,
        meta: {
          title: 'Grupo Resolve - Novo candidato.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'candidates/search',
        name: 'SearchCandidate',
        component: CandidateSearch,
        meta: {
          title: 'Grupo Resolve - Pesquisar candidatos.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'candidates/:id',
        name: 'ShowCandidate',
        component: ShowCandidate,
        props: true,
        meta: {
          title: 'Grupo Resolve - Candidato.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'candidates/:id/edit',
        name: 'EditCandidate',
        component: EditCandidate,
        props: true,
        meta: {
          title: 'Grupo Resolve - Editar candidato.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'jobs',
        name: 'Jobs',
        component: Jobs,
        meta: {
          title: 'Grupo Resolve - Vagas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'jobs/new/:code/:id',
        name: 'NewJob',
        component: NewJob,
        props: true,
        meta: {
          title: 'Grupo Resolve - Nova vaga.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'jobs/edit/:code/:id',
        name: 'EditJob',
        component: EditJob,
        props: true,
        meta: {
          title: 'Grupo Resolve - Editar vaga.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'jobs/search',
        name: 'SearchJob',
        component: SearchJob,
        meta: {
          title: 'Grupo Resolve - Pesquisar vagas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'jobs/closed',
        name: 'ClosedJob',
        component: ClosedJob,
        meta: {
          title: 'Grupo Resolve - Vagas fechadas e canceladas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'jobs/:id',
        name: 'Job',
        component: Job,
        props: true,
        meta: {
          title: 'Grupo Resolve - Vaga.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'reports',
        name: 'Reports',
        component: Reports,
        meta: {
          title: 'Grupo Resolve - Relatórios.',
          auth: [2, 4, 6, 8]
        }
      },
      {
        path: 'setup',
        name: 'Setup',
        component: Setup,
        meta: {
          title: 'Grupo Resolve - Configurações.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/benefits',
        name: 'Benefits',
        component: Benefits,
        meta: {
          title: 'Grupo Resolve - Beneficios.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/branches',
        name: 'Branches',
        component: Branches,
        meta: {
          title: 'Grupo Resolve - Ramos de Atividade.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/Cities',
        name: 'Cities',
        component: Cities,
        meta: {
          title: 'Grupo Resolve - Cidades.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/computing',
        name: 'Computing',
        component: Computing,
        meta: {
          title: 'Grupo Resolve - Informatica.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/deadlines',
        name: 'Deadlines',
        component: Deadlines,
        meta: {
          title: 'Grupo Resolve - Prazos de Vagas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/languages',
        name: 'Languages',
        component: Languages,
        meta: {
          title: 'Grupo Resolve - Idiomas.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/professions',
        name: 'Professions',
        component: Professions,
        meta: {
          title: 'Grupo Resolve - Profissões.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/salary',
        name: 'Salary',
        component: Salary,
        meta: {
          title: 'Grupo Resolve - Faixa Salarial.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/schooling',
        name: 'Schooling',
        component: Schooling,
        meta: {
          title: 'Grupo Resolve - Escolaridade.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/tests',
        name: 'Tests',
        component: Tests,
        meta: {
          title: 'Grupo Resolve - Testes.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'registrations/units',
        name: 'Units',
        component: Units,
        meta: {
          title: 'Grupo Resolve - Unidades.',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'prospects/',
        name: 'NewProspects',
        component: NewProspects,
        meta: {
          title: 'Grupo Resolve - Nova prospecção',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'prospects/:id',
        name: 'ShowProspects',
        component: ShowProspects,
        props: true,
        meta: {
          title: 'Grupo Resolve - Visualizar prospecção',
          auth: [2, 4, 6]
        }
      },
      {
        path: 'prospects/:id/edit',
        name: 'EditProspects',
        component: EditProspects,
        props: true,
        meta: {
          title: 'Grupo Resolve - Editar prospecção',
          auth: [2, 4, 6]
        }
      }
    ]
  },
  {
    path: '/candidate',
    name: 'Candidate',
    component: Candidate,
    children: [
      {
        path: '/',
        name: 'CandidateJobs',
        component: CandidateJobs,
        meta: {
          title: 'Grupo Resolve - Painel de Candidato.',
          auth: [0]
        }
      },
      {
        path: 'profile',
        name: 'CandidateProfile',
        component: CandidateProfile,
        meta: {
          title: 'Grupo Resolve - Perfil de Candidato.',
          auth: [0]
        }
      },
      {
        path: 'setup',
        name: 'CandidateSetup',
        component: CandidateSetup,
        meta: {
          title: 'Grupo Resolve - Configurações de Candidato.',
          auth: [0]
        }
      },
    ]
  },
  {
    path: '/jobs/iframe',
    name: 'IframeJobs',
    component: IframeJobs,
    meta: {
      title: 'Grupo Resolve - Iframe de vagas.'
    }
  },
  {
    path: '/jobs/:code',
    name: 'DetailsJob',
    props: true,
    component: DetailsJob,
    meta: {
      title: 'Grupo Resolve - Detalhes da vaga.'
    }
  },
  {
    path: '/consultant',
    name: 'Consultant',
    component: Consultant,
    children: [
      {
        path: '/',
        name: 'ConsultantJobs',
        component: ConsultantJobs,
        meta: {
          title: 'Grupo Resolve - Vagas.',
          auth: [8]
        }
      },
      {
        path: 'jobs/search',
        name: 'ConsultantSearchJob',
        component: SearchJob,
        meta: {
          title: 'Grupo Resolve - Pesquisar vagas.',
          auth: [8]
        }
      },
      {
        path: 'jobs/closed',
        name: 'ConsultantClosedJobs',
        component: ConsultantClosedJobs,
        meta: {
          title: 'Grupo Resolve - Vagas fechadas e canceladas.',
          auth: [8]
        }
      },
      {
        path: 'jobs/all',
        name: 'ConsultantAllJobs',
        component: ConsultantAllJobs,
        meta: {
          title: 'Grupo Resolve - Todas as vagas.',
          auth: [8]
        }
      },
      {
        path: 'jobs/:id',
        name: 'ConsultantJob',
        component: ConsultantJob,
        props: true,
        meta: {
          title: 'Grupo Resolve - Vaga.',
          auth: [8]
        }
      },
      {
        path: 'jobs/new/:code/:id',
        name: 'ConsultantNewJob',
        component: ConsultantNewJob,
        props: true,
        meta: {
          title: 'Grupo Resolve - Nova Vaga.',
          auth: [8]
        }
      },
      {
        path: 'jobs/edit/:code/:id',
        name: 'ConsultantEditJob',
        component: ConsultantEditJob,
        props: true,
        meta: {
          title: 'Grupo Resolve - Editar vaga.',
          auth: [8]
        }
      },
      {
        path: 'candidates',
        name: 'ConsultantCandidates',
        component: ConsultantCandidates,
        meta: {
          title: 'Grupo Resolve - Candidatos.',
          auth: [8]
        }
      },
      {
        path: 'candidates/search',
        name: 'ConsultantSearchCandidade',
        component: ConsultantSearchCandidade,
        meta: {
          title: 'Grupo Resolve - Filtrar candidatos.',
          auth: [8]
        }
      },
      {
        path: 'candidates/:id',
        name: 'ConsultantCandidate',
        component: ConsultantCandidate,
        props: true,
        meta: {
          title: 'Grupo Resolve - Candidato.',
          auth: [8]
        }
      },
      {
        path: 'companies',
        name: 'ConsultantCompanies',
        component: ConsultantCompanies,
        meta: {
          title: 'Grupo Resolve - Empresas.',
          auth: [8]
        }
      },
      {
        path: 'companies/search',
        name: 'ConsultantSearchCompany',
        component: SearchCompany,
        meta: {
          title: 'Grupo Resolve - Pesquisar empresas.',
          auth: [8]
        }
      },
      {
        path: 'companies/:id',
        name: 'ConsultantCompany',
        component: ConsultantCompany,
        props: true,
        meta: {
          title: 'Grupo Resolve - Cadastro da Empresa.',
          auth: [8]
        }
      },
      {
        path: 'setup',
        name: 'ConsultantSetup',
        component: ConsultantSetup,
        meta: {
          title: 'Grupo Resolve - Configurações.',
          auth: [8]
        }
      },
      {
        path: 'reports',
        name: 'ConsultantReports',
        component: ConsultantReports,
        meta: {
          title: 'Grupo Resolve - Relatórios.',
          auth: [8]
        }
      },
      /** START TEST */
      {
        path: 'registrations/benefits',
        name: 'ConsultantBenefits',
        component: Benefits,
        meta: {
          title: 'Grupo Resolve - Beneficios.',
          auth: [8]
        }
      },
      {
        path: 'registrations/branches',
        name: 'ConsultantBranches',
        component: Branches,
        meta: {
          title: 'Grupo Resolve - Ramos de Atividade.',
          auth: [8]
        }
      },
      {
        path: 'registrations/Cities',
        name: 'ConsultantCities',
        component: Cities,
        meta: {
          title: 'Grupo Resolve - Cidades.',
          auth: [8]
        }
      },
      {
        path: 'registrations/computing',
        name: 'ConsultantComputing',
        component: Computing,
        meta: {
          title: 'Grupo Resolve - Informatica.',
          auth: [8]
        }
      },
      {
        path: 'registrations/deadlines',
        name: 'ConsultantDeadlines',
        component: Deadlines,
        meta: {
          title: 'Grupo Resolve - Prazos de Vagas.',
          auth: [8]
        }
      },
      {
        path: 'registrations/languages',
        name: 'ConsultantLanguages',
        component: Languages,
        meta: {
          title: 'Grupo Resolve - Idiomas.',
          auth: [8]
        }
      },
      {
        path: 'registrations/professions',
        name: 'ConsultantProfessions',
        component: Professions,
        meta: {
          title: 'Grupo Resolve - Profissões.',
          auth: [8]
        }
      },
      {
        path: 'registrations/salary',
        name: 'ConsultantSalary',
        component: Salary,
        meta: {
          title: 'Grupo Resolve - Faixa Salarial.',
          auth: [8]
        }
      },
      {
        path: 'registrations/schooling',
        name: 'ConsultantSchooling',
        component: Schooling,
        meta: {
          title: 'Grupo Resolve - Escolaridade.',
          auth: [8]
        }
      },
      {
        path: 'registrations/tests',
        name: 'ConsultantTests',
        component: Tests,
        meta: {
          title: 'Grupo Resolve - Testes.',
          auth: [8]
        }
      },
      {
        path: 'registrations/units',
        name: 'ConsultantUnits',
        component: Units,
        meta: {
          title: 'Grupo Resolve - Unidades.',
          auth: [8]
        }
      },
      /** END TEST */
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title

  if (to.meta.auth) {
    const auth = to.meta.auth
    if (auth.includes(Number(localStorage.getItem('miolocat_userType')))) {
      next()
    } else {
      next({ name: 'Home' })
    }
  } else {
    next()
  }
})

export default router


<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading" class="bottom-detail" elevation="16">
          <v-card-title>USUÁRIOS DO SISTEMA</v-card-title>
          <v-card-subtitle>AQUI VOCÊ VISUALIZA TODOS OS USUÁRIOS, PODE ACESSAR MAIS DETALHES E EXECUTAR AÇÕES</v-card-subtitle>
          <v-btn v-if="!$vuetify.breakpoint.mobile" color="#662929" class="white--text" absolute top right @click="$router.push({ name: `NewUser`})"><v-icon left>fas fa-plus</v-icon>ADICIONAR</v-btn>
          <v-btn v-else color="#662929" class="ml-4 mb-6 whitegr" @click="$router.push({ name: `NewUser`})"><v-icon left>fas fa-plus</v-icon>ADICIONAR</v-btn>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-alert v-model="errorAlert" border="left" close-text="Close Alert" color="error" dark dismissible>ERRO AO BUSCAR USUÁRIOS!</v-alert>
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME</th>
                  <th>E-MAIL</th>
                  <th>CADASTRADO EM</th>
                  <th>ATUALIZADO EM</th>
                  <th>TIPO DE ACESSO</th>
                  <!-- <th>SITUAÇÃO</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in users" :key="index" class="user-item" @click="$router.push({ path: `/dashboard/users/${user.user_code}` })">
                  <td>{{ user.user_name }}</td>
                  <td>{{ user.user_email }}</td>
                  <td>{{ user.user_created | datetime }}</td>
                  <td>{{ user.user_updated | datetime }}</td>
                  <td class="text-capitalize">{{ user.user_type | userType }}</td>
                  <!-- <td class="text-capitalize">{{ user.user_state | userState }}</td> -->
                </tr>
              </tbody>
            </v-simple-table>
            <v-divider />
            <v-btn color="#662929" class="white--text mt-3" @click="showBlock = true">VER USUÁRIO BLOQUEADOS</v-btn>
            <div v-if="showBlock">
              <h2 class="mt-6">USUÁRIOS BLOQUEADOS:</h2>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>NOME</th>
                    <th>E-MAIL</th>
                    <th>CADASTRADO EM</th>
                    <th>ATUALIZADO EM</th>
                    <th>TIPO DE ACESSO</th>
                    <!-- <th>SITUAÇÃO</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in usersRemoved" :key="index" class="user-item" @click="$router.push({ path: `/dashboard/users/${user.user_code}` })">
                    <td>{{ user.user_name }}</td>
                    <td>{{ user.user_email }}</td>
                    <td>{{ user.user_created | datetime }}</td>
                    <td>{{ user.user_updated | datetime }}</td>
                    <td class="text-capitalize">{{ user.user_type | userType }}</td>
                    <!-- <td class="text-capitalize">{{ user.user_state | userState }}</td> -->
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Users',
  data() {
    return {
      users: [],
      usersRemoved: [],
      loading: true,
      errorAlert: false,
      showBlock: false,
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    // Obtem todos os usuários, exceto candidatos. //
    getUsers() {
      this.$axios.get(`/users`).then(success => {
        this.users = success.data
        this.loading = false
      }).catch(err => {
        this.errorAlert = true
        this.loading = false
      })

      this.$axios.get(`/users/blocked`).then(success => {
        this.usersRemoved = success.data
        this.loading = false
      }).catch(err => {
        this.errorAlert = true
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
  .user-item {
    cursor: pointer;
  }
</style>

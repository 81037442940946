
<template>
  <v-container fluid>
    <v-row id="no-print">
      <v-col cols="12" sm="7">
        <v-card>
          <v-card-title>DETALHES DA VAGA</v-card-title>
          <v-card-subtitle>{{ id }}</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <p><strong>IDENTIFICADOR: </strong>{{ job.jobs_id }}</p>
            <p><strong>NOME FANTASIA: </strong> {{ job.comp_fantasy }}</p>
            <p><strong>RAZÃO SOCIAL: </strong> {{ job.comp_name }}</p>
            <p><strong>CNPJ</strong> {{ job.comp_cnpj }} - <strong>I.E</strong> {{ job.comp_ie || ': ISENTO' }} - <strong>I.M</strong> {{ job.comp_im || ': ISENTO' }}</p>
            <p><strong>INICIO: </strong> {{ job.jobs_start | birth }} - <strong>FIM: </strong> {{ job.jobs_end | birth }}</p>
            <p><strong>CONSULTOR(A): </strong> {{ job.user_name }} - {{ job.user_email }}</p>
            <p><strong>UNIDADE: </strong> {{ job.unit_name }}</p>
            <p><strong>CIDADES: </strong> {{ job.jobs_city | cities }}</p>
            <p><strong>TIPO DE VAGA: </strong> {{ job.jobs_type | jobType }}</p>
            <p><strong>QUANTIDADE DE VAGAS: </strong> {{ job.jobs_qttd }}</p>
            <p><strong>RAMO DE ATIVIDADE: </strong> {{ job.bran_name }}</p>
            <p><strong>PROFISSÃO: </strong> {{ job.occu_name }}</p>
            <p><strong>TITULO: </strong> {{ job.jobs_title }}</p>
            <p><strong>GRAU DE ENSINO: </strong> {{ job.scho_name }}</p>
            <p><strong>CARGO JÁ EXERCIDO: </strong> {{ job.jobs_office_exercised }}</p>
            <p><strong>INFORMATICA: </strong></p>
            <ul class="mb-4">
              <li v-for="(info, index) in job.computings" :key="index">
                {{ info.info_name }} <v-icon color="error" @click="removeInfo(info.nnji_code)" small>fas fa-trash-alt</v-icon>
              </li>
            </ul>
            <p><strong>IDIOMAS: </strong></p>
            <ul class="mb-4">
              <li v-for="(lang, index) in job.languages" :key="index">
                {{ lang.lang_name }} ({{ lang.nnjl_level | language }}) <v-icon color="error" @click="removeLang(lang.nnjl_code)" small>fas fa-trash-alt</v-icon>
              </li>
            </ul>
            <p><strong>BENEFICIOS: </strong></p>
            <ul class="mb-4">
              <li v-for="(benefit, index) in job.benefits" :key="index">
                {{ benefit.bene_name }} (<strong>R$ </strong>{{ benefit.nnbj_value }}) <v-icon color="error" @click="removeBenefit(benefit.nnbj_code)" small>fas fa-trash-alt</v-icon>
              </li>
            </ul>
            <p><strong>GENERO: </strong> {{ job.jobs_sex | sex }}</p>
            <p><strong>ESTADO CIVIL: </strong> {{ job.jobs_marital_status | civil }}</p>
            <p><strong>IDADE: </strong> {{ job.jobs_age_min }} à {{ job.jobs_age_max }}</p>
            <p><strong>ALTURA: </strong> {{ job.jobs_height_min }} à {{ job.jobs_height_max }}</p>
            <p><strong>EMPREGADO: </strong> {{ job.jobs_employee | yes_not }}</p>
            <p><strong>HABILITADO: </strong> {{ job.jobs_cnh | yes_not }} - CATEGORIAS: {{ job.jobs_cnh_categories }}</p>
            <p><strong>POSSUÍ VEICULO: </strong> {{ job.jobs_vehicle | yes_not }}</p>
            <p><strong>SALARIO: </strong> {{ job.jobs_salary }}</p>
            <p><strong>HORÁRIOS: </strong> {{ job.jobs_schedules }}</p>
            <p><strong>PCD: </strong> {{ job.jobs_deficient | yes_not }}</p>
            <v-divider class="mb-6" />
            <p><strong>DESCRIÇÃO DA VAGA: </strong> {{ job.jobs_description }}</p>
            <v-divider class="mb-6" />
            <v-select v-model="jobState" :items="jobStates" item-text="name" item-value="code" label="ALTERAR STATUS DA VAGA" @change="changeStatus()" filled/>
            <hr class="mb-4">
            <v-btn color="#e98b0d" class="white--text mb-4" @click="$router.push({ path: `/consultant/jobs/edit/${job.jobs_code}/${job.jobs_id}` })" block>EDITAR</v-btn>
            <v-btn color="#828a03" class="white--text mt-3" @click="print()" block>IMPRIMIR</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card class="bottom-detail">
          <v-card-title>HISTORICO</v-card-title>
          <v-card-subtitle>ULTIMOS REGISTROS</v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ history.user_name }}</v-list-item-title>
                <v-list-item-content>{{ history.hs_job_message }}</v-list-item-content>
                <v-list-item-subtitle>{{ history.hs_job_created | datetime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mb-4 mt-4"/>
            <v-textarea v-model="message" label="MENSAGEM" auto-grow filled/>
            <v-btn class="white--text" color="#662929" :disabled="!message.length >= 1" large block @click="newHistorical()" :loading="newHistoricalLoading">ADICIONAR</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-btn color="#662929" class="white--text" @click="checkProfiles()" :loading="reloading" :disabled="loadingCandidates" block>VALIDAR PERFIS NOVAMENTE ({{ candidates.length }})</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn color="#662929" class="white--text" @click="onProfileDialog = true" :loading="loadingCandidates" :disabled="loadingCandidates" block>DENTRO DO PERFIL ({{ candidates1.length }})</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn color="#662929" class=" white--text" @click="selectedDialog = true" :loading="loadingCandidates" :disabled="loadingCandidates" block>SELECIONADOS ({{ candidates5.length }})</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn color="#662929" class=" white--text" @click="profileDialog = true" :loading="loadingCandidates" :disabled="loadingCandidates" block>FORA DO PERFIL ({{ candidates2.length }})</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn color="#662929" class="white--text" @click="interestDialog = true" :loading="loadingCandidates" :disabled="loadingCandidates" block>SEM INTERESSE / INDISPONIVEL ({{ candidates3.length }})</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn color="#662929" class="white--text" @click="profileLikeDialog = true" :loading="loadingCandidates" :disabled="loadingCandidates" block>PRÓXIMO AO PERFIL ({{ candidates4.length }})</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="candidateDetails" width="600" persistent>
      <v-card>
        <v-card-title>ACESSO RÁPIDO</v-card-title>
        <v-card-subtitle>{{ currentCandidate.cand_code }}</v-card-subtitle>
        <v-divider />
        <div class="pa-7">
          <v-row>
            <v-col cols="12">
              <p><strong>NOME: </strong> {{ currentCandidate.cand_name }}</p>
              <p><strong>E-MAIL: </strong> {{ currentCandidate.cand_email }}</p>
              <p><strong>CPF: </strong> {{ currentCandidate.cand_cpf }}</p>
              <p><strong>RG: </strong> {{ currentCandidate.cand_rg }}</p>
              <p><strong>PERFIL COMPATIVEL: </strong> {{ currentCandidate.nncj_compatibility | profile }}</p>
              <p><strong>PERFIL SELECIONADO: </strong> {{ currentCandidate.nncj_status | jobSelection }}</p>
              <v-divider />
              <v-select v-model="candidateState" :items="candidateStates" item-text="name" item-value="code" class="mt-6" label="ESTADO DE SELEÇÃO DO CANDIDATO" filled @change="changeState()"/>
              <v-divider />
              <v-select v-model="candidateProgress" :items="candidateProgresses" item-text="name" item-value="code" class="mt-6" label="ESTADO DE PROGRESSO DO CANDIDATO" filled @change="changeProgress()"/>
              <v-divider />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn color="#e98b0d" class="white--text" block @click="candidateDetails = false">FECHAR</v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn color="#828a03" class="white--text" block @click="openCandidateNewTab($router.resolve({ path: `/consultant/candidates/${currentCandidate.cand_code}` }))">VER PERFIL COMPLETO</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="profileDialog" width="1000" persistent>
      <v-card>
        <v-card-title>CANDIDATOS FORA DO PERFIL</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-simple-table>
            <thead>
              <tr>
                <th>NOME / EMAIL</th>
                <th>PERFIL COMPATIVEL / PERFIL SELECIONADO</th>
                <th>PROGRESSO NA VAGA / DATA CANDIDATURA</th>
                <th>MOTIVO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(candidate, index) in candidates2" :key="index" @click="currentCandidate = candidate; candidateState = currentCandidate.nncj_status; candidateProgress = currentCandidate.nncj_progress; candidateDetails = true; setViewed()">
                <td v-if="candidate.nncj_viewed == 2">
                  <div>{{ candidate.cand_name }}</div>
                  <div>{{ candidate.cand_email }}</div>
                </td>
                <td v-else style="background-color: grey; color: white;">
                  <div>{{ candidate.cand_name }}</div>
                  <div>{{ candidate.cand_email }}</div>
                </td>
                <td>
                  <div>{{ candidate.nncj_compatibility | profile }}</div>
                  <div>{{ candidate.nncj_status | jobSelection }}</div>
                </td>
                <td>
                  <div>{{ candidate.nncj_progress | candidateProgress }}</div>
                  <div>{{ candidate.nncj_created | birth }}</div>
                </td>
                <td>
                  <div>{{ candidate.nncj_cause }}</div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider />
          <v-btn color="warning" class="mt-4" @click="profileDialog = false">FECHAR</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="interestDialog" width="1000" persistent>
      <v-card>
        <v-card-title>CANDIDATOS SEM INTERESSE OU INDISPONIVEL</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-simple-table>
            <thead>
              <tr>
                <th>NOME / EMAIL</th>
                <th>PERFIL COMPATIVEL / PERFIL SELECIONADO</th>
                <th>PROGRESSO NA VAGA / DATA CANDIDATURA</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(candidate, index) in candidates3" :key="index" @click="currentCandidate = candidate; candidateState = currentCandidate.nncj_status; candidateProgress = currentCandidate.nncj_progress; candidateDetails = true; setViewed()">
                <td v-if="candidate.nncj_viewed == 2">
                  <div>{{ candidate.cand_name }}</div>
                  <div>{{ candidate.cand_email }}</div>
                </td>
                <td v-else style="background-color: grey; color: white;">
                  <div>{{ candidate.cand_name }}</div>
                  <div>{{ candidate.cand_email }}</div>
                </td>
                <td>
                  <div>{{ candidate.nncj_compatibility | profile }}</div>
                  <div>{{ candidate.nncj_status | jobSelection }}</div>
                </td>
                <td>
                  <div>{{ candidate.nncj_progress | candidateProgress }}</div>
                  <div>{{ candidate.nncj_created | birth }}</div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider />
          <v-btn color="warning" class="mt-4" @click="interestDialog = false">FECHAR</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="profileLikeDialog" width="1000" persistent>
      <v-card>
        <v-card-title>CANDIDATOS COM PERFIL PRÓXIMO DA VAGA</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-simple-table>
            <thead>
              <tr>
                <th>NOME / EMAIL</th>
                <th>PERFIL COMPATIVEL / PERFIL SELECIONADO</th>
                <th>PROGRESSO NA VAGA / DATA CANDIDATURA</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(candidate, index) in candidates4" :key="index" @click="currentCandidate = candidate; candidateState = currentCandidate.nncj_status; candidateProgress = currentCandidate.nncj_progress; candidateDetails = true; setViewed()">
                <td v-if="candidate.nncj_viewed == 2">
                  <div>{{ candidate.cand_name }}</div>
                  <div>{{ candidate.cand_email }}</div>
                </td>
                <td v-else style="background-color: grey; color: white;">
                  <div>{{ candidate.cand_name }}</div>
                  <div>{{ candidate.cand_email }}</div>
                </td>
                <td>
                  <div>{{ candidate.nncj_compatibility | profile }}</div>
                  <div>{{ candidate.nncj_status | jobSelection }}</div>
                </td>
                <td>
                  <div>{{ candidate.nncj_progress | candidateProgress }}</div>
                  <div>{{ candidate.nncj_created | birth }}</div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider />
          <v-btn color="warning" class="mt-4" @click="profileLikeDialog = false">FECHAR</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="onProfileDialog" width="1000" persistent>
      <v-card>
        <v-card-title>CANDIDATOS DENTRO DO PERFIL ({{ candidates1.length }})</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-simple-table>
              <thead>
                <tr>
                  <th>NOME / EMAIL</th>
                  <th>PERFIL COMPATIVEL / PERFIL SELECIONADO</th>
                  <th>PROGRESSO NA VAGA / DATA CANDIDATURA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(candidate, index) in candidates1" :key="index" @click="currentCandidate = candidate; candidateState = currentCandidate.nncj_status; candidateProgress = currentCandidate.nncj_progress; candidateDetails = true; setViewed()">
                  <td v-if="candidate.nncj_viewed == 2">
                    <div>{{ candidate.cand_name }}</div>
                    <div>{{ candidate.cand_email }}</div>
                  </td>
                  <td v-else style="background-color: grey; color: white;">
                    <div>{{ candidate.cand_name }}</div>
                    <div>{{ candidate.cand_email }}</div>
                  </td>
                  <td>
                    <div>{{ candidate.nncj_compatibility | profile }}</div>
                    <div>{{ candidate.nncj_status | jobSelection }}</div>
                  </td>
                  <td>
                    <div>{{ candidate.nncj_progress | candidateProgress }}</div>
                    <div>{{ candidate.nncj_created | birth }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          <v-divider />
          <v-btn color="warning" class="mt-4" @click="onProfileDialog = false">FECHAR</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selectedDialog" width="1000" persistent>
      <v-card>
        <v-card-title>CANDIDATOS SELECIONADOS ({{ candidates5.length }})</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-simple-table>
              <thead>
                <tr>
                  <th>NOME / EMAIL</th>
                  <th>PERFIL COMPATIVEL / PERFIL SELECIONADO</th>
                  <th>PROGRESSO NA VAGA / DATA CANDIDATURA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(candidate, index) in candidates5" :key="index" @click="currentCandidate = candidate; candidateState = currentCandidate.nncj_status; candidateProgress = currentCandidate.nncj_progress; candidateDetails = true; setViewed()">
                  <td v-if="candidate.nncj_viewed == 2">
                    <div>{{ candidate.cand_name }}</div>
                    <div>{{ candidate.cand_email }}</div>
                  </td>
                  <td v-else style="background-color: grey; color: white;">
                    <div>{{ candidate.cand_name }}</div>
                    <div>{{ candidate.cand_email }}</div>
                  </td>
                  <td>
                    <div>{{ candidate.nncj_compatibility | profile }}</div>
                    <div>{{ candidate.nncj_status | jobSelection }}</div>
                  </td>
                  <td>
                    <div>{{ candidate.nncj_progress | candidateProgress }}</div>
                    <div>{{ candidate.nncj_created | birth }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          <v-divider />
          <v-btn color="warning" class="mt-4" @click="selectedDialog = false">FECHAR</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <div id="section-to-print">
      <print message="RELATÓRIO DE VAGA">
        <p class="mt-4"><strong>IDENTIFICADOR: </strong>{{ job.jobs_id }}</p>
        <p><strong>NOME FANTASIA: </strong> {{ job.comp_fantasy }}</p>
        <p><strong>RAZÃO SOCIAL: </strong> {{ job.comp_name }}</p>
        <p><strong>CNPJ</strong> {{ job.comp_cnpj }} - <strong>I.E</strong> {{ job.comp_ie || ': ISENTO' }} - <strong>I.M</strong> {{ job.comp_im || ': ISENTO' }}</p>
        <p><strong>STATUS: </strong> {{ job.jobs_status | jobState }}</p>
        <p><strong>TIPO: </strong> {{ job.jobs_type | jobType }}</p>
        <p><strong>IDADES: </strong> Min {{ job.jobs_age_min }} anos / Max {{ job.jobs_age_max }} anos.</p>
        <p><strong>UNIDADE: </strong> {{ job.unit_name }}</p>
        <p><strong>CIDADES: </strong> {{ job.jobs_city | cities }}</p>
        <p><strong>GENERO: </strong> {{ job.jobs_sex | sex }} - <strong>ESTADO CIVIL: </strong> {{ job.jobs_marital_status | civil }}</p>
        <p><strong>GRAU DE ENSINO: </strong> {{ job.scho_name }}</p>
        <p><strong>RAMO: </strong> {{ job.bran_name }} - <strong>OCUPAÇÃO: </strong> {{ job.occu_name }}</p>
        <p><strong>INICIO: </strong> {{ job.jobs_start | birth }} - <strong>FIM: </strong> {{ job.jobs_end | birth }}</p>
        <p><strong>CONSULTOR: </strong> {{ job.user_name }} - {{ job.user_email }}</p>
        <p><strong>EMPREGADO: </strong> {{ job.jobs_employee | yes_not }}</p>
        <p><strong>HABILITADO: </strong> {{ job.jobs_cnh | yes_not }}</p>
        <p><strong>TEM VEICULO: </strong> {{ job.jobs_vehicle | yes_not }}</p>
        <p><strong>SALARIO: </strong> {{ job.jobs_salary }}</p>
        <p><strong>HORÁRIOS: </strong> {{ job.jobs_schedules }}</p>
        <p><strong>PCD: </strong> {{ job.jobs_deficient | yes_not }}</p>
        <hr class="mb-4">
        <p><strong>CURSOS</strong></p>
        <ul class="mb-5">
          <li v-for="(info, index) in job.computings" :key="index">
            {{ info.info_name }} <v-icon color="error" @click="removeInfo(info.nnji_code)" small>fas fa-trash-alt</v-icon>
          </li>
        </ul>
        <hr class="mb-4">
        <p><strong>IDIOMAS</strong></p>
        <ul class="mb-5">
          <li v-for="(lang, index) in job.languages" :key="index">
            {{ lang.lang_name }} ({{ lang.nnjl_level | language }})
          </li>
        </ul>
        <hr class="mb-4">
          <p><strong>BENEFICIOS: </strong></p>
        <ul class="mb-4">
          <li v-for="(benefit, index) in job.benefits" :key="index">
            {{ benefit.bene_name }} (<strong>R$ </strong>{{ benefit.nnbj_value }})
          </li>
        </ul>
        <hr class="mb-4">
        <p><strong>DESCRIÇÃO DA VAGA: </strong> {{ job.jobs_description }}</p>
        <v-divider class="my-6" />
        <h1>MOVIMENTAÇÃO</h1>
        <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
          <v-list-item-content>
            <v-list-item-title>{{ history.user_name }}</v-list-item-title>
            <v-list-item-content>{{ history.hs_job_message }}</v-list-item-content>
            <v-list-item-subtitle>{{ history.hs_job_created | datetime }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </print>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment'
import print from '../../components/Print.vue'

export default {
  name: 'ConsultantJob',
  props: [ 'id' ],
  components: { print },
  data() {
    return {
      job: [],
      candidates: [],
      currentCandidate: {},
      candidateDetails: false,
      candidateState: '',
      candidateStates: [
        { name: 'NÃO SELECIONADO', code: 2 },
        { name: 'SELECIONADO', code: 4 }
      ],
      jobState: '',
      jobStates: [
        { name: 'CANCELADA', code: 2 },
        { name: 'ABERTA', code: 4 },
        { name: 'SÓ ADMINISTRAÇÃO', code: 12 },
        { name: 'FECHADA PELA CONCORRENCIA', code: 6 },
        { name: 'FECHADA PELA RESOLVE', code: 8 },
        { name: 'INDICAÇÃO', code: 10 },
      ],
      candidateProgress: '',
      candidateProgresses: [
        { name: 'INDEFINIDO', code: 2 },
        { name: 'PRÓXIMO AO PERFIL', code: 12 },
        { name: 'INDISPONIVEL', code: 4 },
        { name: 'CV ENCAMINHADO', code: 6 },
        { name: 'NÃO TEVE INTERESSE', code: 8 },
        { name: 'PROCESSO ANTERIOR', code: 10 },
      ],
      message: '',
      historicals: [],
      newHistoricalLoading: false,
      profileDialog: false,
      interestDialog: false,
      profileLikeDialog: false,
      selectedDialog: false,
      onProfileDialog: false,
      candidates1: [],
      candidates2: [],
      candidates3: [],
      candidates4: [],
      candidates5: [],
      reloading: false,
      loadingCandidates: true,
    }
  },
  created() {
    this.getJob()
  },
  methods: {
    // Obtem vaga. //
    getJob() {
      this.$axios.get(`/jobs/${this.id}`).then(success => {
        this.job = success.data
        this.getCandidates()
        this.listHistoricals()
        this.jobState = this.job.jobs_status
      })
    },
    // Obtem candidatos. //
    getCandidates() {
      this.$axios.get(`/jobs/candidates/${this.job.jobs_id}`).then(success => {
        this.candidates = success.data.candidates
        this.reloadFilters()
      })
    },
    // Altera estado de seleção do candidato. //
    changeState() {
      this.$axios.put(`/jobs/candidatestate/${this.currentCandidate.nncj_code}`, {
        stateCode: this.candidateState
      }).then(success => {
        this.getJob()
        this.candidateDetails = false
      })
    },
    // Altera estado de progresso do candidato. //
    changeProgress() {
      this.$axios.put(`/jobs/candidateprogress/${this.currentCandidate.nncj_code}`, {
        stateCode: this.candidateProgress
      }).then(success => {
        this.getJob()
        this.candidateDetails = false
      })
    },
    // Adiciona historico a Vaga. //
    newHistorical() {
      this.newHistoricalLoading = true
      this.$axios.post(`/historicals/jobs/${this.job.jobs_id}`, {
        message: this.message,
        messages: this.historicals,
        job: {
          company: this.job.comp_name,
          job: this.job.occu_name,
          code: this.job.jobs_code,
          consultant: this.job.user_name,
          consultant_email: this.job.user_email,
          commercial: this.job.comp_commercial_name,
          commercial_email: this.job.comp_commercial_email,
        }
      }).then(success => {
        this.message = ''
        this.listHistoricals()
        this.newHistoricalLoading = false
      })
    },
    // Obtem historicos da Vaga. //
    listHistoricals() {
      this.$axios.get(`/historicals/jobs/${this.job.jobs_id}`).then(success => {
        this.historicals = success.data.historicals
      })
    },
    // Altera estado da vaga. //
    changeStatus() {
      this.$axios.put(`/jobs/jobstate/${this.job.jobs_code}`, {
        stateCode: this.jobState
      }).then(success => {
        this.job.jobs_status = this.jobState
      })
    },
    // Remove info. //
    removeInfo(code) {
      this.$axios.delete(`/jobs/infos/${code}`).then(success => {
        this.job = {}
        this.getJob()
      })
    },
    // Remove idioma. //
    removeLang(code) {
      this.$axios.delete(`/jobs/languages/${code}`).then(success => {
        this.job = {}
        this.getJob()
      })
    },
    // Remove beneficio. //
    removeBenefit(code) {
      this.$axios.delete(`/jobs/benefits/${code}`).then(success => {
        this.job = {}
        this.getJob()
      })
    },
    // Valida novamente compatibilidade de perfis. //
    checkProfiles() {
      this.reloading = true
      this.candidates.forEach(candidate => {

        let profile = 4
        let causes = []

        console.log(candidate.cand_email)

        // Verifica compatibilidade de perfil. //
        // Verifica idade. //
        if (moment().diff(candidate.cand_birth, 'years', false) < this.job.jobs_age_min || moment().diff(candidate.cand_birth, 'years', false) > this.job.jobs_age_max) {
          profile = 2
          console.log('IDADE', this.job.jobs_age_min, this.job.jobs_age_max, candidate.cand_birth, moment().diff(candidate.cand_birth, 'years', false))
          causes.push('IDADE')
        }
        // Verifica genero. //
        if (this.job.jobs_sex != candidate.cand_sex && this.job.jobs_sex != 6) {
          profile = 2
          console.log('SEXO', this.job.jobs_sex, candidate.cand_sex)
          causes.push('SEXO')
        }
        // Verifica estado. //
        // if (this.job.jobs_state != candidate.cand_address_state) {
          // profile = 2
          // console.log('ESTADO', this.job.jobs_state, candidate.cand_address_state)
        // }
        // Verifica cidade. //
        if (!this.job.jobs_city.includes(candidate.cand_address_city)) {
          profile = 2
          console.log('CIDADE', this.job.jobs_city, candidate.cand_address_city)
          causes.push('CIDADE')
          // console.log(this.job.jobs_city, candidate.cand_address_city)
          // console.log(this.job.jobs_city.includes(candidate.cand_address_city))
        }
        // Verifica ensino. //
        if (this.job.jobs_schooling > candidate.cand_schooling) {
          profile = 2
          console.log('ESCOLARIDADE', this.job.jobs_schooling, candidate.cand_schooling)
          causes.push('ESCOLARIDADE')
        }
        // Verifica formação. //
        if (this.job.jobs_occupation != candidate.cand_occupation) {
          profile = 2
          console.log('OCUPAÇÃO', this.job.jobs_occupation, candidate.cand_occupation)
          causes.push('OCUPAÇÃO')
        }
        // Verifica estado civil. //
        if (this.job.jobs_marital_status != candidate.cand_marital_status && this.job.jobs_marital_status != 14) {
          profile = 2
          console.log('CIVIL', this.job.jobs_marital_status, candidate.cand_marital_status)
          causes.push('ESTADO CIVIL')
        }
        // Verifica empregado. //
        if (this.job.jobs_employee != candidate.cand_employee && this.job.jobs_employee != 6) {
          profile = 2
          console.log('TEM EMPREGO', this.job.jobs_employee, candidate.cand_employee)
          causes.push('TEM EMPREGO')
        }
        // Verifica veiculo. //
        if (this.job.jobs_vehicle != candidate.cand_vehicle && this.job.jobs_vehicle != 6) {
          profile = 2
          console.log('TEM VEICULO', this.job.jobs_vehicle, candidate.cand_vehicle)
          causes.push('TEM VEICULO')
        }
        // Verifica habilitado. //
        if (this.job.jobs_cnh != candidate.cand_cnh && this.job.jobs_cnh != 6) {
          profile = 2
          console.log('TEM CNH', this.job.jobs_cnh, candidate.cand_cnh)
          causes.push('TEM CNH')
        }
        // Verifica deficiente. //
        if (this.job.jobs_deficient != candidate.cand_deficient && this.job.jobs_deficient != 6) {
          profile = 2
          console.log('DEFICIENTE', this.job.jobs_deficient, candidate.cand_deficient)
          causes.push('DEFICIENTE')
        }
        // Verifica cursos. //
        this.job.computings.forEach(jc => {
          if (!candidate.courses.find(cc => {
            return jc.nnji_info == cc.nnci_info
          })) {
            profile = 2
            console.log('CURSO')
            causes.push('CURSO')
          }
        })
        // Verifica idiomas. //
        this.job.languages.forEach(jl => {
          if (!candidate.languages.find(cl => {
            return jl.nnjl_lang == cl.nncl_lang
          })) {
            profile = 2
            console.log('IDIOMA')
            causes.push('IDIOMA')
          }
        })

        if (true) {
          this.$axios.put(`/jobs/candidateprofile/${candidate.nncj_id}`, {
            value: profile,
            message: causes.toString()
          }).then(success => {
            candidate.nncj_compatibility = profile
            candidate.nncj_cause = causes.toString()
          })
        }
        setInterval(() => {
          this.reloadFilters()
          this.reloading = false
        }, 6000)
      })
    },
    // Imprime curriculo do candidato. //
    print() {
      this.$root.$emit('evt::close')
      setTimeout(() => {
        window.print()
      }, 1000)
    },
    // Recarrega os candidatos filtrando as exibições. //
    reloadFilters() {
      // Dentro do perfil. //
      this.candidates1 = this.candidates.filter(cand => {
        return cand.nncj_compatibility == 4 && cand.nncj_progress != 4 && cand.nncj_progress != 8
      })
      // Fora do perfil. //
      this.candidates2 = this.candidates.filter(cand => {
        return cand.nncj_compatibility == 2
      })
      // Sem interesse ou indisponivel. //
      this.candidates3 = this.candidates.filter(cand => {
        return cand.nncj_progress == 4 || cand.nncj_progress == 8
      })
      // Próximo ao perfil. //
      this.candidates4 = this.candidates.filter(cand => {
        return cand.nncj_progress == 12
      })
      // Selecionados. //
      this.candidates5 = this.candidates.filter(cand => {
        return cand.nncj_status == 4
      })
      this.loadingCandidates = false
    },
    // Abrir candidato em nova aba. //
    openCandidateNewTab(object) {
      window.open(object.href, '_blank')
    },
    // Define candidato como visualizado. //
    setViewed() {
      this.$axios.put(`/jobs/candidateviewed/${this.currentCandidate.nncj_id}`).then(success => {
        this.currentCandidate.nncj_viewed = 4
      })
    }
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>

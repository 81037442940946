
<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <v-card class="bottom-detail" elevation="16" :loading="loading">
          <v-card-title>CANDIDATOS</v-card-title>
          <v-card-subtitle>AQUI VOCÊ VISUALIZA TODAS OS CANDIDATOS, PODE ACESSAR MAIS DETALHES E EXECUTAR AÇÕES</v-card-subtitle>
          <v-btn class="search-button" color="#662929" fab icon elevation="2" @click="$router.push({ name: 'ConsultantSearchCandidade' })"><v-icon>fas fa-search</v-icon></v-btn>
          <!-- <v-btn class="new-button" color="#662929" fab icon elevation="2" @click="$router.push({ name: 'NewCandidate' })"><v-icon>fas fa-plus</v-icon></v-btn> -->
          <v-divider />
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <!-- <th>NOME / EMAIL</th>
                  <th>CPF / RG</th>
                  <th>PROFISSÃO / CADASTRO</th> -->
                  <th>NOME</th>
                  <th>E-MAIL</th>
                  <th>CADASTRO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(candidate, index) in candidates" :key="index" @click="$router.push({ path: `/consultant/candidates/${candidate.cand_code}` })">
                  <!-- <td>
                    <div>{{ candidate.cand_name }}</div>
                    <div>{{ candidate.cand_email }}</div>
                  </td>
                  <td>
                    <div>{{ candidate.cand_cpf }}</div>
                    <div>{{ candidate.cand_rg }}</div>
                  </td>
                  <td>
                    <div>{{ candidate.occu_name }}</div>
                    <div>{{ candidate.cand_created | datetime }}</div>
                  </td> -->
                  <td>{{ candidate.cand_name }}</td>
                  <td>{{ candidate.cand_email }}</td>
                  <td>{{ candidate.cand_created | datetime }} </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-btn color="#662929" class="white--text mt-6" @click="getMoreCandidates()" block>CARREGAR MAIS</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ConsultantCandidates',
  data() {
    return {
      candidates: [],
      loading: true,
      paginate: 0,
    }
  },
  created() {
    this.getCandidates()
  },
  methods: {
    getCandidates() {
      this.$axios.get(`/candidates/paginate/${this.paginate}`).then(success => {
        this.candidates = success.data
        this.loading = false
      })
    },
    getMoreCandidates() {
      this.loading = true
      this.paginate += 100
      this.$axios.get(`/candidates/paginate/${this.paginate}`).then(success => {
        // this.candidates.concat(success.data)
        success.data.forEach(item => {
          this.candidates.push(item)
        })
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
  .search-button {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .new-button {
    position: absolute;
    right: 86px;
    top: 16px;
  }
</style>


<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="9">
        <v-card :loading="loading" class="bottom-detail" elevation="16">
          <v-card-title>USUÁRIO: {{ user.user_name }}</v-card-title>
          <v-card-subtitle><strong>CÓDIGO: </strong>{{ user.user_code }}</v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4" v-if="!user.user_type">
            <v-alert v-model="errorAlert" border="left" close-text="Close Alert" color="error" dark dismissible>ERRO AO BUSCAR USUÁRIOS!</v-alert>
          </div>
          <div class="pa-4" v-if="user.user_type">
            <p><strong>NOME: </strong>{{ user.user_name }}</p>
            <p><strong>E-MAIL: </strong>{{ user.user_email }}</p>
            <p><strong>TELEFONE: </strong>{{ user.user_tel }}</p>
            <p><strong>CELULAR: </strong>{{ user.user_cel }}</p>
            <p><strong>CRIADO EM: </strong>{{ user.user_created | datetime }}</p>
            <p><strong>ATUALIZADO EM: </strong>{{ user.user_updated | datetime }}</p>
            <p><strong>ESTADO DO USUÁRIO: </strong><span class="text-capitalize">{{ user.user_state | userState }}</span></p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="3" v-if="user.user_type">
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>AÇÕES</v-card-title>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-row class="py-2">
              <v-col cols="12">
                <v-btn color="#662929" class="white--text" @click="$router.push({ path: `/dashboard/users/${user.user_code}/edit` })" block>EDITAR INFOS</v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn v-if="user.user_state == 4" color="#a93121" class="white--text" block @click="changeState(2)">BLOQUEAR ACESSO</v-btn>
                <v-btn v-else color="#828a03" class="white--text" block @click="changeState(4)">DESBLOQUEAR ACESSO</v-btn>
              </v-col>
              <!-- <v-col cols="12">
                <v-btn color="#a93121" class="white--text" block>EXCLUIR</v-btn>
              </v-col> -->
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'User',
  props: [ 'id' ],
  data() {
    return {
      user: {},
      loading: true,
      errorAlert: false
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    // Retorna usuário. //
    getUser() {
      this.$axios.get(`/users/${this.id}`).then(success => {
        this.user = success.data
        this.loading = false
      }).catch(err => {
        this.errorAlert = true
        this.loading = false
      })
    },
    // Bloqueia/Desbloqueia usuário. //
    changeState(newState) {
      this.$axios.post(`/users/state/${this.user.user_id}`, { state: newState }).then(success => {
        this.getUser()
      })
    },
  }
}
</script>

<style scoped>

</style>


<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <resume-cards />
      </v-col>
      <v-col cols="12">
        <resume-tables />
      </v-col>
      <v-col cols="12">
        <resume-small-cards />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResumeCards from '../../components/ResumeCards.vue'
import ResumeTables from '../../components/ResumeTables.vue'
import ResumeSmallCards from '../../components/ResumeSmallCards.vue'

export default {
  name: 'Resume',
  components: {
    ResumeCards, ResumeTables, ResumeSmallCards
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>

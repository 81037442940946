
<template>
  <v-container fluid>
    <v-row v-if="stage == 1"  id="no-print">
      <v-col cols="12">
        <h1>RELATÓRIOS</h1>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn color="#662929" class="white--text" block @click="stage = 2">Relatório de Vagas</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn color="#662929" class="white--text" block @click="stage = 3">Candidatos por Cidade</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn color="#662929" class="white--text" block @click="stage = 4">Candidatos por Dia</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn color="#662929" class="white--text" block @click="createCanvas()">Gráfico de Vagas</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn color="#662929" class="white--text" block @click="stage = 6">Candidatos por Mês</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn color="#662929" class="white--text" block @click="stage = 7">Vagas x Atividades</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn color="#662929" class="white--text" block @click="stage = 8">Onde nos Conheceu</v-btn>
      </v-col>
    </v-row>
    <v-row v-else-if="stage == 2" id="no-print">
      <v-col cols="12">
        <h1>RELATÓRIOS > RELATÓRIO DE VAGAS <v-icon color="#662929" large @click="stage = 1">fas fa-arrow-circle-left</v-icon></h1>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="12" md="3">
            <v-date-picker color="#662929" v-model="stage2.start" range full-width elevation="2"/>
            <v-select v-model="stage2.status" :items="stage2.statusList" item-text="name" item-value="code" label="STATUS DA VAGA" class="mt-6" hide-details filled/>
            <v-select v-model="stage2.consultant" :items="stage2.consultants" item-text="name" item-value="code" label="CONSULTOR(A)" class="mt-6" hide-details filled/>
            <v-btn color="#662929" class="white--text mt-6" block @click="execStage2()">BUSCAR</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="9">
            <v-card class="card-border">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>EMPRESA / IDENTIFICADOR</th>
                    <th>VAGA / QUANTIDADE</th>
                    <th>CONSULTOR / COMERCIAL</th>
                    <th>STATUS / UNIDADE</th>
                    <th>CIDADE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(job, index) in stage2.jobs" :key="index">
                    <td>
                      <div>{{ job.comp_fantasy }}</div>
                      <div>{{ job.jobs_id }}</div>
                    </td>
                    <td>
                      <div>{{ job.occu_name }}</div>
                      <div>{{ job.jobs_qttd }}</div>
                    </td>
                    <td>
                      <div>{{ job.consultant }}</div>
                      <div>{{ job.comercial }}</div>
                    </td>
                    <td>
                      <div>{{ job.jobs_status | jobStatus }}</div>
                      <div>{{ job.unit_name }}</div>
                    </td>
                    <td>{{ job.jobs_city | cities }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-btn color="#828a03" class="white--text my-3 ml-3" @click="print()">IMPRIMIR</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="stage == 3" id="no-print">
      <v-col cols="12">
        <h1>RELATÓRIOS > CANDIDATOS POR CIDADE <v-icon color="#662929" large @click="stage = 1">fas fa-arrow-circle-left</v-icon></h1>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-date-picker color="#662929" v-model="stage3.start" range full-width elevation="2"/>
            <v-btn color="#662929" class="white--text mt-6" block @click="execStage3()">BUSCAR</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card class="card-border">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>CIDADE</th>
                    <th>QUANTIDADE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(candidate, index) in stage3.candidates" :key="index">
                    <td>{{ candidate.cand_address_city }}</td>
                    <td>{{ candidate.num }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="stage == 4" id="no-print">
      <v-col cols="12">
        <h1>RELATÓRIOS > CANDIDATOS POR DIA <v-icon color="#662929" large @click="stage = 1">fas fa-arrow-circle-left</v-icon></h1>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-date-picker color="#662929" v-model="stage4.month" full-width type="month" elevation="2"/>
            <v-btn color="#662929" class="white--text mt-6" block @click="execStage4()">BUSCAR</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card class="card-border">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>DIA DO MÊS</th>
                    <th>QUANTIDADE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(candidate, index) in stage4.candidates" :key="index">
                    <td>{{ candidate.day }}</td>
                    <td>{{ candidate.number }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="stage == 5" id="no-print">
      <v-col cols="12">
        <h1>RELATÓRIOS > GRÁFICO DE VAGAS <v-icon color="#662929" large @click="stage = 1">fas fa-arrow-circle-left</v-icon></h1>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-date-picker color="#662929" v-model="stage5.start" range full-width elevation="2"/>
            <v-btn color="#662929" class="white--text mt-6" block @click="execStage5()">BUSCAR</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <v-card class="card-border mb-6 pa-4">
              <canvas id="myChart"></canvas>
            </v-card>
            <v-card class="card-border">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>STATUS DE VAGA</th>
                    <th>QUANTIDADE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(candidate, index) in stage5.candidates" :key="index">
                    <td>{{ candidate.jobs_status | jobStatus }}</td>
                    <td>{{ candidate.num }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-btn color="#828a03" class="white--text my-3 ml-3" @click="print()">IMPRIMIR</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="stage == 6" id="no-print">
      <v-col cols="12">
        <h1>RELATÓRIOS > CANDIDATOS POR MÊS <v-icon color="#662929" large @click="stage = 1">fas fa-arrow-circle-left</v-icon></h1>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-select v-model="stage6.year" :items="stage6.years" label="ESCOLHER ANO" filled/>
            <v-btn color="#662929" class="white--text" block @click="execStage6()">BUSCAR</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card class="card-border">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>MÊS</th>
                    <th>QUANTIDADE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(candidate, index) in stage6.jobs" :key="index">
                    <td>{{ candidate.month }}</td>
                    <td>{{ candidate.num }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else-if="stage == 7" id="no-print">
      <v-col cols="12">
        <h1>RELATÓRIOS > VAGAS X ATIVIDADES <v-icon color="#662929" large @click="stage = 1">fas fa-arrow-circle-left</v-icon></h1>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="12" md="3">
            <v-date-picker color="#662929" v-model="stage7.start" range full-width elevation="2"/>
            <v-select v-model="stage7.status" :items="stage7.statusList" item-text="name" item-value="code" label="STATUS DA VAGA" class="mt-6" hide-details filled/>
            <v-select v-model="stage7.consultant" :items="stage7.consultants" item-text="name" item-value="code" label="CONSULTOR(A)" class="mt-6" hide-details filled/>
            <v-btn color="#662929" class="white--text mt-6" block @click="execStage7()">BUSCAR</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="9">
            <v-card class="card-border pb-4">
              <div v-for="(company, index) in stage7.jobs" v-show="company.jobs_historicals > 0" :key="index" class="px-4 pt-2">
                <p class="ma-0 mt-2"><strong>Identificador:</strong> {{ company.jobs_id }}</p>
                <p class="ma-0"><strong>Ocupação:</strong> {{ company.occu_name }}</p>
                <p class="ma-0"><strong>Consultor(a):</strong> {{ company.consultant }}</p>
                <p class="ma-0"><strong>Comercial:</strong> {{ company.commercial }}</p>
                <p class="ma-0"><strong>Unidade: </strong>{{ company.unit_name }}</p>
                <p class="ma-0"><strong>Empresa: </strong>{{ company.comp_fantasy }}</p>
                <p class="ma-0"><strong>Status da vaga: </strong>{{ company.jobs_status | jobStatus }}</p>
                <p class="ma-0"><strong>Data cadastro: </strong>{{ company.jobs_created | datetime }}</p>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>RESPONSAVEL</th>
                      <th>COMENTARIO</th>
                      <th>DATA / HORA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(hs, index) in company.historicals" :key="index">
                      <td>{{ hs.user_name }}</td>
                      <td>{{ hs.hs_job_message }}</td>
                      <td>{{ hs.hs_job_created | datetime }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-divider />
              </div>
              <v-btn color="#828a03" class="white--text mt-3 ml-3" @click="print()">IMPRIMIR</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col> 
    </v-row>
    <v-row v-else-if="stage == 8" id="no-print">
      <v-col cols="12">
        <h1>RELATÓRIOS > ONDE NOS CONHECEU <v-icon color="#662929" large @click="stage = 1">fas fa-arrow-circle-left</v-icon></h1>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card class="card-border">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>OPÇÃO</th>
                    <th>QUANTIDADE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(opt, index) in stage8.options" :key="index">
                    <td>{{ opt.cand_he_knew }}</td>
                    <td>{{ opt.num }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div id="section-to-print">
      <print message="RELATÓRIO">
        <div v-if="stage == 2">
          <h1 class="mt-4">RELATÓRIO DE VAGAS</h1>
          <h4>PERÍODO DE {{ stage2.start[0] | birth }} A {{ stage2.start[1] | birth }}</h4>
          <v-simple-table style="width: 100%">
            <thead>
              <tr>
                <th>EMPRESA / IDENTIFICADOR</th>
                <th>VAGA / QUANTIDADE</th>
                <th>CONSULTOR / COMERCIAL</th>
                <th>STATUS / UNIDADE</th>
                <th>CIDADE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(job, index) in stage2.jobs" :key="index">
                <td>
                  <div>{{ job.comp_fantasy }}</div>
                  <div>{{ job.jobs_id }}</div>
                </td>
                <td>
                  <div>{{ job.occu_name }}</div>
                  <div>{{ job.jobs_qttd }}</div>
                </td>
                <td>
                  <div>{{ job.consultant }}</div>
                  <div>{{ job.comercial }}</div>
                </td>
                <td>
                  <div>{{ job.jobs_status | jobStatus }}</div>
                  <div>{{ job.unit_name }}</div>
                </td>
                <td>{{ job.jobs_city | cities }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-else-if="stage == 5">
          <h1 class="mt-4">GRÁFICO DE VAGAS</h1>
          <h4>PERÍODO DE {{ stage5.start[0] | birth }} A {{ stage5.start[1] | birth }}</h4>
          <v-simple-table>
            <thead>
              <tr>
                <th>STATUS DE VAGA</th>
                <th>QUANTIDADE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(candidate, index) in stage5.candidates" :key="index">
                <td>{{ candidate.jobs_status | jobStatus }}</td>
                <td>{{ candidate.num }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-else-if="stage == 7">
          <h1 class="mt-4">VAGAS X ATIVIDADES</h1>
          <h4>PERÍODO DE {{ stage7.start[0] | birth }} A {{ stage7.start[1] | birth }}</h4>
          <div v-for="(company, index) in stage7.jobs" v-show="company.jobs_historicals > 0" :key="index" class="px-4 pt-2">
            <p class="ma-0 mt-4"><strong>{{ company.jobs_id }} - {{ company.occu_name }}</strong></p>
            <p class="ma-0"><strong>Consultor(a):</strong> {{ company.consultant }} / <strong>Comercial:</strong> {{ company.commercial }}</p>
            <p class="ma-0"><strong>Unidade: </strong>{{ company.unit_name }} / <strong>Empresa: </strong>{{ company.comp_fantasy }} / <strong>Status da vaga: </strong>{{ company.jobs_status | jobStatus }}</p>
            <v-simple-table>
              <thead>
                <tr>
                  <th>RESPONSAVEL</th>
                  <th>COMENTARIO</th>
                  <th>DATA / HORA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(hs, index) in company.historicals" :key="index">
                  <td>{{ hs.user_name }}</td>
                  <td>{{ hs.hs_job_message }}</td>
                  <td>{{ hs.hs_job_created | datetime }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-divider />
          </div>
        </div>
      </print>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment'
import print from '../../../components/Print.vue'

export default {
  name: 'Reports',
  components: { print },
  data() {
    return {
      stage: 1,
      stage2: {
        jobs: [],
        start: [moment().format('YYYY-MM-DD'), moment().add(1, 'week').format('YYYY-MM-DD')],
        status: 0,
        statusList: [
          { code: 0, name: 'TODOS' },
          { code: 2, name: 'CANCELADAS' },
          { code: 4, name: 'ABERTAS' },
          { code: 6, name: 'FECHADO P/ CONCORRENCIA' },
          { code: 8, name: 'FECHADO P/ RESOLVE' },
          { code: 10, name: 'INDICAÇÃO' },
          { code: 12, name: 'ADMINISTRAÇÃO' },
        ],
        consultant: 0,
        consultants: [
          { code: 0, name: 'TODOS' }
        ],
      },
      stage3: {
        start: [moment().format('YYYY-MM-DD'), moment().add(1, 'week').format('YYYY-MM-DD')],
        candidates: [],
      },
      stage4: {
        month: moment().format('YYYY-MM'),
        candidates: []
      },
      stage5: {
        start: [moment().format('YYYY-MM-DD'), moment().add(1, 'week').format('YYYY-MM-DD')],
        candidates: [],
      },
      stage6: {
        start: '',
        end: '',
        jobs: [],
        year: moment().format('YYYY'),
        years: [
          '2021', '2022', '2023', '2024', '2025', '2026'
        ]
      },
      stage7: {
        jobs: [],
        start: [moment().format('YYYY-MM-DD'), moment().add(1, 'week').format('YYYY-MM-DD')],
        status: 0,
        statusList: [
          { code: 0, name: 'TODOS' },
          { code: 2, name: 'CANCELADAS' },
          { code: 4, name: 'ABERTAS' },
          { code: 6, name: 'FECHADO P/ CONCORRENCIA' },
          { code: 8, name: 'FECHADO P/ RESOLVE' },
          { code: 10, name: 'INDICAÇÃO' },
          { code: 12, name: 'ADMINISTRAÇÃO' },
        ],
        consultant: 0,
        consultants: [
          { code: 0, name: 'TODOS' }
        ],
      },
      stage8: {
        options: [],
      },
      chart: undefined
    }
  },
  created() {
    this.datanew()

    this.execStage8()
  },
  methods: {
    // Obtem dados base. //
    datanew() {
      this.$axios.get(`/reports/datanew`).then(success => {
        this.stage2.consultants = Array(this.stage2.consultants[0]).concat(success.data.consultants)
        this.stage7.consultants = Array(this.stage7.consultants[0]).concat(success.data.consultants)
      })
    },
    // Obtem relatório de vagas. //
    execStage2() {
      const start = this.stage2.start[0]
      const end = this.stage2.start[1]
      this.$axios.get(`/reports/jobs/${start}/${end}?status=${this.stage2.status}&consultant=${this.stage2.consultant}`).then(success => {
        this.stage2.jobs = success.data
      })
    },
    // Obtem relatório de candidatos por cidade. //
    execStage3() {
      const start = this.stage3.start[0]
      const end = this.stage3.start[1]
      this.$axios.get(`/reports/candidatecity/${start}/${end}`).then(success => {
        this.stage3.candidates = success.data
      })
    },
    // Obtem relatório de candidatos por dia. //
    execStage4() {
      const start = moment(this.stage4.month).startOf('month').format('YYYY-MM-DD')
      const end = moment(this.stage4.month).endOf('month').format('YYYY-MM-DD')
      this.$axios.get(`/reports/candidatedays/${start}/${end}`).then(success => {
        this.stage4.candidates = success.data
      })
    },
    // Obtem relatório de vagas e status. //
    execStage5() {
      const start = this.stage5.start[0]
      const end = this.stage5.start[1]
      this.$axios.get(`/reports/jobstatus/${start}/${end}`).then(success => {
        this.stage5.candidates = success.data

        this.chart.data.labels = this.stage5.candidates.map((item) => this.$options.filters.jobStatus(item.jobs_status))
        this.chart.data.datasets.forEach((dataset) => {
          dataset.data = this.stage5.candidates.map((item) => item.num)
        })
        this.chart.update()
      })
    },
    // Obtem relatório de candidatos por mês. //
    execStage6() {
      const start = moment(`${this.stage6.year}-01-01`).format('YYYY-MM-DD')
      const end = moment(`${this.stage6.year}-12-31`).format('YYYY-MM-DD')
      this.$axios.get(`/reports/candidatemonths/${start}/${end}`).then(success => {
        this.stage6.jobs = success.data
      })
    },
    // Obtem relatório de atividades. //
    execStage7() {
      const start = this.stage7.start[0]
      const end = this.stage7.start[1]
      this.$axios.get(`/reports/activities/${start}/${end}?status=${this.stage7.status}&consultant=${this.stage7.consultant}`).then(success => {
        this.stage7.jobs = []
        success.data.forEach(item => {
          this.$axios.get(`/historicals/jobs/${item.jobs_id}`).then(success2 => {
            item.historicals = success2.data.historicals
            this.stage7.jobs.push(item)
          })
        })
      })
    },
    // Obtem relatório de candidatos por cidade. //
    execStage8() {
      this.$axios.get(`/reports/heknow`).then(success => {
        this.stage8.options = success.data
      })
    },
    // Cria grafico. //
    createCanvas() {
      this.stage = 5
      setTimeout(() => {

        this.chart = new Chart(
          document.getElementById('myChart'), {
            type: 'bar',
            data: {
              labels: this.stage5.candidates.map((item) => item.jobs_status),
              datasets: [
                {
                  label: 'GRUPO RESOLVE',
                  backgroundColor: '#662929',
                  borderColor: 'black',
                  data: this.stage5.candidates.map((item) => item.num),
                }
              ]
            },
            options: {}
          }
        )

      }, 500)
    },
    // Imprime curriculo do candidato. //
    print() {
      this.$root.$emit('evt::close')
      setTimeout(() => {
        window.print()
      }, 1000)
    },
  }
}
</script>

<style scoped>
  .card-border {
    border-bottom: solid 6px #662929 !important;
  }
</style>


<template>
  <v-container fluid>
    <v-row id="no-print">
      <v-col cols="12" sm="12" md="8">
        <v-card>
          <v-card-title>DETALHES DA PROSPECÇÃO</v-card-title>
          <v-card-subtitle>CÓDIGO: {{ prospect.pros_code }} - ID: {{ prospect.pros_id }}</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <p><strong>RAZÃO SOCIAL: </strong>{{ prospect.pros_name || 'NÃO INFORMADO!' }}</p>
            <p><strong>NOME FANTASIA: </strong>{{ prospect.pros_fantasy || 'NÃO INFORMADO!' }}</p>
            <p><strong>CNPJ: </strong>{{ prospect.pros_cnpj || 'NÃO INFORMADO!' }}</p>
            <p><strong>INSCRIÇÃO ESTADUAL: </strong>{{ prospect.pros_ie || 'NÃO INFORMADO!' }}</p>
            <p><strong>INSCRIÇÃO MUNICIPAL: </strong>{{ prospect.pros_im || 'NÃO INFORMADO!' }}</p>
            <p><strong>RAMOS DE ATIVIDADE: </strong>{{ prospect.bran_name || 'NÃO INFORMADO!' }}</p>
            <p><strong>CLIENTE GRUPO RESOLVE: </strong>{{ prospect.pros_resolve | yes_not }}</p>
            <p><strong>NOME: </strong>{{ prospect.pros_customer || 'NÃO INFORMADO!' }}</p>
            <p><strong>E-MAIL DE CONTATO: </strong>{{ prospect.pros_email || 'NÃO INFORMADO!' }}</p>
            <p><strong>TELEFONE 1: </strong>{{ prospect.pros_tel1 || 'NÃO INFORMADO!' }}</p>
            <p><strong>TELEFONE 2: </strong>{{ prospect.pros_tel2 || 'NÃO INFORMADO!' }}</p>
            <p><strong>SETOR: </strong>{{ prospect.pros_sector | sector }}</p>
            <p><strong>CEP: </strong>{{ prospect.pros_zipcode || 'NÃO INFORMADO!' }}</p>
            <p><strong>LOGRADOURO: </strong>{{ prospect.pros_address || 'NÃO INFORMADO!' }}</p>
            <p><strong>NUMERO: </strong>{{ prospect.pros_number || 'NÃO INFORMADO!' }}</p>
            <p><strong>COMPLEMENTO: </strong>{{ prospect.pros_complement || 'NÃO INFORMADO!' }}</p>
            <p><strong>BAIRRO: </strong>{{ prospect.pros_district || 'NÃO INFORMADO!' }}</p>
            <p><strong>CIDADE: </strong>{{ prospect.pros_city || 'NÃO INFORMADO!' }}</p>
            <p><strong>ESTADO: </strong>{{ prospect.pros_state || 'NÃO INFORMADO!' }}</p>
            <p><strong>DESCRIÇÃO: </strong>{{ prospect.pros_description || 'NÃO INFORMADO!' }}</p>
            <p><strong>CADASTRADO POR: </strong>{{ prospect.user_name }} - {{ prospect.user_email }}</p>
            <p><strong>CADASTRADO EM: </strong>{{ prospect.pros_created | datetime }}</p>
            <hr>
            <v-btn color="#828a03" class="white--text mt-3" @click="print()" block>IMPRIMIR</v-btn>
            <v-btn color="warning" class="white--text mt-3" @click="$router.push({ name: 'EditProspects', params: { id: prospect.pros_code } })" block>EDITAR</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card>
          <v-card-title>HISTORICO</v-card-title>
          <v-card-subtitle>ULTIMOS REGISTROS</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ history.user_name }}</v-list-item-title>
                <v-list-item-content>{{ history.hs_pros_message }}</v-list-item-content>
                <v-list-item-subtitle>{{ history.hs_pros_created | datetime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mb-4 mt-4"/>
            <v-textarea v-model="message" label="MENSAGEM" auto-grow filled/>
            <v-btn class="white--text" color="#662929" :disabled="!message.length >= 1" large block @click="newHistorical()" :loading="newHistoricalLoading">ADICIONAR</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div id="section-to-print">
      <print message="RELATÓRIO DE PROSPECÇÃO">
        <p><strong>RAZÃO SOCIAL: </strong>{{ prospect.pros_name || 'NÃO INFORMADO!' }}</p>
        <p><strong>NOME FANTASIA: </strong>{{ prospect.pros_fantasy || 'NÃO INFORMADO!' }}</p>
        <p><strong>CNPJ: </strong>{{ prospect.pros_cnpj || 'NÃO INFORMADO!' }}</p>
        <p><strong>INSCRIÇÃO ESTADUAL: </strong>{{ prospect.pros_ie || 'NÃO INFORMADO!' }}</p>
        <p><strong>INSCRIÇÃO MUNICIPAL: </strong>{{ prospect.pros_im || 'NÃO INFORMADO!' }}</p>
        <p><strong>RAMOS DE ATIVIDADE: </strong>{{ prospect.bran_name || 'NÃO INFORMADO!' }}</p>
        <p><strong>CLIENTE GRUPO RESOLVE: </strong>{{ prospect.pros_resolve | yes_not }}</p>
        <p><strong>NOME: </strong>{{ prospect.pros_customer || 'NÃO INFORMADO!' }}</p>
        <p><strong>E-MAIL DE CONTATO: </strong>{{ prospect.pros_email || 'NÃO INFORMADO!' }}</p>
        <p><strong>TELEFONE 1: </strong>{{ prospect.pros_tel1 || 'NÃO INFORMADO!' }}</p>
        <p><strong>TELEFONE 2: </strong>{{ prospect.pros_tel2 || 'NÃO INFORMADO!' }}</p>
        <p><strong>SETOR: </strong>{{ prospect.pros_sector | sector }}</p>
        <p><strong>CEP: </strong>{{ prospect.pros_zipcode || 'NÃO INFORMADO!' }}</p>
        <p><strong>LOGRADOURO: </strong>{{ prospect.pros_address || 'NÃO INFORMADO!' }}</p>
        <p><strong>NUMERO: </strong>{{ prospect.pros_number || 'NÃO INFORMADO!' }}</p>
        <p><strong>COMPLEMENTO: </strong>{{ prospect.pros_complement || 'NÃO INFORMADO!' }}</p>
        <p><strong>BAIRRO: </strong>{{ prospect.pros_district || 'NÃO INFORMADO!' }}</p>
        <p><strong>CIDADE: </strong>{{ prospect.pros_city || 'NÃO INFORMADO!' }}</p>
        <p><strong>ESTADO: </strong>{{ prospect.pros_state || 'NÃO INFORMADO!' }}</p>
        <p><strong>DESCRIÇÃO: </strong>{{ prospect.pros_description || 'NÃO INFORMADO!' }}</p>
        <p><strong>CADASTRADO POR: </strong>{{ prospect.user_name }} - {{ prospect.user_email }}</p>
        <p><strong>CADASTRADO EM: </strong>{{ prospect.pros_created | datetime }}</p>
        <v-divider class="my-6" />
        <h1>MOVIMENTAÇÃO</h1>
        <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
          <v-list-item-content>
            <v-list-item-title>{{ history.user_name }}</v-list-item-title>
            <v-list-item-content>{{ history.hs_pros_message }}</v-list-item-content>
            <v-list-item-subtitle>{{ history.hs_pros_created | datetime }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </print>
    </div>
  </v-container>
</template>

<script>
import print from '../../../components/Print.vue'

export default {
  name: 'ShowProspect',
  props: [ 'id' ],
  components: { print },
  data() {
    return {
      prospect: {},
      message: '',
      historicals: [],
      newHistoricalLoading: false,
    }
  },
  created() {
    this.load()
  },
  methods: {
    // Obtem registro da prospecção. //
    load() {
      this.$axios.get(`/prospects/${this.id}`).then(success => {
        this.prospect = success.data
        this.listHistoricals()
      })
    },
    // Adiciona historico a empresa. //
    newHistorical() {
      this.newHistoricalLoading = true
      this.$axios.post(`/historicals/prospects/${this.prospect.pros_id}`, {
        message: this.message,
        messages: this.historicals,
        prospect: {
          commercial_name: this.prospect.user_name,
          commercial_email: this.prospect.user_email
        }
      }).then(success => {
        this.message = ''
        this.listHistoricals()
        this.newHistoricalLoading = false
      })
    },
    // Obtem historicos da empresa. //
    listHistoricals() {
      this.$axios.get(`/historicals/prospects/${this.prospect.pros_id}`).then(success => {
        this.historicals = success.data.historicals
      })
    },
    // Imprime curriculo do candidato. //
    print() {
      this.$root.$emit('evt::close')
      setTimeout(() => {
        window.print()
      }, 1000)
    }
  }
}
</script>

<style scoped>

</style>

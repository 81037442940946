
<template>
  <v-container>

    <v-app-bar color="#662929" height="46" app>
      <v-app-bar-nav-icon color="#dddddd" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer />
      <template v-if="!this.$vuetify.breakpoint.mobile">
        <v-btn class="mr-2" @click="$router.push({ name: `Home` })" small>PÁGINA PRINCIPAL</v-btn>
        <v-btn @click="logout()" small>SAIR</v-btn>
      </template>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" width="240" app>
      <v-list-item>
        <v-list-item-content>
          <img src="@/assets/logoDash.jpg" width="100" alt="logo">
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          <!-- <v-list-item-subtitle>Tipo: <span class="text-capitalize">{{ user.type | userType }}</span></v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link @click="$router.push({ name: `CandidateJobs` })">
          <v-list-item-icon>
            <v-icon>fas fa-briefcase</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>STATUS DE VAGAS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$router.push({ name: `CandidateProfile` })">
          <v-list-item-icon>
            <v-icon>fas fa-newspaper</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>SEU CURRÍCULO</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$router.push({ name: `CandidateSetup` })">
          <v-list-item-icon>
            <v-icon>fas fa-tools</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>CONFIGURAÇÕES</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="this.$vuetify.breakpoint.mobile" link @click="$router.push({ name: `Home` })">
          <v-list-item-icon>
            <v-icon>fas fa-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>PÁGINA PRINCIPAL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="this.$vuetify.breakpoint.mobile" link @click="logout()">
          <v-list-item-icon>
            <v-icon>fas fa-power-off</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>SAIR DO SISTEMA</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container fluid>
      <router-view />
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'Candidate',
  data() {
    return {
      drawer: false
    }
  },
  mounted() {
    if (!this.$vuetify.breakpoint.mobile) { this.drawer = true }
  },
  methods: {
    logout() {
      localStorage.removeItem('miolocat')
      localStorage.removeItem('miolocat_userType')
      this.$store.commit('SET_USER', {
        id: '',
        name: '',
        email: '',
        type: '',
        created: '',
      })
      this.$router.push({ name: 'Home' })
    }
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style scoped>

</style>


<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="7">
        <v-card :loading="loading" class="bottom-detail" elevation="16">
          <v-card-title>UNIDADES</v-card-title>
          <hr>
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME</th>
                  <th>CÓDIGO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(unit, index) in units" :key="index" @click="select(unit)">
                  <td>{{ unit.unit_name }}</td>
                  <td>{{ unit.unit_code }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <hr class="mb-4">
            <h5>REGISTROS ENCONTRADOS: {{ meta.registries }}</h5>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="5">
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>CADASTRO</v-card-title>
          <hr>
          <div class="pa-4">
            <v-form v-model="form.valid" ref="unitForm" lazy-validation>
              <v-row class="py-2">
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.name" label="NOME *" :rules="form.rules.name" counter="60" filled/>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.tel" label="TELEFONE *" :rules="form.rules.tel" counter="40" filled/>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.cep" label="CEP *" @blur="getCEP(form.cep)" :rules="form.rules.cep" counter="8" filled/>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.logradouro" label="LOGRADOURO *" :rules="form.rules.logradouro" :disabled="form.disabled" counter="255" filled/>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.number" label="NUMERO *" :rules="form.rules.number" counter="20" filled/>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.comp" label="COMPLEMENTO" counter="255" filled/>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.district" label="BAIRRO *" :rules="form.rules.district" :disabled="form.disabled" counter="60" filled/>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.city" label="CIDADE *" :rules="form.rules.city" :disabled="form.disabled" counter="60" filled/>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.state" label="ESTADO *" :rules="form.rules.state" :disabled="form.disabled" counter="4" filled/>
                </v-col>
                <v-col cols="12">
                  <hr class="mb-4">
                  <v-btn color="#662929" class="white--text" @click="save()" block>CADASTRAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="actions" width="500" persistent>
      <v-card>
        <v-card-title>{{ currentUnit.unit_name }}</v-card-title>
        <v-card-subtitle>{{ currentUnit.unit_code }}</v-card-subtitle>
        <hr>
        <div class="pa-4">
          <v-form v-model="form2.valid" ref="unitEditForm" lazy-validation>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.name" label="NOME *" :rules="form.rules.name" counter="60" filled/>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.tel" label="TELEFONE *" :rules="form.rules.tel" counter="40" filled/>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.cep" label="CEP *" :rules="form.rules.cep" counter="8" filled/>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.logradouro" label="LOGRADOURO *" :rules="form.rules.logradouro" counter="255" filled/>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.number" label="NUMERO *" :rules="form.rules.number" counter="20" filled/>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.comp" label="COMPLEMENTO" counter="255" filled/>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.district" label="BAIRRO *" :rules="form.rules.district" counter="60" filled/>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.city" label="CIDADE *" :rules="form.rules.city" counter="60" filled/>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="form2.state" label="ESTADO *" :rules="form.rules.state" counter="4" filled/>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <hr>
        <div class="pa-4">
          <v-btn color="#828a03" class="white--text" :disabled="loading" @click="edit()">SALVAR</v-btn>
          <v-btn color="#a93121" :disabled="loading" class="ml-4 white--text" @click="remove()">REMOVER</v-btn>
          <v-btn color="#e98b0d" :disabled="loading" class="ml-4 white--text" @click="actions=false;currentUnit = {};">CANCELAR</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import cep from 'cep-promise'

export default {
  name: 'Units',
  data() {
    return {
      units: [],
      meta: { registries: 0 },
      loading: false,
      currentUnit: {},
      actions: false,
      error: { show: false, message: 'INDEFINIDO' },
      error2: { show: false, message: 'INDEFINIDO' },
      form: {
        valid: true,
        disabled: true,
        name: '',
        tel: '',
        cep: '',
        logradouro: '',
        number: '',
        comp: '',
        district: '',
        city: '',
        state: '',
        rules: {
          name: [
            v => !!v || 'Nome obrigatório.',
            v => v.length >= 4 || 'Nome não pode conter menos que 4 caracteres.',
            v => v.length <= 60 || 'Nome não pode conter mais que 60 caracteres.',
          ],
          tel: [
            v => !!v || 'Telefone obrigatório.',
            v => v.length >= 4 || 'Telefone não pode conter menos que 4 caracteres.',
            v => v.length <= 40 || 'Telefone não pode conter mais que 40 caracteres.',
          ],
          cep: [
            v => !!v || 'CEP obrigatório.',
            v => v.length >= 8 || 'CEP não pode conter menos que 8 caracteres.',
            v => v.length <= 8 || 'CEP não pode conter mais que 8 caracteres.',
          ],
          logradouro: [
            v => !!v || 'Logradouro obrigatório.',
            v => v.length >= 4 || 'Logradouro não pode conter menos que 4 caracteres.',
            v => v.length <= 255 || 'Logradouro não pode conter mais que 255 caracteres.',
          ],
          number: [
            v => !!v || 'Numero obrigatório.',
            v => v.length >= 1 || 'Numero não pode conter menos que 1 caracteres.',
            v => v.length <= 20 || 'Numero não pode conter mais que 20 caracteres.',
          ],
          district: [
            v => !!v || 'Bairro obrigatório.',
            v => v.length >= 4 || 'Bairro não pode conter menos que 4 caracteres.',
            v => v.length <= 60 || 'Bairro não pode conter mais que 60 caracteres.',
          ],
          city: [
            v => !!v || 'Cidade obrigatório.',
            v => v.length >= 4 || 'Cidade não pode conter menos que 4 caracteres.',
            v => v.length <= 60 || 'Cidade não pode conter mais que 60 caracteres.',
          ],
          state: [
            v => !!v || 'Estado obrigatório.',
            v => v.length >= 2 || 'Estado não pode conter menos que 2 caracteres.',
            v => v.length <= 4 || 'Estado não pode conter mais que 4 caracteres.',
          ],
        }
      },
      form2: {
        valid: true,
        name: '',
        tel: '',
        cep: '',
        logradouro: '',
        number: '',
        comp: '',
        district: '',
        city: '',
        state: '',
      }
    }
  },
  created() {
    this.load()
  },
  methods: {
    // Carrega unidades. //
    load() {
      this.loading = true
      this.$axios.get(`/units`).then(success => {
        this.units = success.data.units
        this.meta = success.data.meta
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.error.message = err.response.data.message
        this.error.show = true
      })
    },
    // Seleciona unidade e abre janela. //
    select(unit) {
      this.currentUnit = unit
      this.form2.name = this.currentUnit.unit_name
      this.form2.tel = this.currentUnit.unit_tel
      this.form2.cep = this.currentUnit.unit_address_cep
      this.form2.logradouro = this.currentUnit.unit_address
      this.form2.number = this.currentUnit.unit_address_number
      this.form2.comp = this.currentUnit.unit_address_comp
      this.form2.district = this.currentUnit.unit_address_district
      this.form2.city = this.currentUnit.unit_address_city
      this.form2.state = this.currentUnit.unit_address_state
      this.actions = true
    },
    // Salva nova unidade. //
    save() {
      if (this.$refs.unitForm.validate()) {
        this.loading = true
        this.$axios.post(`/units`, {
          name: this.form.name,
          tel: this.form.tel,
          addressCEP: this.form.cep,
          address: this.form.logradouro,
          addressNumber: this.form.number,
          addressComp: this.form.comp,
          addressDistrict: this.form.district,
          addressCity: this.form.city,
          addressState: this.form.state,
        }).then(success => {
          this.load()
          this.$refs.unitForm.reset()
          this.form.name = ''
        }).catch(err => {
          this.loading = false
          this.error2.message = err.response.data.message
          this.error2.show = true
        })
      }
    },
    // Editar unidade. //
    edit() {
      if (this.$refs.unitEditForm.validate()) {
        this.loading = true
        this.$axios.put(`/units/${this.currentUnit.unit_code}`, {
          name: this.form2.name,
          tel: this.form2.tel,
          addressCEP: this.form2.cep,
          address: this.form2.logradouro,
          addressNumber: this.form2.number,
          addressComp: this.form2.comp,
          addressDistrict: this.form2.district,
          addressCity: this.form2.city,
          addressState: this.form2.state,
        }).then(success => {
          this.actions = false
          this.currentUnit = {}
          this.load()
        }).catch(err => {
          this.error.message = err.response.data.message
          this.error.show = true
        })
      }
    },
    // Excluir unidade. //
    remove() {
      this.$axios.delete(`/units/${this.currentUnit.unit_code}`).then(success => {
        this.actions = false
        this.currentUnit = {}
        this.load()
      }).catch(err => {
        this.error.message = err.response.data.message
        this.error.show = true
      })
    },
    // Obtem endereço através do CEP informado. //
    getCEP(code) {
      cep(code).then(success => {
        this.form.logradouro = success.street
        this.form.district = success.neighborhood
        this.form.city = success.city
        this.form.state = success.state
        this.form.disabled = true
      }).catch(err => {
        this.form.disabled = false
      })
    }
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: '',
      name: '',
      email: '',
      type: '',
      created: '',
    }
  },
  mutations: {
    'SET_USER': (state, payload) => {
      state.user = payload
    }
  },
  getters: {
    'GET_USER': (state) => { return state.user }
  },
  actions: {
  },
  modules: {
  }
})


<template>
  <v-container>
    <v-row>
      <v-col cols=12 sm=12 md="12" lg="8">
        <v-card class="bottom-detail" elevation="16" :loading="loading">
          <v-card-title>EMPRESAS</v-card-title>
          <v-card-subtitle>AQUI VOCÊ VISUALIZA TODAS AS EMPRESAS, PODE ACESSAR MAIS DETALHES E EXECUTAR AÇÕES</v-card-subtitle>
          <hr>
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME FANTASIA / RAZÃO SOCIAL</th>
                  <th>CNPJ / E-MAIL</th>
                  <th>RESPONSAVEL / CADASTRO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(company, index) in companies" :key="index" @click="$router.push({ path: `/consultant/companies/${company.comp_code}` })">
                  <td>
                    <div>{{ company.comp_fantasy }}</div>
                    <div>{{ company.comp_name }}</div>
                  </td>
                  <td>
                    <div>{{ company.comp_cnpj }}</div>
                    <div>{{ company.comp_email }}</div>
                  </td>
                  <td>
                    <div>{{ company.comp_responsible_name }}</div>
                    <div>{{ company.comp_created | datetime }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm ="12" md="12" lg="4">
        <v-row>
          <v-col cols="12">
            <v-card class="bottom-detail" elevation="16">
              <v-card-title>PESQUISAR</v-card-title>
              <hr>
              <div class="pa-4">
                <v-text-field v-model="search" label="PESQUISAR EMPRESAS" @keyup.enter="searchCompanies()" filled/>
                <v-btn color="#662929" class="white--text" block large @click="searchCompanies()">BUSCAR</v-btn>
              </div>
            </v-card>
          </v-col>
          <!-- <v-col cols="12">
            <v-card class="bottom-detail" elevation="16">
              <v-card-title>AÇÕES</v-card-title>
              <hr>
              <div class="pa-4">
                <v-row>
                  <v-col cols="12">
                    <v-btn color="#662929" class="white--text" @click="$router.push({ name: 'NewCompany' })" block large>CADASTRO</v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ConsultantCompanies',
  data() {
    return {
      companies: [],
      loading: true,
      search: '',
    }
  },
  created() {
    this.getCompanies()
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  },
  methods: {
    // Obtem empresas. //
    getCompanies() {
      this.$axios.get(`/companies/basic`).then(success => {
        this.companies = success.data.companies
        this.loading = false
      })
    },
    // Pesquisa empresas. //
    searchCompanies() {
      if (this.search) {
        if (this.user.type != 8) {
        this.$router.push({ path: `/dashboard/companies/search?q=${this.search}` })
        } else {
          this.$router.push({ path: `/consultant/companies/search?q=${this.search}` })
        }
      }
    },
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>

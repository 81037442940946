
<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="7">
        <v-card :loading="loading" class="bottom-detail" elevation="16">
          <v-card-title>IDIOMAS</v-card-title>
          <hr>
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME</th>
                  <th>CÓDIGO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lang, index) in languages" :key="index" @click="select(lang)">
                  <td>{{ lang.lang_name }}</td>
                  <td>{{ lang.lang_code }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-alert
                v-model="error.show" border="left" close-text="Close Alert" color="error" dark dismissible>{{ error.message }}</v-alert>
            <hr class="mb-4">
            <h5>REGISTROS ENCONTRADOS: {{ meta.registries }}</h5>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="5">
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>CADASTRO</v-card-title>
          <hr>
          <div class="pa-4">
            <v-form v-model="form.valid" ref="langForm" lazy-validation>
              <v-row class="py-2">
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="form.name" :rules="form.rules.name" label="NOME *" :disabled="loading" @keypress.enter.prevent="save()" counter="60" filled/>
                <v-alert
                v-model="error2.show" border="left" close-text="Close Alert" color="error" dark dismissible>{{ error2.message }}</v-alert>
                </v-col>
                <v-col cols="12">
                  <hr class="mb-4">
                  <v-btn color="#662929" class="white--text" @click="save()" :loading="loading" block>CADASTRAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="actions" width="500" persistent>
      <v-card>
        <v-card-title>{{ currentLanguage.lang_name }}</v-card-title>
        <v-card-subtitle>{{ currentLanguage.lang_code }}</v-card-subtitle>
        <hr>
        <div class="pa-4">
          <v-form v-model="form2.valid" ref="langEditForm" lazy-validation>
            <v-text-field v-model="form2.name" :rules="form.rules.name" label="NOME *" :disabled="loading" @keypress.enter.prevent="edit()" counter="60" filled/>
          </v-form>
        </div>
        <hr>
        <div class="pa-4">
          <v-btn color="#828a03" class="white--text" :disabled="loading" @click="edit()">SALVAR</v-btn>
          <v-btn color="#a93121" :disabled="loading" class="ml-4 white--text" @click="remove()">REMOVER</v-btn>
          <v-btn color="#e98b0d" :disabled="loading" class="ml-4 white--text" @click="actions=false;currentLanguage = {};">CANCELAR</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Languages',
  data() {
    return {
      languages: [],
      meta: { registries: 0 },
      loading: false,
      currentLanguage: {},
      actions: false,
      error: { show: false, message: 'INDEFINIDO' },
      error2: { show: false, message: 'INDEFINIDO' },
      form: {
        valid: true,
        name: '',
        rules: {
          name: [
            v => !!v || 'Nome obrigatório.',
            v => v.length >= 4 || 'Nome não pode conter menos que 4 caracteres.',
            v => v.length <= 60 || 'Nome não pode conter mais que 60 caracteres.',
          ],
        }
      },
      form2: {
        valid: true,
        name: ''
      }
    }
  },
  created() {
    this.load()
  },
  methods: {
    // Carrega idiomas. //
    load() {
      this.loading = true
      this.$axios.get(`/languages`).then(success => {
        this.languages = success.data.languages
        this.meta = success.data.meta
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.error.message = err.response.data.message
        this.error.show = true
      })
    },
    // Salva nova idioma. //
    save() {
      if (this.$refs.langForm.validate()) {
        this.loading = true
        this.$axios.post(`/languages`, {
          name: this.form.name
        }).then(success => {
          this.load()
          this.$refs.langForm.reset()
          this.form.name = ''
        }).catch(err => {
          this.loading = false
          this.error2.message = err.response.data.message
          this.error2.show = true
        })
      }
    },
    // Seleciona idioma e abre janela. //
    select(language) {
      this.currentLanguage = language
      this.form2.name = this.currentLanguage.lang_name
      this.actions = true
    },

    // Editar idioma. //
    edit() {
      if (this.$refs.langEditForm.validate()) {
        this.loading = true
        this.$axios.put(`/languages/${this.currentLanguage.lang_code}`, {
          name: this.form2.name
        }).then(success => {
          this.actions = false
          this.currentLanguage = {}
          this.load()
        }).catch(err => {
          this.error.message = err.response.data.message
          this.error.show = true
        })
      }
    },

    // Excluir idioma. //
    remove() {
      this.$axios.delete(`/languages/${this.currentLanguage.lang_code}`).then(success => {
        this.actions = false
        this.currentLanguage = {}
        this.load()
      }).catch(err => {
        this.error.message = err.response.data.message
        this.error.show = true
      })
    }
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>


<template>
  <v-container>
    <v-row id="candidate-container">
      <v-col cols="12" md="7">
        <v-card class="bottom-detail" elevation="16" :loading="loading">
          <v-card-title>DETALHES</v-card-title>
          <v-card-subtitle>{{ id }}</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <p><strong>NOME: </strong>{{ candidate.cand_name }} <v-icon v-if="candidate.cand_deficient == 4" color="blue">fas fa-wheelchair</v-icon></p>
            <p v-if="candidate.cand_deficient == 4"><strong>CID: </strong>{{ candidate.cand_cid }} - <strong>DEPENDE DE APARELHOS: </strong>{{ candidate.cand_deficient_apparatus | deficientApparatus }}</p>
            <p v-if="candidate.cand_deficient == 4"><strong>TIPO DA DEFICIÊNCIA: </strong>{{ candidate.cand_deficient_type | deficientType }}</p>
            <p><strong>E-MAIL: </strong>{{ candidate.cand_email }}</p>
            <p><strong>CPF: </strong>{{ candidate.cand_cpf }} - <strong>RG: </strong>{{ candidate.cand_rg }}</p>
            <p><strong>PIS: </strong>{{ candidate.cand_pis }}</p>
            <p><strong>NATURALIDADE: </strong>{{ candidate.cand_naturalness }} - <strong>NACIONALIDADE: </strong>{{ candidate.cand_nationality }}</p>
            <p><strong>NASCIMENTO: </strong>{{ candidate.cand_birth | birthutc }} - <strong>ALTURA: </strong>{{ candidate.cand_height }}</p>
            <p><strong>GENERO: </strong>{{ candidate.cand_sex | sex }} - <strong>ESTADO CIVIL: </strong>{{ candidate.cand_marital_status | civil }}</p>
            <p><strong>ENDEREÇO: </strong>{{ candidate.cand_address }}, {{ candidate.cand_address_number }} {{ candidate.cand_address_comp }} - {{ candidate.cand_address_district }} - {{ candidate.cand_address_city }}/{{ candidate.cand_address_state }} - CEP: {{ candidate.cand_address_cep }}</p>
            <p><strong>ZONA: </strong>{{ candidate.cand_address_zone }}</p>
            <p><strong>TELEFONE 1: </strong>{{ candidate.cand_tel1 }} <span v-if="candidate.cand_tel1_wpp == 4"><v-icon color="success" @click="openWhatsapp(candidate.cand_tel1)">fab fa-whatsapp-square</v-icon></span></p>
            <p><strong>TELEFONE 2: </strong>{{ candidate.cand_tel2 }} <span v-if="candidate.cand_tel2_wpp == 4"><v-icon color="success" @click="openWhatsapp(candidate.cand_tel2)">fab fa-whatsapp-square</v-icon></span></p>
            <p><strong>POSSUI CNH: </strong>{{ candidate.cand_cnh | yes_not }} - <strong>CATEGORIA(S): </strong> {{ candidate.cand_cnh_categories }}</p>
            <p><strong>POSSUI VEICULO: </strong>{{ candidate.cand_vehicle | yes_not }}</p>
            <v-divider class="mb-4" />
            <p><strong>ESCOLARIDADE: </strong>{{ candidate.scho_name }}</p>
            <p><strong>PROFISSÃO: </strong>{{ candidate.occu_name }}</p>
            <pre class="mb-4"><strong>DESCRIÇÃO DA FORMAÇÃO: </strong>{{ candidate.cand_education }}</pre>
            <v-divider class="mb-4" />
            <p><strong>CURSOS: </strong>
              <ul>
                <li v-for="(info, index) in candidate.infos" :key="index" class="py-2">{{ info.info_name }} <v-icon color="error" @click="removeInfo(info.nnci_code)" small>fas fa-trash-alt</v-icon></li>
              </ul>
            </p>
            <v-divider class="mb-4" />
            <p><strong>IDIOMAS: </strong>
              <ul>
                <li v-for="(language, index) in candidate.languages" :key="index" class="py-2">{{ language.lang_name }} ({{ language.nncl_level | language }}) <v-icon color="error" @click="removeLang(language.nncl_code)" small>fas fa-trash-alt</v-icon></li>
              </ul>
            </p>
            <v-divider class="mb-4" />
            <pre><strong>Descrição: </strong>{{ candidate.cand_description }}</pre>
            <pre><strong>Experiencia: </strong></pre>
            <pre>{{ candidate.cand_exp }}</pre>
            <v-divider class="mb-4" />
            <p><strong>EMPREGADO: </strong>{{ candidate.cand_employee | yes_not }}</p>
            <p><strong>PRETENSÃO SALARIAL: </strong>{{ candidate.sala_name }}</p>
            <p><strong>COMO NOS CONHECEU: </strong>{{ candidate.cand_he_knew }}</p>
            <v-divider class="mb-4" />
            <!--<p><strong>PCD: </strong>{{ candidate.cand_deficient | yes_not }}</p>
            <p v-show="candidate.cand_deficient == 4"><strong>DEPENDE DE APARELHOS: </strong>{{ candidate.cand_deficient_apparatus | yes_not }}</p>
            <p v-show="candidate.cand_deficient == 4"><strong>TIPO DE DEFICIENCIA: </strong>{{ candidate.cand_deficient_type }}</p>
            <v-divider class="mb-4" />-->
            <v-btn color="#e98b0d" class="white--text" @click="$router.push({ path: `/dashboard/candidates/${id}/edit` })" block>EDITAR</v-btn>
            <v-btn v-if="user.type == 4 || user.type == 6" color="#a93121" class="white--text mt-3" @click="deleteAccount()" block>EXCLUIR CANDIDATO</v-btn>
            <v-btn color="#828a03" class="white--text mt-3" @click="reportType = 2; print()" block>IMPRIMIR RELATÓRIO INTERNO</v-btn>
            <v-btn color="#1F5673" class="white--text mt-3" @click="reportType = 4; print()" block>IMPRIMIR RELATÓRIO EXTERNO</v-btn>
            <v-btn color="#533747" class="white--text mt-3" @click="reportType = 6; print()" block>IMPRIMIR COM MOVIMENTAÇÕES</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card class="bottom-detail">
          <v-card-title>HISTORICO</v-card-title>
          <v-card-subtitle>ULTIMOS REGISTROS</v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ history.user_name }}</v-list-item-title>
                <v-list-item-content>{{ history.hs_cand_message }}</v-list-item-content>
                <v-list-item-subtitle>{{ history.hs_cand_created | datetime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mb-4 mt-4"/>
            <v-textarea v-model="message" label="MENSAGEM" auto-grow filled/>
            <v-btn class="white--text" color="#662929" :disabled="!message.length >= 1" large block @click="newHistorical()" :loading="newHistoricalLoading">ADICIONAR</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <v-card class="bottom-detail">
          <v-card-title>VAGAS</v-card-title>
          <v-card-subtitle>LISTAGEM COM TODAS AS VAGAS AS QUAIS ESTE USUÁRIO SE CANDIDATOU</v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME / RAZÃO SOCIAL</th>
                  <th>IDENTIFICADOR / OCUPAÇÕES</th>
                  <th>INICIO / FIM</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(job, index) in jobs" :key="index" @click="$router.push({ path: `/dashboard/jobs/${job.jobs_code}` })">
                  <td>
                    <div>{{ job.comp_fantasy }}</div>
                    <div>{{ job.comp_name }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_id }}</div>
                    <div>{{ job.occu_name }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_start | birth }}</div>
                    <div>{{ job.jobs_end | birth }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div id="section-to-print">
      <print message="RELATÓRIO DE CANDIDATO COMPLETO" v-if="reportType == 2">
        <div class="pa-4">
          <p><strong>NOME: </strong>{{ candidate.cand_name }} <v-icon v-if="candidate.cand_deficient == 4" color="blue">fas fa-wheelchair</v-icon></p>
          <p v-if="candidate.cand_deficient == 4"><strong>CID: </strong>{{ candidate.cand_cid }} - <strong>DEPENDE DE APARELHOS: </strong>{{ candidate.cand_deficient_apparatus | deficientApparatus }}</p>
          <p v-if="candidate.cand_deficient == 4"><strong>TIPO DA DEFICIÊNCIA: </strong>{{ candidate.cand_deficient_type | deficientType }}</p>
          <p><strong>E-MAIL: </strong>{{ candidate.cand_email }}</p>
          <p><strong>CPF: </strong>{{ candidate.cand_cpf }} - <strong>RG: </strong>{{ candidate.cand_rg }}</p>
          <p><strong>NATURALIDADE: </strong>{{ candidate.cand_naturalness }} - <strong>NACIONALIDADE: </strong>{{ candidate.cand_nationality }}</p>
          <p><strong>NASCIMENTO: </strong>{{ candidate.cand_birth | birthutc }} - <strong>ALTURA: </strong>{{ candidate.cand_height }}</p>
          <p><strong>GENERO: </strong>{{ candidate.cand_sex | sex }} - <strong>ESTADO CIVIL: </strong>{{ candidate.cand_marital_status | civil }}</p>
          <p><strong>ENDEREÇO: </strong>{{ candidate.cand_address }}, {{ candidate.cand_address_number }} {{ candidate.cand_address_comp }} - {{ candidate.cand_address_district }} - {{ candidate.cand_address_city }}/{{ candidate.cand_address_state }} - CEP: {{ candidate.cand_address_cep }}</p>
          <p><strong>TELEFONE 1: </strong>{{ candidate.cand_tel1 }} <span v-if="candidate.cand_tel1_wpp == 4"><v-icon color="success" @click="openWhatsapp(candidate.cand_tel1)">fab fa-whatsapp-square</v-icon></span></p>
          <p><strong>TELEFONE 2: </strong>{{ candidate.cand_tel2 }} <span v-if="candidate.cand_tel2_wpp == 4"><v-icon color="success" @click="openWhatsapp(candidate.cand_tel2)">fab fa-whatsapp-square</v-icon></span></p>
          <p><strong>POSSUI CNH: </strong>{{ candidate.cand_cnh | yes_not }} - <strong>CATEGORIA(S): </strong> {{ candidate.cand_cnh_categories }}</p>
          <p><strong>POSSUI VEICULO: </strong>{{ candidate.cand_vehicle | yes_not }}</p>
          <v-divider class="mb-4" />
          <p><strong>ESCOLARIDADE: </strong>{{ candidate.scho_name }}</p>
          <p><strong>PROFISSÃO: </strong>{{ candidate.occu_name }}</p>
          <pre class="mb-4"><strong>DESCRIÇÃO DA FORMAÇÃO: </strong>{{ candidate.cand_education }}</pre>
          <v-divider class="mb-4" />
          <p><strong>CURSOS: </strong>
            <ul>
              <li v-for="(info, index) in candidate.infos" :key="index" class="py-2">{{ info.info_name }}</li>
            </ul>
          </p>
          <v-divider class="mb-4" />
          <p><strong>IDIOMAS: </strong>
            <ul>
              <li v-for="(language, index) in candidate.languages" :key="index" class="py-2">{{ language.lang_name }} ({{ language.nncl_level | language }})</li>
            </ul>
          </p>
          <v-divider class="mb-4" />
          <pre><strong>Descrição: </strong>{{ candidate.cand_description }}</pre>
          <pre><strong>Experiencia: </strong>{{ candidate.cand_exp }}</pre>
          <v-divider class="mb-4" />
          <p><strong>EMPREGADO: </strong>{{ candidate.cand_employee | yes_not }}</p>
          <p><strong>PRETENSÃO SALARIAL: </strong>{{ candidate.sala_name }}</p>
          <p><strong>COMO NOS CONHECEU: </strong>{{ candidate.cand_he_knew }}</p>
          <!-- <v-divider class="mb-4" /> -->
          <!-- <p><strong>PCD: </strong>{{ candidate.cand_deficient | yes_not }}</p>
          <p v-show="candidate.cand_deficient == 4"><strong>DEPENDE DE APARELHOS: </strong>{{ candidate.cand_deficient_apparatus | yes_not }}</p>
          <p v-show="candidate.cand_deficient == 4"><strong>TIPO DE DEFICIENCIA: </strong>{{ candidate.cand_deficient_type }}</p> -->
          <!--<v-divider />
          <h2 class="mt-6">MOVIMENTAÇÕES</h2>
          <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ history.user_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ history.hs_cand_message }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ history.hs_cand_created | datetime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>-->
        </div>
      </print>
      <print message="RELATÓRIO DE CANDIDATO" v-else-if="reportType == 4">
        <div class="pa-4">
          <p><strong>NOME: </strong>{{ candidate.cand_name }} <v-icon v-if="candidate.cand_deficient == 4" color="blue">fas fa-wheelchair</v-icon></p>
          <p v-if="candidate.cand_deficient == 4"><strong>CID: </strong>{{ candidate.cand_cid }} - <strong>DEPENDE DE APARELHOS: </strong>{{ candidate.cand_deficient_apparatus | deficientApparatus }}</p>
          <p v-if="candidate.cand_deficient == 4"><strong>TIPO DA DEFICIÊNCIA: </strong>{{ candidate.cand_deficient_type | deficientType }}</p>
          <!-- <p><strong>E-MAIL: </strong>{{ candidate.cand_email }}</p> -->
          <!-- <p><strong>CPF: </strong>{{ candidate.cand_cpf }} - <strong>RG: </strong>{{ candidate.cand_rg }}</p> -->
          <p><strong>NATURALIDADE: </strong>{{ candidate.cand_naturalness }} - <strong>NACIONALIDADE: </strong>{{ candidate.cand_nationality }}</p>
          <p><strong>NASCIMENTO: </strong>{{ candidate.cand_birth | birthutc }}</p>
          <p><strong>GENERO: </strong>{{ candidate.cand_sex | sex }} - <strong>ESTADO CIVIL: </strong>{{ candidate.cand_marital_status | civil }}</p>
          <!-- <p><strong>ENDEREÇO: </strong>{{ candidate.cand_address }}, {{ candidate.cand_address_number }} {{ candidate.cand_address_comp }} - {{ candidate.cand_address_district }} - {{ candidate.cand_address_city }}/{{ candidate.cand_address_state }} - CEP: {{ candidate.cand_address_cep }}</p> -->
          <!-- <p><strong>TELEFONE 1: </strong>{{ candidate.cand_tel1 }} <span v-if="candidate.cand_tel1_wpp == 4"><v-icon color="success" @click="openWhatsapp(candidate.cand_tel1)">fab fa-whatsapp-square</v-icon></span></p> -->
          <!-- <p><strong>TELEFONE 2: </strong>{{ candidate.cand_tel2 }} <span v-if="candidate.cand_tel2_wpp == 4"><v-icon color="success" @click="openWhatsapp(candidate.cand_tel2)">fab fa-whatsapp-square</v-icon></span></p> -->
          <p><strong>POSSUI CNH: </strong>{{ candidate.cand_cnh | yes_not }} - <strong>CATEGORIA(S): </strong> {{ candidate.cand_cnh_categories }}</p>
          <p><strong>POSSUI VEICULO: </strong>{{ candidate.cand_vehicle | yes_not }}</p>
          <v-divider class="mb-4" />
          <p><strong>ESCOLARIDADE: </strong>{{ candidate.scho_name }}</p>
          <p><strong>PROFISSÃO: </strong>{{ candidate.occu_name }}</p>
          <pre class="mb-4"><strong>DESCRIÇÃO DA FORMAÇÃO: </strong>{{ candidate.cand_education }}</pre>
          <v-divider class="mb-4" />
          <p><strong>CURSOS: </strong>
            <ul>
              <li v-for="(info, index) in candidate.infos" :key="index" class="py-2">{{ info.info_name }}</li>
            </ul>
          </p>
          <v-divider class="mb-4" />
          <p><strong>IDIOMAS: </strong>
            <ul>
              <li v-for="(language, index) in candidate.languages" :key="index" class="py-2">{{ language.lang_name }} ({{ language.nncl_level | language }})</li>
            </ul>
          </p>
          <v-divider class="mb-4" />
          <pre><strong>Descrição: </strong>{{ candidate.cand_description }}</pre>
          <pre><strong>Experiencia: </strong>{{ candidate.cand_exp }}</pre>
          <v-divider class="mb-4" />
          <p><strong>EMPREGADO: </strong>{{ candidate.cand_employee | yes_not }}</p>
          <p><strong>PRETENSÃO SALARIAL: </strong>{{ candidate.sala_name }}</p>
          <!-- <p><strong>COMO NOS CONHECEU: </strong>{{ candidate.cand_he_knew }}</p> -->
          <!-- <v-divider class="mb-4" /> -->
          <!-- <p><strong>PCD: </strong>{{ candidate.cand_deficient | yes_not }}</p>
          <p v-show="candidate.cand_deficient == 4"><strong>DEPENDE DE APARELHOS: </strong>{{ candidate.cand_deficient_apparatus | yes_not }}</p>
          <p v-show="candidate.cand_deficient == 4"><strong>TIPO DE DEFICIENCIA: </strong>{{ candidate.cand_deficient_type }}</p> -->
        </div>
      </print>
      <print message="RELATÓRIO DE CANDIDATO E MOVIMENTAÇÕES" v-else-if="reportType == 6">
        <div class="pa-4">
          <p><strong>NOME: </strong>{{ candidate.cand_name }} <v-icon v-if="candidate.cand_deficient == 4" color="blue">fas fa-wheelchair</v-icon></p>
          <p v-if="candidate.cand_deficient == 4"><strong>CID: </strong>{{ candidate.cand_cid }} - <strong>DEPENDE DE APARELHOS: </strong>{{ candidate.cand_deficient_apparatus | deficientApparatus }}</p>
          <p v-if="candidate.cand_deficient == 4"><strong>TIPO DA DEFICIÊNCIA: </strong>{{ candidate.cand_deficient_type | deficientType }}</p>
          <p><strong>E-MAIL: </strong>{{ candidate.cand_email }}</p>
          <p><strong>CPF: </strong>{{ candidate.cand_cpf }} - <strong>RG: </strong>{{ candidate.cand_rg }}</p>
          <p><strong>NATURALIDADE: </strong>{{ candidate.cand_naturalness }} - <strong>NACIONALIDADE: </strong>{{ candidate.cand_nationality }}</p>
          <p><strong>NASCIMENTO: </strong>{{ candidate.cand_birth | birthutc }} - <strong>ALTURA: </strong>{{ candidate.cand_height }}</p>
          <p><strong>GENERO: </strong>{{ candidate.cand_sex | sex }} - <strong>ESTADO CIVIL: </strong>{{ candidate.cand_marital_status | civil }}</p>
          <p><strong>ENDEREÇO: </strong>{{ candidate.cand_address }}, {{ candidate.cand_address_number }} {{ candidate.cand_address_comp }} - {{ candidate.cand_address_district }} - {{ candidate.cand_address_city }}/{{ candidate.cand_address_state }} - CEP: {{ candidate.cand_address_cep }}</p>
          <p><strong>ZONA: </strong>{{ candidate.cand_address_zone }}</p>
          <p><strong>TELEFONE 1: </strong>{{ candidate.cand_tel1 }} <span v-if="candidate.cand_tel1_wpp == 4"><v-icon color="success" @click="openWhatsapp(candidate.cand_tel1)">fab fa-whatsapp-square</v-icon></span></p>
          <p><strong>TELEFONE 2: </strong>{{ candidate.cand_tel2 }} <span v-if="candidate.cand_tel2_wpp == 4"><v-icon color="success" @click="openWhatsapp(candidate.cand_tel2)">fab fa-whatsapp-square</v-icon></span></p>
          <p><strong>POSSUI CNH: </strong>{{ candidate.cand_cnh | yes_not }} - <strong>CATEGORIA(S): </strong> {{ candidate.cand_cnh_categories }}</p>
          <p><strong>POSSUI VEICULO: </strong>{{ candidate.cand_vehicle | yes_not }}</p>
          <v-divider class="mb-4" />
          <p><strong>ESCOLARIDADE: </strong>{{ candidate.scho_name }}</p>
          <p><strong>PROFISSÃO: </strong>{{ candidate.occu_name }}</p>
          <pre class="mb-4"><strong>DESCRIÇÃO DA FORMAÇÃO: </strong>{{ candidate.cand_education }}</pre>
          <v-divider class="mb-4" />
          <p><strong>CURSOS: </strong>
            <ul>
              <li v-for="(info, index) in candidate.infos" :key="index" class="py-2">{{ info.info_name }}</li>
            </ul>
          </p>
          <v-divider class="mb-4" />
          <p><strong>IDIOMAS: </strong>
            <ul>
              <li v-for="(language, index) in candidate.languages" :key="index" class="py-2">{{ language.lang_name }} ({{ language.nncl_level | language }})</li>
            </ul>
          </p>
          <v-divider class="mb-4" />
          <pre><strong>Descrição: </strong>{{ candidate.cand_description }}</pre>
          <pre><strong>Experiencia: </strong>{{ candidate.cand_exp }}</pre>
          <v-divider class="mb-4" />
          <p><strong>EMPREGADO: </strong>{{ candidate.cand_employee | yes_not }}</p>
          <p><strong>PRETENSÃO SALARIAL: </strong>{{ candidate.sala_name }}</p>
          <p><strong>COMO NOS CONHECEU: </strong>{{ candidate.cand_he_knew }}</p>
          <!-- <v-divider class="mb-4" /> -->
          <!-- <p><strong>PCD: </strong>{{ candidate.cand_deficient | yes_not }}</p>
          <p v-show="candidate.cand_deficient == 4"><strong>DEPENDE DE APARELHOS: </strong>{{ candidate.cand_deficient_apparatus | yes_not }}</p>
          <p v-show="candidate.cand_deficient == 4"><strong>TIPO DE DEFICIENCIA: </strong>{{ candidate.cand_deficient_type }}</p> -->
          <v-divider />
          <h2 class="mt-6">MOVIMENTAÇÕES</h2>
          <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ history.user_name }}</v-list-item-title>
                <v-list-item-content>{{ history.hs_cand_message }}</v-list-item-content>
                <v-list-item-subtitle>{{ history.hs_cand_created | datetime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </div>
      </print>
    </div>
  </v-container>
</template>

<script>
import print from '../../../components/Print.vue'

export default {
  name: 'ShowCandidate',
  props: [ 'id' ],
  components: { print },
  data() {
    return {
      loading: true,
      candidate: {},
      message: '',
      historicals: [],
      newHistoricalLoading: false,
      reportType: undefined,
      jobs: []
    }
  },
  created() {
    this.getCandidate()
  },
  methods: {
    // Obtem dados do candidato. //
    getCandidate() {
      this.$axios.get(`/candidates/${this.id}`).then(success => {
        this.candidate = success.data
        this.loading = false
        this.listHistoricals()
        this.getJobs()
      })
    },
    // Abrir conversa no Whatsapp. //
    openWhatsapp(number) {
      // window.open(`https://api.whatsapp.com/send?phone=55${number}`)
      window.open(`https://api.whatsapp.com/send?phone=55${number.replace(/\D/g, '')}`)
    },
    // Remove info. //
    removeInfo(code) {
      this.$axios.delete(`/candidates/infos/${code}`).then(success => {
        this.loading = true
        this.candidate = {}
        this.getCandidate()
      })
    },
    // Remove idioma. //
    removeLang(code) {
      this.$axios.delete(`/candidates/languages/${code}`).then(success => {
        this.loading = true
        this.candidate = {}
        this.getCandidate()
      })
    },
    // Adiciona historico a Candidato. //
    newHistorical() {
      this.newHistoricalLoading = true
      this.$axios.post(`/historicals/candidates/${this.candidate.cand_id}`, {
        message: this.message,
        messages: this.historicals,
        candidate: {
          name: this.candidate.cand_name,
          email: this.candidate.cand_email,
          cpf: this.candidate.cand_cpf,
          code: this.candidate.cand_code
        }
      }).then(success => {
        this.message = ''
        this.listHistoricals()
        this.newHistoricalLoading = false
      })
    },
    // Obtem historicos de Candidato. //
    listHistoricals() {
      this.$axios.get(`/historicals/candidates/${this.candidate.cand_id}`).then(success => {
        this.historicals = success.data.historicals
      })
    },
    // Imprime curriculo do candidato. //
    print() {
      this.$root.$emit('evt::close')
      setTimeout(() => {
        window.print()
      }, 1000)
    },
    // Excluir conta e dados. //
    deleteAccount() {
      this.$axios.delete(`/candidates/${this.candidate.cand_id}`).then(success => {
        this.$router.push({ name: 'Candidates' })
      }).catch(err => {
        
      })
    },
    // Obtem vagas do candidato. //
    getJobs() {
      this.$axios.get(`/jobs/candidate/${this.candidate.cand_id}`).then(sucess => {
        this.jobs = sucess.data.jobs
      })
    }
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style scoped>
  pre { white-space: pre-wrap; }
</style>

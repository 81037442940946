
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card>
          <v-card-title>EDITAR PROSPECÇÃO</v-card-title>
          <v-card-subtitle>CÓDIGO: {{ id }}</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.name" label="RAZÃO SOCIAL" hide-details filled counter/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.fantasy" label="NOME FANTASIA" hide-details filled counter/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.cnpj" label="CNPJ" v-mask="`##.###.###/####-##`" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.ie" label="INSCRIÇÃO ESTADUAL" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.im" label="INSCRIÇÃO MUNICIPAL" hide-details filled counter/>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="form.branch" label="RAMO DE ATIVIDADE" :items="branches" item-text="bran_name" item-value="bran_id" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.resolve" label="CLIENTE RESOLVE" :items="yes_not" item-text="name" item-value="code" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.customer" label="NOME DO CONTATO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.email" label="E-MAIL DE CONTATO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.telephone1" label="TELEFONE 1" v-mask="`(##) #########`" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.wpp1" label="WHATSAPP?" :items="yes_not" item-text="name" item-value="code" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.telephone2" label="TELEFONE 2" v-mask="`(##) #########`" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.wpp2" label="WHATSAPP?" :items="yes_not" item-text="name" item-value="code" hide-details filled counter/>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="form.sector" label="SETOR DE CONTATO" :items="sectors" item-text="name" item-value="code" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.zipcode" label="CEP" v-mask="`########`" @blur="getCEP(form.zipcode)" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.address" label="LOGRADOURO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.number" label="NUMERO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.complement" label="COMPLEMENTO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.district" label="BAIRRO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.city" label="CIDADE" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.state" label="UF ESTADO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.description" label="DESCRIÇÃO" filled />
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-divider />
          <div>
            <!--<v-btn class="ml-4 my-4" color="warning" @click="newProspect = false">FECHAR</v-btn>-->
            <v-btn color="#828a03" class="white--text ml-4 my-4" @click="save()">SALVAR</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>  
</template>

<script>
import cep from 'cep-promise'

export default {
  name: 'EditProspects',
  props: [ 'id' ],
  data() {
    return {
      loadingProspects: false,
      newProspect: false,
      form: {
        name: '',
        fantasy: '',
        cnpj: '',
        ie: '',
        im: '',
        branch: null,
        resolve: 4,
        customer: '',
        email: '',
        telephone1: '',
        wpp1: 2,
        telephone2: '',
        wpp2: 2,
        sector: null,
        zipcode: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        description: '',
      },
      sectors: [
        { name: 'NENHUM', code: null },
        { name: 'DEPTO. RH', code: 2 },
        { name: 'DEPTO. COMERCIAL', code: 4 },
        { name: 'DIRETORIA', code: 6 },
      ],
      yes_not: [
        { name: 'NÃO', code: 2 },
        { name: 'SIM', code: 4 },
      ],
      branches: [],
      prospects: [],
    }
  },
  created() {
    this.getDataNew()
    this.load()
  },
  methods: {
    // Obtem dados base. //
    getDataNew() {
      this.$axios.get(`/prospects/datanew`).then(success => {
        this.branches = success.data.branches
        this.branches.unshift({ bran_id: null, bran_name: 'NENHUM' })
      })
    },
    // Obtem endereço através do CEP informado. //
    getCEP(code) {
      cep(code).then(success => {
        this.form.address = success.street
        this.form.district = success.neighborhood
        this.form.city = success.city
        this.form.state = success.state
      })
    },
    // Registra nova prospecção. //
    save() {
      this.$axios.put(`/prospects/${this.id}`, {
        form: this.form
      }).then(success => {
        this.newProspect = false
        this.$router.push({ name: 'ShowProspects', params: { id: this.id } })
      })
    },
    // Obtem registro da prospecção. //
    load() {
      this.$axios.get(`/prospects/${this.id}`).then(success => {
        this.form = {
          name: success.data.pros_name,
          fantasy: success.data.pros_fantasy,
          cnpj: success.data.pros_cnpj,
          ie: success.data.pros_ie,
          im: success.data.pros_im,
          branch: success.data.pros_branch,
          resolve: success.data.pros_resolve,
          customer: success.data.pros_customer,
          email: success.data.pros_email,
          telephone1: success.data.pros_tel1,
          telephone2: success.data.pros_tel2,
          wpp1: success.data.pros_wpp1,
          wpp2: success.data.pros_wpp2,
          sector: success.data.pros_sector,
          zipcode: success.data.pros_zipcode,
          address: success.data.pros_address,
          number: success.data.pros_number,
          complement: success.data.pros_complement,
          district: success.data.pros_district,
          city: success.data.pros_city,
          state: success.data.pros_state,
          description: success.data.pros_description,
        }
      })
    },
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>


<template>
  <v-row>
    <v-col cols="12" sm="12" md="8">
      <v-card class="bottom-elem" elevation="16">
        <v-simple-table>
          <thead>
            <tr>
              <th>EMPRESA</th>
              <th>OCUPAÇÃO</th>
              <th>DATA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(job, index) in registries.jobs" :key="index" @click="$router.push({ path: `/dashboard/jobs/${job.jobs_code}` })">
              <td>
                <div>{{ job.comp_fantasy }}</div>
                <div>{{ job.comp_name }}</div>
              </td>
              <td>
                <!-- <div>{{ job.bran_name }}</div> -->
                <div>{{ job.occu_name }}</div>
              </td>
              <td>
                <div>{{ job.jobs_start | birth }}</div>
                <div>{{ job.jobs_end | birth }}</div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-divider />
        <div class="text-center font-weight-light py-1">ULTIMAS VAGAS CADASTRADAS</div>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md=4>
      <v-card class="bottom-elem" elevation="16">
        <v-simple-table>
          <thead>
            <tr>
              <th>NOME / E-MAIL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(candidate, index) in registries.candidates" :key="index" @click="$router.push({ path: `/dashboard/candidates/${candidate.cand_code}` })">
              <td>
                <div>{{ candidate.cand_name }}</div>
                <div>{{ candidate.cand_email }}</div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-divider />
        <div class="text-center font-weight-light py-1">ULTIMOS CANDIDATOS</div>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12">
      <v-card class="bottom-elem" elevation="16">
        <v-simple-table>
          <thead>
            <tr>
              <th>NOME / RAZÃO SOCIAL</th>
              <th>CNPJ / E-MAIL</th>
              <th>RESPONSAVEL / DATA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(company, index) in registries.companies" :key="index" @click="$router.push({ path: `/dashboard/companies/${company.comp_code}` })">
              <td>
                <div>{{ company.comp_fantasy }}</div>
                <div>{{ company.comp_name }}</div>
              </td>
              <td>
                <div>{{ company.comp_cnpj }}</div>
                <div>{{ company.comp_email }}</div>
              </td>
              <td>
                <div>{{ company.comp_responsible_name }}</div>
                <div>{{ company.comp_created | birth }}</div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-divider />
        <div class="text-center font-weight-light py-1">ULTIMAS EMPRESAS CADASTRADAS</div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ResumeTables',
  data() {
    return {
      registries: {}
    }
  },
  created() {
    this.getTables()
  },
  methods: {
    // Obtem resumos de candidatos, empresas e vagas. //
    getTables() {
      this.$axios.get(`/dashboard/tables`).then(success => {
        this.registries = success.data
      })
    }
  }
}
</script>

<style scoped>
  .bottom-elem {
    border-bottom: 6px solid #662929 !important;
  }

  tr {
    cursor: pointer;
  }
</style>


<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-card class="mt-6">
          <v-card-title>REDEFINIÇÃO DE SENHA</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-form v-model="valid" ref="formPass" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="newPass" type="password" label="SENHA NOVA" :rules="rules.password" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="reNewPass" type="password" label="CONFIRME A NOVA SENHA" :rules="rules.rePassword" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-divider />
            <v-btn color="#662929" class="white--text mt-6" @click="sendPassword()">SALVAR NOVA SENHA</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Recovery',
  props: [ 'token' ],
  data() {
    return {
      valid: true,
      newPass: '',
      reNewPass: '',
      rules: {
        password: [
          v => !!v || 'Senha obrigatória.',
          v => v.length >= 6 || 'Senha não pode conter menos que 6 caracteres.',
        ],
        rePassword: [
          v => v == this.newPass || 'As senhas devem ser iguais.',
        ],
      },
    }
  },
  methods: {
    sendPassword() {
      if (this.$refs.formPass.validate()) {
        this.$axios.post(`/users/recovery/${this.token}`, {
          password: this.newPass
        }).then(success => {
          this.$router.push({ name: 'Login' })
        })
      }
    }
  }
}
</script>

<style scoped>

</style>


<template>
  <v-container class="container" :style="{'background': 'url(' + require('@/assets/backgroundLogin3.jpg') + ') center'}" fluid>
    <v-row class="row-container" justify="center" align="center">
      <v-col cols="12" sm="12" md="8" lg="4">
        <v-card v-if="!recovery" :loading="loading">
          <v-card-title>ACESSO AO SISTEMA</v-card-title>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-form v-model="validLogin" ref="loginForm" lazy-validation>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="email" label="E-MAIL" prepend-inner-icon="fas fa-user" :rules="rules.email" :disabled="loading" counter solo/>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="password" :type="showPass ? 'text' : 'password'" label="SENHA" prepend-inner-icon="fas fa-key" :rules="rules.password" :disabled="loading" @keyup.enter="login()" :append-icon="showPass ? 'fas fa-eye-slash' : 'fas fa-eye'" @click:append="showPass = !showPass" counter solo/>
                </v-col>
                <v-col cols="6" class="pr-2 pt-0">
                  <v-btn color="primary" @click="login()" :loading="loading" block>ENTRAR</v-btn>
                </v-col>
                <v-col cols="6" class="pl-2 pt-0">
                  <v-btn color="warning" :disabled="loading" block @click="$router.push({ name: 'Registry' })">CADASTRAR-SE</v-btn>
                </v-col>
                <v-col cols="12" class="pl-3 pt-3 pb-0">
                  <a href="#" @click="recovery = true">Esqueceu sua Senha? Clique aqui.</a>
                </v-col>
                <!--<v-col cols="6" class="pl-2 pt-0">
                  <v-btn color="warning" :disabled="loading" block @click="recovery = true">RECUPERAR</v-btn>
                </v-col>-->
                <v-col cols="12">
                  <v-alert
                  v-model="errorAlert" border="left" close-text="Close Alert" color="error" dark dismissible>USUÁRIO OU SENHA INCORRETOS!</v-alert>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-divider></v-divider>
          <p class="text-center py-2 mb-0">Grupo Resolve - 2021</p>
        </v-card>
        <v-card v-else>
          <v-card-title>RECUPERAÇÃO DE ACESSO</v-card-title>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-form v-model="validLogin" ref="loginForm" lazy-validation>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="email" label="E-MAIL" prepend-inner-icon="fas fa-user" :rules="rules.email" :disabled="loading" counter solo/>
                </v-col>
                <v-col cols="6" class="pr-2 pt-0">
                  <v-btn color="primary" :disabled="tokenSend" :loading="loading" @click="userRecovery()" block>ENVIAR</v-btn>
                </v-col>
                <v-col cols="6" class="pl-2 pt-0">
                  <v-btn color="warning" :disabled="loading" block @click="recovery = false">VOLTAR</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-alert
                  v-model="tokenSend" border="left" close-text="Close Alert" color="success" dark dismissible>VERIFIQUE SEU E-MAIL, ENVIAMOS UM LINK DE RECUPERAÇÃO!</v-alert>
                  <v-alert
                  v-model="userNotFound" border="left" close-text="Close Alert" color="error" dark dismissible>USUÁRIO NÃO EXISTE!</v-alert>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-divider></v-divider>
          <p class="text-center py-2 mb-0">Grupo Resolve - 2021</p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      recovery: false,
      tokenSend: false,
      userNotFound: false,
      email: '',
      password: '',
      loading: false,
      validLogin: false,
      errorAlert: false,
      showPass: false,
      rules: {
        email: [
          v => !!v || 'E-mail obrigatório.',
          v => /.+@.+/.test(v) || 'E-mail inválido.',
        ],
        password: [
          v => !!v || 'Senha obrigatória.',
          v => v.length >= 6 || 'Senha não pode conter menos que 6 caracteres.',
        ],
      }
    }
  },
  methods: {
    // Acessar sistema. //
    login() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true
        this.$axios.post(`/users/login`, {
          email: this.email,
          password: this.password
        }).then(success => {
          localStorage.setItem('miolocat', success.data.token)
          localStorage.setItem('miolocat_userType', success.data.type)
          axios.defaults.headers.common['Authorization'] = `Bearer ${success.data.token}`
          this.$store.commit('SET_USER', {
            id: success.data.id,
            name: success.data.name,
            email: success.data.email,
            type: success.data.type,
            created: success.data.created,
          })
          this.$router.push({ name: 'Home' })
        }).catch(err => {
          this.loading = false
          this.errorAlert = true
        })
      }
    },
    // Gerar recuperação de senha. //
    userRecovery() {
      this.loading = true
      this.$axios.post(`/users/recovery`, {
        email: this.email
      }).then(success => {
        this.tokenSend = true
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.userNotFound = true
      })
    }
  }
}
</script>

<style scoped>
  .container {
    width: 100vw;
    height: 100vh;
  }
  .row-container {
    height: 100vh;
  }
</style>


<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>{{ form.name }}</v-card-title>
          <v-card-subtitle><strong>CODIGO:</strong> {{ id }}</v-card-subtitle>
          <hr>
          <div class="pa-4">
            <v-form>
              <v-row class="py-1">
                <v-col cols="12">
                  <h3>DADOS PRINCIPAIS</h3>
                </v-col>
                <v-col cols="12" sm="12" lg="12">
                  <v-text-field v-model="form.name" label="RAZÃO SOCIAL. *" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.fantasy" label="NOME FANTASIA. *" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.cnpj" v-mask="`##.###.###/####-##`" label="CNPJ. *" counter="18" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.ie" label="INSCRIÇÃO ESTADUAL" counter="20" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.im" label="INSCRIÇÃO MUNICIPAL" counter="20" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-select v-model="form.branch" :items="form.branches" item-text="bran_name" item-value="bran_id" label="RAMOS DE ATIVIDADE *" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.website" label="WEBSITE" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="12">
                  <v-text-field v-model="form.email" label="E-MAIL PRINCIPAL *" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-select v-model="form.consultor" :items="form.consultors" item-text="user_name" item-value="user_id" label="CONSULTOR *" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-select v-model="form.commercial" :items="form.commercials" item-text="user_name" item-value="user_id" label="COMERCIAL *" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.recruitment" label="VALOR RECRUTAMENTO E SELEÇÃO (%)" type="number" min="0" max="100" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.labor" label="VALOR MÃO DE OBRA TEMPORÁRIA (%)" type="number" min="0" max="100" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-select v-model="form.gpresolve" :items="form.yes_not" item-text="name" item-value="code" label="CLIENTE GRUPO RESOLVE *" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-select v-model="form.state" :items="form.states" item-text="name" item-value="code" label="EMPRESA LIBERADA *" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-select v-model="form.contract" :items="form.contracts" item-text="name" item-value="code" label="TIPO DE CONTRATO *" filled/>
                </v-col>
                <v-col cols="12">
                  <h3>RESPONSAVEL FINANCEIRO</h3>
                </v-col>
                <v-col cols="12"><hr class="mb-4"></v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.responsible_name" label="NOME *" counter="60" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.responsible_email" label="E-MAIL DO RESPONSAVEL *" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-text-field v-model="form.responsible_tel" v-mask="`(##) #########`" label="TELEFONE 1" counter="40" filled/>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select v-model="form.responsible_tel_wpp" :items="form.yes_not" item-text="name" item-value="code" label="WHATSAPP" filled/>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-text-field v-model="form.responsible_cel" v-mask="`(##) #########`" label="TELEFONE 2" counter="40" filled/>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select v-model="form.responsible_cel_wpp" :items="form.yes_not" item-text="name" item-value="code" label="WHATSAPP" filled/>
                </v-col>
                <v-col cols="12">
                  <h3>RESPONSAVEL RH</h3>
                </v-col>
                <v-col cols="12"><hr class="mb-4"></v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.rh_name" label="NOME" counter="60" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="6">
                  <v-text-field v-model="form.rh_email" label="E-MAIL RH" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-text-field v-model="form.rh_tel" v-mask="`(##) #########`" label="TELEFONE 1" counter="40" filled/>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select v-model="form.rh_tel_wpp" :items="form.yes_not" item-text="name" item-value="code" label="WHATSAPP" filled/>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-text-field v-model="form.rh_cel" v-mask="`(##) #########`" label="TELEFONE 2" counter="40" filled/>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select v-model="form.rh_cel_wpp" :items="form.yes_not" item-text="name" item-value="code" label="WHATSAPP" filled/>
                </v-col>
                <v-col cols="12">
                  <h3>ENDEREÇO DE CORRESPONDENCIA</h3>
                </v-col>
                <v-col cols="12"><hr class="mb-4"></v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.correspondence_cep" v-mask="`########`" @blur="getCEP(form.correspondence_cep, 'correspondence_fields', ['correspondence', 'correspondence_district', 'correspondence_city', 'correspondence_state'])" label="CEP" counter="8" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="8">
                  <v-text-field v-model="form.correspondence" :disabled="form.correspondence_fields" label="LOGRADOURO" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.correspondence_number" label="NUMERO" counter="20" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="8">
                  <v-text-field v-model="form.correspondence_comp" label="COMPLEMENTO" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.correspondence_district" :disabled="form.correspondence_fields" label="BAIRRO" counter="60" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.correspondence_city" :disabled="form.correspondence_fields" label="CIDADE" counter="60" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.correspondence_state" :disabled="form.correspondence_fields" label="ESTADO" counter="60" filled/>
                </v-col>
                <v-col cols="12">
                  <h3>ENDEREÇO DE COBRANÇA</h3>
                </v-col>
                <v-col cols="12"><hr class="mb-4"></v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.billing_cep" v-mask="`########`" @blur="getCEP(form.billing_cep, 'billing_fields', ['billing', 'billing_district', 'billing_city', 'billing_state'])" label="CEP" counter="8" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="8">
                  <v-text-field v-model="form.billing" :disabled="form.billing_fields" label="LOGRADOURO" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.billing_number" label="NUMERO" counter="20" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="8">
                  <v-text-field v-model="form.billing_comp" label="COMPLEMENTO" counter="255" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.billing_district" :disabled="form.billing_fields" label="BAIRRO" counter="60" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.billing_city" :disabled="form.billing_fields" label="CIDADE" counter="60" filled/>
                </v-col>
                <v-col cols="12" sm="12" lg="4">
                  <v-text-field v-model="form.billing_state" :disabled="form.billing_fields" label="ESTADO" counter="60" filled/>
                </v-col>
                <v-col cols="12"><hr class="mb-4"></v-col>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.description" label="DESCRIÇÃO" filled></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.hiring" label="DETALHES PARA CONTRATAÇÃO" filled></v-textarea>
                </v-col>
                <v-col cols="12">
                  <hr class="mb-6">
                  <v-btn color="#662929" class="white--text" @click="save()">SALVAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cep from 'cep-promise'

export default {
  name: 'EditCompany',
  props: [ 'id' ],
  data() {
    return {
      form: {
        valid: true,
        name: '',
        fantasy: '',
        cnpj: '',
        ie: '',
        im: '',
        website: '',
        email: '',
        consultor: {},
        branch: {},
        recruitment: 0,
        labor: 0,
        responsible_name: '',
        responsible_email: '',
        responsible_tel: '',
        responsible_tel_wpp: 2,
        responsible_cel: '',
        responsible_cel_wpp: 2,
        rh_name: '',
        rh_email: '',
        rh_tel: '',
        rh_tel_wpp: 2,
        rh_cel: '',
        rh_cel_wpp: 2,
        correspondence: '',
        correspondence_number: '',
        correspondence_comp: '',
        correspondence_district: '',
        correspondence_cep: '',
        correspondence_state: '',
        correspondence_city: '',
        billing: '',
        billing_number: '',
        billing_comp: '',
        billing_district: '',
        billing_cep: '',
        billing_state: '',
        billing_city: '',
        description: '',
        gpresolve: 4,
        state: 4,
        contract: 2,
        hiring: '',
        yes_not: [
          { name: 'SIM', code: 4 },
          { name: 'NÃO', code: 2 },
        ],
        states: [
          { name: 'DESBLOQUEADA', code: 4 },
          { name: 'BLOQUEADA', code: 2 },
        ],
        contracts: [
          { name: 'CONTRATO 01', code: 2 },
          { name: 'CONTRATO 02', code: 4 },
          { name: 'CONTRATO 03', code: 6 },
        ],
        consultors: [],
        branches: [],
        rules: {

        },
        correspondence_fields: false,
        billing_fields: true,
        commercial: '',
        commercials: []
      },
      dataNewLoading: true
    }
  },
  created() {
    this.getDataNew()
    this.getCompany()
  },
  methods: {
    // Obtem endereço através do CEP informado. //
    getCEP(code, disabled, fields) {
      cep(code).then(success => {
        this.form[fields[0]] = success.street
        this.form[fields[1]] = success.neighborhood
        this.form[fields[2]] = success.city
        this.form[fields[3]] = success.state
        this.form[disabled] = false
      }).catch(err => {
        this.form[disabled] = false
      })
    },
    // Obtem dados para formulario de cadastro. //
    getDataNew() {
      this.$axios.get(`/companies/data/new`).then(success => {
        this.form.branches = success.data.branches
        this.form.consultors = success.data.consultors
        this.form.commercials = success.data.commercials
        this.dataNewLoading = false
      })
    },
    // Obter dados da empresa. //
    getCompany() {
      this.$axios.get(`/companies/${this.id}`).then(success => {
        this.form.name = success.data.comp_name
        this.form.fantasy = success.data.comp_fantasy
        this.form.cnpj = success.data.comp_cnpj
        this.form.ie = success.data.comp_ie
        this.form.im = success.data.comp_im
        this.form.website = success.data.comp_site
        this.form.email = success.data.comp_email
        this.form.consultor = success.data.comp_consultant
        this.form.branch = success.data.comp_branch
        this.form.recruitment = success.data.comp_recruitment
        this.form.labor = success.data.comp_labor
        this.form.responsible_name = success.data.comp_responsible_name
        this.form.responsible_email = success.data.comp_responsible_email
        this.form.responsible_tel = success.data.comp_responsible_tel
        this.form.responsible_tel_wpp = success.data.comp_responsible_tel_wpp
        this.form.responsible_cel = success.data.comp_responsible_cel
        this.form.responsible_cel_wpp = success.data.comp_responsible_cel_wpp
        this.form.rh_name = success.data.comp_rh_name
        this.form.rh_email = success.data.comp_rh_email
        this.form.rh_tel = success.data.comp_rh_tel
        this.form.rh_tel_wpp = success.data.comp_rh_tel_wpp
        this.form.rh_cel = success.data.comp_rh_cel
        this.form.rh_cel_wpp = success.data.comp_rh_cel_wpp
        this.form.correspondence = success.data.comp_correspondence
        this.form.correspondence_number = success.data.comp_correspondence_number
        this.form.correspondence_comp = success.data.comp_correspondence_comp
        this.form.correspondence_district = success.data.comp_correspondence_district
        this.form.correspondence_cep = success.data.comp_correspondence_cep
        this.form.correspondence_state = success.data.comp_correspondence_state
        this.form.correspondence_city = success.data.comp_correspondence_city
        this.form.billing = success.data.comp_billing
        this.form.billing_number = success.data.comp_billing_number
        this.form.billing_comp = success.data.comp_billing_comp
        this.form.billing_district = success.data.comp_billing_district
        this.form.billing_cep = success.data.comp_billing_cep
        this.form.billing_state = success.data.comp_billing_state
        this.form.billing_city = success.data.comp_billing_city
        this.form.gpresolve = success.data.comp_gpresolve
        this.form.state = success.data.comp_state
        this.form.contract = success.data.comp_contract
        this.form.description = success.data.comp_description
        this.form.hiring = success.data.comp_hiring
        this.form.commercial = success.data.comp_commercial
      })
    },
    // Salva edição. //
    save() {
      this.$axios.put(`/companies/${this.id}`, {
        "fantasy": this.form.fantasy,
        "name": this.form.name,
        "cnpj": this.form.cnpj,
        "ie": this.form.ie,
        "im": this.form.im,
        "branch": this.form.branch,
        "site": this.form.website,
        "email": this.form.email,
        "consultant": this.form.consultor,
        "recruitment": this.form.recruitment,
        "labor": this.form.labor,
        "gpresolve": this.form.gpresolve,
        "state": this.form.state,
        "responsible_name": this.form.responsible_name,
        "responsible_email": this.form.responsible_email,
        "responsible_tel": this.form.responsible_tel,
        "responsible_tel_wpp": this.form.responsible_tel_wpp,
        "responsible_cel": this.form.responsible_cel,
        "responsible_cel_wpp": this.form.responsible_cel_wpp,
        "rh_name": this.form.rh_name,
        "rh_email": this.form.rh_email,
        "rh_tel": this.form.rh_tel,
        "rh_tel_wpp": this.form.rh_tel_wpp,
        "rh_cel": this.form.rh_cel,
        "rh_cel_wpp": this.form.rh_cel_wpp,
        "correspondence": this.form.correspondence,
        "correspondence_number": this.form.correspondence_number,
        "correspondence_comp": this.form.correspondence_comp,
        "correspondence_district": this.form.correspondence_district,
        "correspondence_cep": this.form.correspondence_cep,
        "correspondence_state": this.form.correspondence_state,
        "correspondence_city": this.form.correspondence_city,
        "billing": this.form.billing,
        "billing_number": this.form.billing_number,
        "billing_comp": this.form.billing_comp,
        "billing_district": this.form.billing_district,
        "billing_cep": this.form.billing_cep,
        "billing_state": this.form.billing_state,
        "billing_city": this.form.billing_city,
        "description": this.form.description,
        "hiring": this.form.hiring,
        "contract": this.form.contract,
        "commercial": this.form.commercial
      }).then(success => {
        this.$router.push({ path: `/dashboard/companies/${this.id}` })
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>

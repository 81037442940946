
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>VAGAS CANCELADAS</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>NOME / RAZÃO SOCIAL</th>
                      <th>STATUS / PROFISSÃO</th>
                      <th>INICIO / FIM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(job, index) in closedJobs" :key="index" @click="$router.push({ path: `/consultant/jobs/${job.jobs_code}` })">
                      <td>
                        <div>{{ job.comp_fantasy }}</div>
                        <div>{{ job.comp_name }}</div>
                      </td>
                      <td>
                        <div>{{ job.jobs_status | jobStatus }}</div>
                        <div>{{ job.occu_name }}</div>
                      </td>
                      <td>
                        <div>{{ job.jobs_start | birth }}</div>
                        <div>{{ job.jobs_end | birth }}</div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-title>VAGAS FECHADAS</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>NOME / RAZÃO SOCIAL</th>
                      <th>STATUS / PROFISSÃO</th>
                      <th>INICIO / FIM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(job, index) in canceledJobs" :key="index" @click="$router.push({ path: `/consultant/jobs/${job.jobs_code}` })">
                      <td>
                        <div>{{ job.comp_fantasy }}</div>
                        <div>{{ job.comp_name }}</div>
                      </td>
                      <td>
                        <div>{{ job.jobs_status | jobStatus }}</div>
                        <div>{{ job.occu_name }}</div>
                      </td>
                      <td>
                        <div>{{ job.jobs_start | birth }}</div>
                        <div>{{ job.jobs_end | birth }}</div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Closed',
  data() {
    return {
      jobs: [],
      closedJobs: [],
      canceledJobs: [],
    }
  },
  created() {
    this.getJobs()
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  },
  methods: {
    // Obtem todas as vagas. //
    getJobs() {
      this.$axios.get(`/jobs/consultant/closed`).then(success => {
        this.jobs = success.data
        this.closedJobs = this.jobs.filter(job => {
          return job.jobs_status == 2
        })
        this.canceledJobs = this.jobs.filter(job => {
          return job.jobs_status == 6 || job.jobs_status == 8
        })
      })
    }
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>

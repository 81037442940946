
<template>
  <div style="width: 100%; margin-top: -40px;">
    <div class="print-header">
      <div class="my-2">
        <p class="ma-0 pa-0">{{ message }}</p>
        <p class="ma-0 pa-0"><strong>DATA / HORA:</strong> {{ current }}</p>
        <!--<p class="ma-0 pa-0"><strong>USUÁRIO:</strong> {{ user.name }} - {{ user.email }}</p>-->
        <p class="ma-0 pa-0"><strong></strong></p>
      </div>
      <img class="print-logo" src="@/assets/logoDash.jpg" width="200" alt="logo">
    </div>
    <hr />
    <slot />
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Print',
  props: [ 'message' ],
  data() {
    return {
      current: moment().format(`DD/MM/YYYY HH:mm:ss`)
    }
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style scoped>
  .print-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
</style>

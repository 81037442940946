
<template>
  <v-container>
    <v-row id="no-print">
      <v-col cols="12" sm="12" md="7">
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>DETALHES</v-card-title>
          <v-card-subtitle>{{ id }}</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <p><strong>IDENTIFICADOR: </strong>{{ job.jobs_id }}</p>
            <p><strong>NOME FANTASIA: </strong> {{ job.comp_fantasy }}</p>
            <p><strong>RAZÃO SOCIAL: </strong> {{ job.comp_name }}</p>
            <p><strong>CNPJ</strong> {{ job.comp_cnpj }} - <strong>I.E</strong> {{ job.comp_ie || ': ISENTO' }} - <strong>I.M</strong> {{ job.comp_im || ': ISENTO' }}</p>
            <p><strong>STATUS: </strong> {{ job.jobs_status | jobStatus }}</p>
            <p><strong>TIPO: </strong> {{ job.jobs_type | jobType }}</p>
            <p><strong>IDADES: </strong> {{ job.jobs_age_min }} à {{ job.jobs_age_max }}</p>
            <p><strong>ALTURA: </strong> {{ job.jobs_height_min }} à {{ job.jobs_height_max }}</p>
            <p><strong>UNIDADE: </strong> {{ job.unit_name }}</p>
            <p><strong>CIDADES: </strong> {{ job.jobs_city | cities }}</p>
            <p><strong>GENERO: </strong> {{ job.jobs_sex | sex }} - <strong>ESTADO CIVIL: </strong> {{ job.jobs_marital_status | civil }}</p>
            <p><strong>GRAU DE ENSINO: </strong> {{ job.scho_name }}</p>
            <p><strong>RAMO: </strong> {{ job.bran_name }} - <strong>OCUPAÇÃO: </strong> {{ job.occu_name }}</p>
            <p><strong>TITULO: </strong> {{ job.jobs_title }}</p>
            <p><strong>INICIO: </strong> {{ job.jobs_start | birth }} - <strong>FIM: </strong> {{ job.jobs_end | birth }}</p>
            <p><strong>CONSULTOR: </strong> {{ job.user_name }} - {{ job.user_email }}</p>
            <p><strong>EMPREGADO: </strong> {{ job.jobs_employee | yes_not }}</p>
            <p><strong>HABILITADO: </strong> {{ job.jobs_cnh | yes_not }}</p>
            <p><strong>TEM VEICULO: </strong> {{ job.jobs_vehicle | yes_not }}</p>
            <p><strong>SALARIO: </strong> {{ job.jobs_salary }}</p>
            <p><strong>HORÁRIOS: </strong> {{ job.jobs_schedules }}</p>
            <p><strong>PCD: </strong> {{ job.jobs_deficient | yes_not }}</p>
            <hr class="mb-4">
            <p><strong>INFORMATICA</strong></p>
            <ul class="mb-5">
              <li v-for="(info, index) in job.computings" :key="index">
                {{ info.info_name }} <v-icon color="error" @click="removeInfo(info.nnji_code)" small>fas fa-trash-alt</v-icon>
              </li>
            </ul>
            <hr class="mb-4">
            <p><strong>IDIOMAS</strong></p>
            <ul class="mb-5">
              <li v-for="(lang, index) in job.languages" :key="index">
                {{ lang.lang_name }} ({{ lang.nnjl_level | language }}) <v-icon color="error" @click="removeLang(lang.nnjl_code)" small>fas fa-trash-alt</v-icon>
              </li>
            </ul>
            <hr class="mb-4">
             <p><strong>BENEFICIOS: </strong></p>
            <ul class="mb-4">
              <li v-for="(benefit, index) in job.benefits" :key="index">
                {{ benefit.bene_name }} (<strong>R$ </strong>{{ benefit.nnbj_value }}) <v-icon color="error" @click="removeBenefit(benefit.nnbj_code)" small>fas fa-trash-alt</v-icon>
              </li>
            </ul>
            <hr class="mb-4">
            <p><strong>DESCRIÇÃO DA VAGA: </strong> {{ job.jobs_description }}</p>
            <hr class="mb-4">
            <v-btn color="#e98b0d" class="white--text mb-4" @click="$router.push({ path: `/dashboard/jobs/edit/${job.jobs_code}/${job.jobs_id}` })" block>EDITAR</v-btn>
            <v-btn color="#828a03" class="white--text mt-3" @click="print()" block>IMPRIMIR</v-btn>
            <!-- <v-btn color="#662929" class="white--text" block>ALTERAR ESTADO</v-btn> -->
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-card class="bottom-detail">
          <v-card-title>HISTORICO</v-card-title>
          <v-card-subtitle>ULTIMOS REGISTROS</v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ history.user_name }}</v-list-item-title>
                <v-list-item-content>{{ history.hs_job_message }}</v-list-item-content>
                <v-list-item-subtitle>{{ history.hs_job_created | datetime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mb-4 mt-4"/>
            <v-textarea v-model="message" label="MENSAGEM" auto-grow filled/>
            <v-btn class="white--text" color="#662929" :loading="newHistoricalLoading" :disabled="!message.length >= 1" large block @click="newHistorical()">ADICIONAR</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div id="section-to-print">
      <print message="RELATÓRIO DE VAGA">
        <p class="mt-4"><strong>IDENTIFICADOR: </strong>{{ job.jobs_id }}</p>
        <p><strong>NOME FANTASIA: </strong> {{ job.comp_fantasy }}</p>
        <p><strong>RAZÃO SOCIAL: </strong> {{ job.comp_name }}</p>
        <p><strong>CNPJ</strong> {{ job.comp_cnpj }} - <strong>I.E</strong> {{ job.comp_ie || ': ISENTO' }} - <strong>I.M</strong> {{ job.comp_im || ': ISENTO' }}</p>
        <p><strong>ESTATUS: </strong> {{ job.jobs_status | jobState }}</p>
        <p><strong>TIPO: </strong> {{ job.jobs_type | jobType }}</p>
        <p><strong>IDADES: </strong> Min {{ job.jobs_age_min }} anos / Max {{ job.jobs_age_max }} anos.</p>
        <p><strong>UNIDADE: </strong> {{ job.unit_name }}</p>
         <p><strong>CIDADES: </strong> {{ job.jobs_city | cities }}</p>
        <p><strong>GENERO: </strong> {{ job.jobs_sex | sex }} - <strong>ESTADO CIVIL: </strong> {{ job.jobs_marital_status | civil }}</p>
        <p><strong>GRAU DE ENSINO: </strong> {{ job.scho_name }}</p>
        <p><strong>RAMO: </strong> {{ job.bran_name }} - <strong>OCUPAÇÃO: </strong> {{ job.occu_name }}</p>
        <p><strong>INICIO: </strong> {{ job.jobs_start | birth }} - <strong>FIM: </strong> {{ job.jobs_end | birth }}</p>
        <p><strong>CONSULTOR: </strong> {{ job.user_name }} - {{ job.user_email }}</p>
        <p><strong>EMPREGADO: </strong> {{ job.jobs_employee | yes_not }}</p>
        <p><strong>HABILITADO: </strong> {{ job.jobs_cnh | yes_not }}</p>
        <p><strong>TEM VEICULO: </strong> {{ job.jobs_vehicle | yes_not }}</p>
        <p><strong>SALARIO: </strong> {{ job.jobs_salary }}</p>
        <p><strong>HORÁRIOS: </strong> {{ job.jobs_schedules }}</p>
        <p><strong>PCD: </strong> {{ job.jobs_deficient | yes_not }}</p>
        <hr class="mb-4">
        <p><strong>INFORMATICA</strong></p>
        <ul class="mb-5">
          <li v-for="(info, index) in job.computings" :key="index">
            {{ info.info_name }} <v-icon color="error" @click="removeInfo(info.nnji_code)" small>fas fa-trash-alt</v-icon>
          </li>
        </ul>
        <hr class="mb-4">
        <p><strong>IDIOMAS</strong></p>
        <ul class="mb-5">
          <li v-for="(lang, index) in job.languages" :key="index">
            {{ lang.lang_name }} ({{ lang.nnjl_level | language }})
          </li>
        </ul>
        <hr class="mb-4">
          <p><strong>BENEFICIOS: </strong></p>
        <ul class="mb-4">
          <li v-for="(benefit, index) in job.benefits" :key="index">
            {{ benefit.bene_name }} (<strong>R$ </strong>{{ benefit.nnbj_value }})
          </li>
        </ul>
        <hr class="mb-4">
        <p><strong>DESCRIÇÃO DA VAGA: </strong> {{ job.jobs_description }}</p>
        <v-divider class="my-6" />
        <h1>MOVIMENTAÇÃO</h1>
        <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
          <v-list-item-content>
            <v-list-item-title>{{ history.user_name }}</v-list-item-title>
            <v-list-item-content>{{ history.hs_job_message }}</v-list-item-content>
            <v-list-item-subtitle>{{ history.hs_job_created | datetime }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </print>
    </div>
  </v-container>
</template>

<script>
import print from '../../../components/Print.vue'

export default {
  name: 'Job',
  props: [ 'id' ],
  components: { print },
  data() {
    return {
      job: {},
      message: '',
      historicals: [],
      newHistoricalLoading: false,
    }
  },
  created() {
    this.getJob()
  },
  methods: {
    // Obtem vaga. //
    getJob() {
      this.$axios.get(`/jobs/${this.id}`).then(success => {
        this.job = success.data
        this.listHistoricals()
      })
    },
    // Remove info. //
    removeInfo(code) {
      this.$axios.delete(`/jobs/infos/${code}`).then(success => {
        this.job = {}
        this.getJob()
      })
    },
    // Remove idioma. //
    removeLang(code) {
      this.$axios.delete(`/jobs/languages/${code}`).then(success => {
        this.job = {}
        this.getJob()
      })
    },
    // Remove beneficio. //
    removeBenefit(code) {
      this.$axios.delete(`/jobs/benefits/${code}`).then(success => {
        this.job = {}
        this.getJob()
      })
    },
    // Adiciona historico a Vaga. //
    newHistorical() {
      this.newHistoricalLoading = true
      this.$axios.post(`/historicals/jobs/${this.job.jobs_id}`, {
        message: this.message,
        messages: this.historicals,
        job: {
          company: this.job.comp_name,
          job: this.job.occu_name,
          code: this.job.jobs_code,
          consultant: this.job.user_name,
          consultant_email: this.job.user_email,
          commercial: this.job.comp_commercial_name,
          commercial_email: this.job.comp_commercial_email,
        }
      }).then(success => {
        this.message = ''
        this.listHistoricals()
        this.newHistoricalLoading = false
      })
    },
    // Obtem historicos da Vaga. //
    listHistoricals() {
      this.$axios.get(`/historicals/jobs/${this.job.jobs_id}`).then(success => {
        this.historicals = success.data.historicals
      })
    },
    // Imprime curriculo do candidato. //
    print() {
      this.$root.$emit('evt::close')
      setTimeout(() => {
        window.print()
      }, 1000)
    }
  }
}
</script>

<style scoped>

</style>

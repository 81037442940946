
<template>
  <v-container fluid>
    <v-row>
      <v-col cols='12'>
        <v-card class="bottom-detail" elevation="16" :loading="loading">
          <v-card-title>SUAS VAGAS</v-card-title>
          <v-card-subtitle>ACOMPANHE AS VAGAS E GERENCIA AS CANDIDATURAS</v-card-subtitle>
          <v-btn class="search-button" color="#662929" fab icon elevation="2" @click="$router.push({ name: 'ConsultantSearchJob' })"><v-icon>fas fa-search</v-icon></v-btn>
          <v-btn class="lock-button" color="#662929" fab icon elevation="2" @click="$router.push({ name: 'ConsultantClosedJobs' })"><v-icon>fas fa-lock</v-icon></v-btn>
          <v-btn class="all-button" color="#662929" fab icon elevation="2" @click="$router.push({ name: 'ConsultantAllJobs' })"><v-icon>fas fa-th</v-icon></v-btn>
          <v-divider />
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME / RAZÃO SOCIAL</th>
                  <th>TITULO / PROFISSÃO</th>
                  <th>STATUS DA VAGA</th>
                  <th>IDENTIFICADOR / DATA DE CADASTRO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(job, index) in jobs" :key="index" @click="$router.push({ path: `/consultant/jobs/${job.jobs_code}` })">
                  <td>
                    <div>{{ job.comp_fantasy }}</div>
                    <div>{{ job.comp_name }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_title }}</div>
                    <div>{{ job.occu_name }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_status | jobStatus }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_id }}</div>
                    <div>{{ job.jobs_created | birth }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ConsultantJobs',
  data() {
    return {
      jobs: [],
      loading: true,
    }
  },
  created() {
    this.getJobs()
  },
  methods: {
    getJobs() {
      this.$axios.get(`/jobs/consultant/${this.user.id}`).then(success => {
        this.jobs = success.data.jobs
        this.loading = false
      })
    }
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
  .search-button {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .lock-button {
    position: absolute;
    right: 90px;
    top: 16px;
  }
  .all-button {
    position: absolute;
    right: 164px;
    top: 16px;
  }
</style>

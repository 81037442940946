
<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="bottom-detail" :loading="loading" :disabled="loading" elevation="16">
          <v-card-title>CADASTRO DE VAGA - {{ company.comp_fantasy }}</v-card-title>
          <v-card-subtitle>{{ company.comp_name }} - {{ company.comp_cnpj }}</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-form>
              <v-row>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field v-model="form.ident" label="ID VAGA *" type="number" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field v-model="form.jobs_age_min" label="IDADE MINIMA *" type="number" min="0" max="99" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field v-model="form.jobs_age_max" label="IDADE MÁXIMA *" type="number" min="1" max="100" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field v-model="form.jobs_height_min" label="ALTURA MINIMA *" v-mask="'#.##'" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field v-model="form.jobs_height_max" label="ALTURA MAXIMA *" v-mask="'#.##'" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select v-model="form.jobs_sex" :items="sex" item-text="name" item-value="code" label="GENERO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete v-model="form.jobs_state" label="ESTADO" :items="states" disabled item-text="fullName" item-value="sigla" @change="getCitiesByUF()" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete v-model="form.jobs_city" :items="cities" item-text="nome" item-value="nome" label="CIDADE" multiple chips single-line filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_schooling" :items="schoolings" item-text="scho_name" item-value="scho_id" label="GRAU DE ENSINO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-row>
                    <v-col cols="12" sm="2">
                      <v-btn color="success" class="mt-2" :loading="loadingOccupations" large block @click="popups.occupations.show = true">+</v-btn>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-autocomplete v-model="form.jobs_occupation" :items="occupations" :loading="loadingOccupations" :disabled="loadingOccupations" item-text="occu_name" item-value="occu_id" label="PROFISSÃO" filled/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-row>
                    <v-col cols="12" sm="2">
                      <v-btn color="success" :loading="loadingBranchs" :disabled="loadingBranchs" class="mt-2" large block @click="popups.branch.show = true">+</v-btn>
                    </v-col>
                    <v-col cols="12" sm="10">
                      <v-autocomplete v-model="form.jobs_branch" :items="branches" item-text="bran_name" :loading="loadingBranchs" item-value="bran_id" label="RAMO DE ATIVIDADE" filled/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_type" :items="job_types" item-text="name" item-value="code" label="TIPO DE VAGA" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="form.jobs_title" label="TITULO DA VAGA" filled/>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="form.jobs_qttd" type="number" label="QUANTIDADE DE VAGAS" filled/>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="form.jobs_office_exercised" label="CARGO JÁ EXERCIDO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_marital_status" :items="marital_status" item-text="name" item-value="code" label="ESTADO CIVIL" filled/>
                </v-col>
                <v-col cols="12"><v-divider/></v-col>
                <v-col cols="12">
                  <h2 class="mb-6">INFORMATICA</h2>
                  <v-row class="ml-3">
                    <v-col cols="12" sm="12" md="4" v-for="(info, index) in infos" :key="index">
                      <v-switch v-model="computings" :label="info.info_name" :value="info.info_id" inset/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider/></v-col>
                <v-col cols="12">
                  <h2 class="mb-6">IDIOMAS</h2>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" v-for="(language, index) in languages" :key="index">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-select v-model="language.level" :label="language.name" :items="language_levels" item-text="name" item-value="code" filled/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider/></v-col>
                <v-col cols="12">
                  <v-btn color="success" class="float-right" large @click="popups.benefits.show = true">+</v-btn>
                  <h2 class="mb-6">BENEFICIOS</h2>
                  <v-row class="ml-3">
                    <v-col cols="12" sm="12" md="4" v-for="(benefit, index) in benefits" :key="index">
                      <v-switch v-model="benefitSels" :label="benefit.bene_name" :value="benefit" inset/>
                    </v-col>
                  </v-row>
                  <v-row class="ml-3">
                    <v-col cols="12" sm="12" md="4" v-for="(benefit, index) in benefitSels" :key="index">
                      <!-- <v-switch v-model="benefitSels" :label="benefit.bene_name" :value="benefit" inset/> -->
                      <v-text-field v-model="benefit.bene_value" prefix="R$" :label="benefit.bene_name" filled/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider/></v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select v-model="form.jobs_employee" :items="yes_not" item-text="name" item-value="code" label="EMPREGADO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select v-model="form.jobs_cnh" :items="yes_not" item-text="name" item-value="code" label="HABILITADO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field v-model="form.jobs_cnh_categories" label="CATEGORIAS" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select v-model="form.jobs_vehicle" :items="yes_not" item-text="name" item-value="code" label="TEM VEICULO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_unit" :items="units" item-text="unit_name" item-value="unit_id" label="UNIDADE" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.jobs_salary" label="SALARIO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.jobs_schedules" label="HORARIOS DE TRABALHO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_time" @change="setDays()" :items="times" item-text="time_days" item-value="time_days" label="DIAS DE EXIBIÇÃO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_consultant" :items="users" item-text="user_name" item-value="user_id" label="CONSULTOR" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_deficient" :items="yes_not" item-text="name" item-value="code" label="DEFICIENTE" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea v-model="form.jobs_description" label="DESCRIÇÃO DA VAGA" filled></v-textarea>
                </v-col>
                <v-col cols="12">
                  <hr class="mb-6">
                  <v-btn color="#662929" class="white--text" :loading="loading" @click="newJob()">CADASTRAR</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-alert v-model="error.show" type="error" dismissible>{{ error.message }}</v-alert>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="popups.branch.show" width="600" persistent>
      <v-card>
        <v-card-title>NOVO RAMO DE ATIVIDADE</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-form v-model="popups.branch.valid" ref="branForm" lazy-validation>
            <v-row class="py-2">
              <v-col cols="12" class="pb-0">
                <v-text-field v-model="popups.branch.name" :rules="popups.branch.rules.name" label="NOME *" counter="60" filled/>
              <!-- <v-alert
              v-model="error2.show" border="left" close-text="Close Alert" color="error" dark dismissible>{{ error2.message }}</v-alert> -->
              </v-col>
              <v-col cols="12">
                <hr class="mb-4">
                <v-btn color="#662929" class="white--text" @click="newBranch()" block>CADASTRAR</v-btn>
                <v-btn color="warning" class="mt-2" @click="popups.branch.show = false" block>CANCELAR</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="popups.occupations.show" width="600" persistent>
      <v-card>
        <v-card-title>NOVA OCUPAÇÃO</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-form v-model="popups.occupations.valid" ref="occuForm" lazy-validation>
              <v-row class="py-2">
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="popups.occupations.name" :rules="popups.occupations.rules.name" label="NOME *" counter="60" filled/>
                <!-- <v-alert
                v-model="error2.show" border="left" close-text="Close Alert" color="error" dark dismissible>{{ error2.message }}</v-alert> -->
                </v-col>
                <v-col cols="12">
                  <hr class="mb-4">
                  <v-btn color="#662929" class="white--text" @click="newOccupation()" block>CADASTRAR</v-btn>
                  <v-btn color="warning" class="mt-2" @click="popups.occupations.show = false" block>CANCELAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="popups.benefits.show" width="600" persistent>
      <v-card :loading="popups.benefits.loading" :disabled="popups.benefits.loading">
        <v-card-title>NOVO BENEFICIO</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-form v-model="popups.benefits.valid" ref="occuForm" lazy-validation>
              <v-row class="py-2">
                <v-col cols="12" class="pb-0">
                  <v-text-field v-model="popups.benefits.name" :rules="popups.benefits.rules.name" label="NOME *" counter="60" filled/>
                </v-col>
                <v-col cols="12">
                  <hr class="mb-4">
                  <v-btn color="#662929" class="white--text" @click="newBenefit()" :loading="popups.benefits.loading" block>CADASTRAR</v-btn>
                  <v-btn color="warning" class="mt-2" @click="popups.benefits.show = false" block>CANCELAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
        </div>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ConsultantNewJob',
  props: [ `code`, `id` ],
  data() {
    return {
      company: {},
      schoolings: [],
      occupations: [],
      branches: [],
      units: [],
      users: [],
      infos: [],
      computings: [],
      languages: [],
      benefits: [],
      times: [],
      benefitSels: [],
      loads: {
        company: true,
        schoolings: true,
        occupations: true,
        branches: true,
        units: true,
        consultants: true,
        states: true,
        cities: false
      },
      form: {
        ident: 0,
        jobs_age_min: 0,
        jobs_age_max: 100,
        jobs_sex: 6,
        jobs_state: 'SP',
        jobs_city: '',
        jobs_marital_status: 14,
        jobs_status: 4,
        jobs_schooling: '',
        jobs_occupation: '',
        jobs_branch: '',
        jobs_type: 0,
        jobs_office_exercised: '',
        jobs_employee: 6,
        jobs_cnh: 6,
        jobs_cnh_categories: '',
        jobs_vehicle: 6,
        jobs_unit: '',
        jobs_start: moment().format('YYYY-MM-DD'),
        jobs_end: moment().add(1, 'month').format('YYYY-MM-DD'),
        jobs_consultant: '',
        jobs_type: '',
        jobs_deficient: 6,
        jobs_deficient: 6,
        jobs_salary: '',
        jobs_description: '',
        jobs_schedules: '',
        jobs_time: '',
        jobs_qttd: 1,
        jobs_height_min: 1,
        jobs_height_max: 2,
        jobs_title: '',
      },
      yes_not: [
        { name: 'SIM', code: 4 },
        { name: 'NÃO', code: 2 },
        { name: 'TODOS', code: 6 },
      ],
      sex: [
        { name: 'TODOS', code: 6 },
        { name: 'FEMININO', code: 4 },
        { name: 'MASCULINO', code: 2 },
      ],
      marital_status: [
        { name: 'Solteiro(a)', code: 2 },
        { name: 'Casado(a)', code: 4 },
        { name: 'Desquitado(a)', code: 6 },
        { name: 'Divorciado(a)', code: 8 },
        { name: 'Viuvo(a)', code: 10 },
        { name: 'Outro', code: 12 },
        { name: 'Todos', code: 14 },
      ],
      job_types: [
        { name: 'Efetiva', code: 2 },
        { name: 'Estágio', code: 4 },
        { name: 'PJ/MEI', code: 6 },
        { name: 'Temporaria', code: 8 },
      ],
      language_levels: [
        { name: 'Não exigido', code: 0 },
        { name: 'Básico', code: 2 },
        { name: 'Intermediario', code: 4 },
        { name: 'Fluente', code: 6 },
      ],
      states: [],
      cities: [],
      popups: {
        branch: {
          show: false,
          valid: true,
          name: '',
          rules: {
            name: [
              v => !!v || 'Nome obrigatório.',
              v => v.length >= 4 || 'Nome não pode conter menos que 4 caracteres.',
              v => v.length <= 60 || 'Nome não pode conter mais que 60 caracteres.',
            ],
          },
        },
        occupations: {
          show: false,
          valid: true,
          name: '',
          rules: {
            name: [
              v => !!v || 'Nome obrigatório.',
              v => v.length >= 4 || 'Nome não pode conter menos que 4 caracteres.',
              v => v.length <= 60 || 'Nome não pode conter mais que 60 caracteres.',
            ],
          },
        },
        benefits: {
          loading: false,
          show: false,
          valid: true,
          name: '',
          rules: {
            name: [
              v => !!v || 'Nome obrigatório.',
              v => v.length >= 4 || 'Nome não pode conter menos que 4 caracteres.',
              v => v.length <= 60 || 'Nome não pode conter mais que 60 caracteres.',
            ],
          },
        },
      },
      loading: true,
      loadingOccupations: false,
      loadingBranchs: false,
      error: {
        message: '',
        show: false,
      },
    }
  },
  created() {
    this.getCompany()
    this.getDataNew()
    this.getStatesFromIBGE()
    this.getCitiesByUF()
  },
  methods: {
    // Obter dados da empresa. //
    getCompany() {
      this.$axios.get(`/companies/${this.code}`).then(success => {
        this.company = success.data
        this.loads.company = false
      })
    },
    // Obtem dados auxiliares para cadastro. //
    getDataNew() {
      this.$axios.get(`/jobs/data/new`).then(success => {
        this.branches = success.data.branches
        this.occupations = success.data.occupations
        this.schoolings = success.data.schoolings
        this.units = success.data.units
        this.users = success.data.users
        this.infos = success.data.infos
        this.benefits = success.data.benefits.map(item => {
          return {
            ...item,
            bene_value: 0
          }
        })
        this.times = success.data.times
        this.languages = success.data.languages.map(item => {
          return {
            name: item.lang_name,
            code: item.lang_id,
            level: 0
          }
        })
        this.form.jobs_time = this.times[0].time_days
        this.setDays()
        this.loading = false
      })
    },
    // Obtem estados do Brasil. //
    getStatesFromIBGE() {
      this.$axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`).then(success => {
        this.states = success.data.map(item => {
          item.fullName = `${item.sigla} - ${item.nome}`
          return item
        })
      })
    },
    // Obtem cidades por estado. //
    getCitiesByUF() {
      this.$axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.form.jobs_state}/distritos?orderBy=nome`).then(success => {
        this.cities = success.data
      })
    },
    // Cadastra nova vaga. //
    newJob() {
      if (this.form.jobs_city.length >= 1) {
        this.loading = true
        this.$axios.post(`/jobs`, {
          ...this.form,
          jobs_company: this.id,
          computings: this.computings,
          languages: this.languages,
          benefits: this.benefitSels,
          jobs_city: JSON.stringify(this.form.jobs_city),
          email: {
            fantasy: this.company.comp_fantasy,
            name: this.company.comp_name,
            cnpj: this.company.comp_cnpj,
            job: this.form.jobs_title,
            created_by: this.company.user_name,
          }
        }).then(success => {
          this.$router.push({ path: `/consultant/jobs/${success.data.code}` })
        }).catch((err) => {
          this.loading = false
          this.error.show = true
          this.error.message = err.response.data.err.sqlMessage
        })
      } else {
        this.error.show = true
        this.error.message = 'Selecione pelo menos uma cidade!'
      }
    },
    // Calculo de dias para prazo de exibição. //
    setDays() {
      const newTime = moment().add(this.form.jobs_time, 'days').format('YYYY-MM-DD 23:59:00')
      this.form.jobs_start = moment().format('YYYY-MM-DD 00:00:00')
      this.form.jobs_end = newTime
    },
    // Salva novo ramos de atividade. //
    newBranch() {
      if (this.$refs.branForm.validate()) {
        this.loadingBranchs = true
        this.$axios.post(`/branches`, {
          name: this.popups.branch.name.toUpperCase()
        }).then(success => {
          this.$axios.get(`/jobs/data/new`).then(success => {
            this.branches = success.data.branches
            this.loadingBranchs = false
          })
          this.popups.branch.show = false
        })
      }
    },
    // Salva ocupação. //
    newOccupation() {
      if (this.$refs.occuForm.validate()) {
        this.loadingOccupations = true
        this.$axios.post(`/occupations`, {
          name: this.popups.occupations.name.toUpperCase()
        }).then(success => {
          this.$axios.get(`/jobs/data/new`).then(success => {
            this.occupations = success.data.occupations
            this.loadingOccupations = false
          })
          this.popups.occupations.show = false
        })
      }
    },
    // Salva ocupação. //
    newBenefit() {
      if (this.$refs.occuForm.validate()) {
        this.popups.benefits.loading = true
        this.$axios.post(`/benefits`, {
          name: this.popups.benefits.name.toUpperCase()
        }).then(success => {
          // this.benefitSels = []
          this.benefits = []
          this.$axios.get(`/jobs/data/new`).then(success => {
            this.benefits = success.data.benefits.map(item => {
              return {
                ...item,
                bene_value: 0
              }
            })
            this.popups.benefits.loading = false
            this.popups.benefits.show = false
          })
        })
      }
    },
  }
}
</script>

<style scoped>

</style>


<template>
  <v-container fluid>
    <h2 class="mb-4">{{ contractType | contract }}</h2>
    <editor class="text-editor" v-model="textCode" apiKey="bve2f01hwat5tjzkiak32jgbfh2x9zry0hc4m1vulo7fowqn" :init="{}"/>
    <v-btn color="#662929" class="white--text mt-6" :disabled="savePendence" @click="saveContract()">SALVAR CONTRATO</v-btn>
    <v-btn color="#662929" class="white--text mt-6 ml-4" @click="printContract()">IMPRIMIR</v-btn>
    <!-- {{ textCode }} -->
  </v-container>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'Contract',
  props: [ 'code' ],
  components: {
    editor: Editor
  },
  data() {
    return {
      textCode: '',
      textCodeCurrent: '',
      savePendence: true,
      contractType: undefined
    }
  },
  created() {
    this.getContract()
  },
  methods: {
    // Obtem contrato do cliente. //
    getContract() {
      this.$axios.get(`companies/contract/${this.code}`).then(success => {
        this.textCode = success.data.contract
        this.textCodeCurrent = success.data.contract
        this.contractType = success.data.contract_type
      })
    },
    // Salva contrato no cadastro do cliente. //
    saveContract() {
      this.$axios.post(`companies/contract/${this.code}`, {
        contract: this.textCode
      }).then(success => {
        this.textCodeCurrent = this.textCode
        this.savePendence = true
      })
    },
    // Imprimir contrato. //
    printContract() {
      let contractWindows = window.open(``, `Janela de Impressao`, `status=1,width=800,height=600`)
      contractWindows.document.write(`${this.textCode}`)
      contractWindows.document.close()
      contractWindows.print()
    },
  },
  watch: {
    textCode(newCode, oldCode) {
      if (newCode == this.textCodeCurrent) { this.savePendence = true }
      else { this.savePendence = false }
    }
  }
}
</script>

<style scoped>
  .text-editor {
    height: 400px;
  }
</style>

<template>
  <v-container class="registry-container">
    <v-app-bar app>
      <img src="@/assets/gpr-logo.png" height="60" class="ml-10 py-2" alt="logo-grupo-resolve">
      <v-spacer></v-spacer>
      <v-btn color="#662929" class="mr-4 whitegr" href="https://vagas.gruporesolve.com.br"><v-icon>fas fa-home</v-icon></v-btn>
      <template v-if="user.id">
        <v-btn v-if="user.type == 0" color="#662929" @click="$router.push({ path: `/candidate` })" class="whitegr">PAINEL DE "{{ user.name }}"</v-btn>
        <v-btn v-else color="#662929" class="whitegr" @click="$router.push({ path: `/dashboard` })">PAINEL DE "{{ user.name }}"</v-btn>
      </template>
      <template v-else>
        <v-btn color="#662929" class="white--text" @click="$router.push({ name: `Login` })">FAZER LOGIN</v-btn>
      </template>
    </v-app-bar>

    <v-row justify="center" align="center" class="stepper-box">
      <v-col cols="12" sm="12" md="10" lg="8">
        <h2 class="text-center mb-6 mt-4">BEM VINDO AO CADASTRO DE CANDIDATO, VOCÊ SERÁ GUIADO(A)!</h2>
        <v-stepper v-model="step" vertical>

          <v-stepper-step :complete="step > 1" step="1">
            SEUS DOCUMENTOS
            <small>Esses dados serão validados, coloque-os corretamente!</small>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-form v-model="step1.valid" ref="step1Form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.cpf" label="CPF SEM PONTUAÇÃO" v-mask="'###.###.###.##'" :rules="step1.rules.cpf" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.rg" label="RG SEM PONTUAÇÃO" v-mask="'##.###.###-NN'" :rules="step1.rules.rg" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="verifyDocument()" :disabled="!step1.valid || !form.cpf || !form.rg" class="mb-1 white--text" color="#662929" :loading="step1.loading">PRÓXIMO</v-btn>
            <v-alert v-model="step1.error" border="left" class="mt-4" close-text="Close Alert" color="warning" dark dismissible>USUÁRIO JÁ EXISTE!</v-alert>
          </v-stepper-content>

          <v-stepper-step :complete="step > 2" step="2">
            SEU ACESSO
            <small>Você precisa ter acesso ao e-mail digitado para receber nossas notificações!</small>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-form v-model="step2.valid" ref="step2Form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.email" :rules="step2.rules.email" label="SEU E-MAIL DE CONTATO" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.password" :rules="step2.rules.password" :type="showPass ? 'text' : 'password'" label="SUA SENHA SEGURA" :append-icon="showPass ? 'fas fa-eye-slash' : 'fas fa-eye'" @click:append="showPass = !showPass" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.rePassword" :rules="step2.rules.rePassword" :type="showPass ? 'text' : 'password'" label="DIGITE SUA SENHA NOVAMENTE" :append-icon="showPass ? 'fas fa-eye-slash' : 'fas fa-eye'" @click:append="showPass = !showPass" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="step = 1" color="warning" class="mt-0 mr-4">VOLTAR</v-btn>
            <v-btn @click="verifyEmail()" :disabled="!step2.valid || !form.email || !form.password || !form.rePassword" class="mb-1 white--text" color="#662929">PRÓXIMO</v-btn>
            <v-alert v-model="step2.error" border="left" class="mt-4" close-text="Close Alert" color="warning" dark dismissible>USUÁRIO JÁ EXISTE!</v-alert>
          </v-stepper-content>

          <v-stepper-step :complete="step > 3" step="3">
            SEUS DADOS
            <small>Quanto mais completo o seu cadastro, maiores as chances de você ser selecionado para uma vaga!</small>
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-form v-model="step3.valid" ref="step3Form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.name" :rules="step3.rules.name" label="SEU NOME COMPLETO" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.naturalness" :rules="step3.rules.naturalness" label="CIDADE/ESTADO EM QUE VOCÊ NASCEU" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.nationality" :rules="step3.rules.nationality" label="PAIS EM QUE VOCÊ NASCEU" filled/>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.birth2"
                        label="SEU NASCIMENTO"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="form.birth"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.height" :rules="step3.rules.height" v-mask="`#.##`" label="SUA ALTURA" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-select v-model="form.sex" label="GENERO" :items="sex" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-select v-model="form.marital_status" label="ESTADO CIVIL" :items="marital_status" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.pis" :rules="step3.rules.pis" label="SEU PIS (APENAS NUMEROS)" counter="11" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="step = 2" color="warning" class="mt-0 mr-4">VOLTAR</v-btn>
            <v-btn @click="step = 4" class="mb-1 white--text" :disabled="!step3.valid || !form.name || !form.naturalness || !form.nationality || !form.birth || !form.height || form.sex == 0 || form.marital_status == 0" color="#662929">PRÓXIMO</v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="step > 4" step="4">
            SEUS CONTATOS
            <small>Quando você for selecionado, precisaremos entrar em contato. Por isso, use dados corretos abaixo!</small>
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-form v-model="step4.valid" ref="step4Form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.tel1" v-mask="'(##) #########'" :rules="step4.rules.tel" label="TELEFONE 1" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.wpp1" label="WHATSAPP" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.tel2" v-mask="'(##) #########'" label="TELEFONE 2" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.wpp2" label="WHATSAPP" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="step = 3" color="warning" class="mt-0 mr-4">VOLTAR</v-btn>
            <v-btn @click="step = 5" class="mb-1 white--text" :disabled="!step4.valid || !form.tel1" color="#662929">PRÓXIMO</v-btn>
          </v-stepper-content>
          
          <v-stepper-step :complete="step > 5" step="5">
            SEU ENDEREÇO
            <small>O endereço será usado para validar a distancia entre você e as empresas.</small>
          </v-stepper-step>
          <v-stepper-content step="5">
            <v-form v-model="step5.valid" ref="step5Form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.cep" v-mask="'########'" :rules="step5.rules.cep" @blur="getCEP(form.cep)" label="CEP (APENAS NUMEROS)" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.street" disabled :rules="step5.rules.street" label="RUA / AVENIDA" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.number" :rules="step5.rules.number" label="NUMERO" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.comp" label="COMPLEMENTO" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.district" disabled :rules="step5.rules.district" label="BAIRRO" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.city" disabled :rules="step5.rules.city" label="CIDADE" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.state" disabled :rules="step5.rules.state" label="ESTADO" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-select v-model="form.zone" label="ZONA" :items="zones" item-text="name" item-value="code" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="step = 4" color="warning" class="mt-0 mr-4">VOLTAR</v-btn>
            <v-btn @click="step = 6" class="mb-1 white--text" :disabled="!step5.valid || !form.cep || !form.street || !form.number || !form.district || !form.city || !form.state" color="#662929">PRÓXIMO</v-btn>
          </v-stepper-content>
          
          <v-stepper-step :complete="step > 6" step="6">
            SEUS CONHECIMENTOS
            <small>Os conhecimentos são usados para validar as vagas compativeis com seu perfil.</small>
          </v-stepper-step>
          <v-stepper-content step="6">
            <v-form v-model="step6.valid" ref="step6Form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select v-model="form.schooling" label="ESCOLARIDADE" :items="schoolings" item-text="scho_name" item-value="scho_id" filled/>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="form.education" label="DESCRIÇÃO DA FORMAÇÃO" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-autocomplete v-model="form.occupation" label="PROFISSÃO" :items="occupations" item-text="occu_name" item-value="occu_id" filled/>
                </v-col>
                <v-col cols="12">
                  <h2 class="mb-6">INFORMATICA</h2>
                  <v-row class="ml-0">
                    <v-col cols="12" sm="12" md="6" v-for="(info, index) in infos" :key="index">
                      <v-switch v-model="form.computings" :label="info.info_name" :value="info.info_id" inset/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <h2 class="mb-6">IDIOMAS</h2>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" v-for="(language, index) in languages" :key="index">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-select v-model="language.level" :label="language.lang_name" :items="language_levels" item-text="name" item-value="code" filled/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="step = 5" color="warning" class="mt-0 mr-4">VOLTAR</v-btn>
            <v-btn @click="step = 7" class="mb-1 white--text" :disabled="form.schooling == 0 || form.occupation == 0" color="#662929">PRÓXIMO</v-btn>
          </v-stepper-content>
          
          <v-stepper-step :complete="step > 7" step="7">
            SEUS DETALHES
            <small>Preencha os detalhes com atenção.</small>
          </v-stepper-step>
          <v-stepper-content step="7">
            <v-form v-model="step7.valid" ref="step7Form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.employee" label="VOCÊ ESTA EMPREGADO?" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.cnh" label="TEM CNH?" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.cnh_categories" :disabled="form.cnh != 4" label="CATEGORIAS" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.vehicle" label="VOCÊ POSSUI VEICULO?" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.salary" label="PRETENSÃO SALARIAL" :items="salaries" item-text="sala_name" item-value="sala_id" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <!-- <v-text-field v-model="form.he_knew" label="COMO NOS CONHECEU?" filled/> -->
                  <v-select v-model="form.he_knew" :items="he_knew_list" item-text="name" item-value="code" label="COMO NOS CONHECEU?" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.deficient" label="VOCÊ É PCD?" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.deficient_apparatus" label="VOCÊ UTILIZA APARELHOS?" :items="yes_not" item-text="name" item-value="code" :disabled="form.deficient < 4" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.deficient_type" label="TIPO DE NECESSIDADE" :items="deficient_type" item-text="name" item-value="code" :disabled="form.deficient < 4" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.cid" label="SE POSSUIR, INFORME O CID" :disabled="form.deficient < 4" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="step = 6" color="warning" class="mt-0 mr-4">VOLTAR</v-btn>
            <v-btn @click="step = 8" class="mb-1 white--text" :disabled="(form.employee < 2 || form.cnh < 2 || form.vehicle < 2 || form.salary < 2 || form.deficient < 2) || (form.deficient == 4 && !form.cid)" color="#662929">PRÓXIMO</v-btn>
          </v-stepper-content>
          
          <v-stepper-step :complete="step > 8" step="8">
            SUAS QUALIFICAÇÕES E EXPERIENCIAS
            <small>Não economize palavras.</small>
          </v-stepper-step>
          <v-stepper-content step="8">
            <v-form v-model="step8.valid" ref="step8Form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.description" label="RESUMO DE QUALIFICAÇÕES PROFISSIONAIS" counter="600" maxlength="600" filled></v-textarea>
                </v-col>
                <hr>
                <v-col cols="12">
                  <v-checkbox v-model="step8.expCase" class="ml-3" label="SE NÃO POSSUIR EXPERIÊNCIAS, MARQUE ESSA OPÇÃO!"/>
                </v-col>
                <v-col v-show="!step8.expCase" cols="12">
                  <h5 class="mb-2">EMPRESA 1 - A PRIMEIRA EMPRESA É OBRIGATÓRIA, CASO VOCÊ NÂO MARQUE A AUSÊNCIA DE EXPERIÊNCIA!</h5>
                  <v-text-field v-model="exps.comp1.name" label="NOME DA EMPRESA *" filled/>
                  <v-text-field v-model="exps.comp1.office" label="CARGO *" filled/>
                  <v-text-field v-model="exps.comp1.activity" label="ATIVIDADES DESENVOLVIDAS *" filled/>
                  <v-text-field v-model="exps.comp1.admission" v-mask="'##/##/####'" label="DATA ADMISSÃO *" filled/>
                  <v-text-field v-model="exps.comp1.resignation" v-mask="'##/##/####'" label="DATA DEMISSÃO" filled/>
                  <v-text-field v-model="exps.comp1.salary" v-mask="mask" label="SALARIO *" filled/>
                </v-col>
                <v-col v-show="!step8.expCase" cols="12">
                  <v-divider class="mb-10" />
                  <h5 class="mb-2">EMPRESA 2</h5>
                  <v-text-field v-model="exps.comp2.name" label="NOME DA EMPRESA" filled/>
                  <v-text-field v-model="exps.comp2.office" label="CARGO" filled/>
                  <v-text-field v-model="exps.comp2.activity" label="ATIVIDADES DESENVOLVIDAS" filled/>
                  <v-text-field v-model="exps.comp2.admission" v-mask="'##/##/####'" label="DATA ADMISSÃO" filled/>
                  <v-text-field v-model="exps.comp2.resignation" v-mask="'##/##/####'" label="DATA DEMISSÃO" filled/>
                  <v-text-field v-model="exps.comp2.salary" v-mask="mask" label="SALARIO" filled/>
                </v-col>
                <v-col v-show="!step8.expCase" cols="12">
                  <v-divider class="mb-10" />
                  <h5 class="mb-2">EMPRESA 3</h5>
                  <v-text-field v-model="exps.comp3.name" label="NOME DA EMPRESA" filled/>
                  <v-text-field v-model="exps.comp3.office" label="CARGO" filled/>
                  <v-text-field v-model="exps.comp3.activity" label="ATIVIDADES DESENVOLVIDAS" filled/>
                  <v-text-field v-model="exps.comp3.admission" v-mask="'##/##/####'" label="DATA ADMISSÃO" filled/>
                  <v-text-field v-model="exps.comp3.resignation" v-mask="'##/##/####'" label="DATA DEMISSÃO" filled/>
                  <v-text-field v-model="exps.comp3.salary" v-mask="mask" label="SALARIO" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="step = 7" color="warning" class="mt-0 mr-4">VOLTAR</v-btn>
            <v-btn @click="step = 9" :disabled="!step8.expCase && (!exps.comp1.name || !exps.comp1.office || !exps.comp1.activity || !exps.comp1.admission || !exps.comp1.salary)" class="mb-1 white--text" color="#662929">PRÓXIMO</v-btn>
          </v-stepper-content>
          
          <v-stepper-step :complete="step > 9" step="9">
            TERMOS E POLITICAS
            <small>É necessário aceitar nossos termos de uso e politicas de privacidade!</small>
          </v-stepper-step>
          <v-stepper-content step="9">
            <v-form v-model="step9.valid" ref="step9Form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-checkbox v-model="terms" hide-details label="ACEITAR O TERMO DE CONSENTIMENTO."/>
                </v-col>
                <v-col cols="12" sm="12" class="pb-10">
                  <!-- <v-btn color="#662929" class="mt-2 white--text" @click="showTerms = true">ABRIR TERMO DE CONSENTIMENTO</v-btn> -->
                  <a @click="showTerms = true" class="mb-4">TERMO DE CONSENTIMENTO</a>
                </v-col>
              </v-row>
            </v-form>
            <v-btn @click="step = 8" color="warning" class="mt-0 mr-4">VOLTAR</v-btn>
            <v-btn @click="registry()" class="mb-1 white--text" :disabled="!terms" color="#662929">CONCLUIR E CADASTRAR</v-btn>
          </v-stepper-content>

        </v-stepper>
      </v-col>
    </v-row>

    <v-dialog v-model="showTerms" width="800px">
      <v-card>
        <v-card-title>TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS RECEBIMENTO E CADASTRAMENTO DE CURRÍCULO</v-card-title>
        <v-divider />
        <v-card-text>
          O aceite ao presente TERMO DE CONSENTIMENTO registra a MANIFESTAÇÃO LIVRE, INFORMADA e INEQUÍVOCA pela qual o Titular (CANDIDATO) concorda com o tratamento de seus dados pessoais por RESOLVE SERVIÇOS EMPRESARIAIS LTDA, pessoa jurídica de direito privado inscrita no CNPJ sob o número 65.628.950/0001-00, com sede na Rua Sebastião Humel, no 599, Centro, São José dos Campos/SP, CEP 12.210-200 (CONTROLADORA), em conformidade com a Lei 13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD), com a finalidade específica de cadastro em banco de candidatos e participação em procedimento de recrutamento e seleção para contratação de profissionais.
          O CANDIDATO autoriza e concorda que a CONTROLADORA tome decisões relativas ao tratamento de seus dados pessoais e realize o tratamento de dados pessoais, a fim de que esta possa: entrar em contato com o titular para agendamento e realização de entrevistas; fazer a classificação dos candidatos; estudar a adequação do perfil do candidato em relação à vaga ofertada; ter ciência sobre as habilidades do candidato com foco no preenchimento da vaga; avaliar a distância entre a residência do candidato e o local de trabalho; manutenção do currículo em banco de dados por prazo determinado; ofertar outra vaga de emprego, e compartilhar seus dados com o cliente (empresa detentora da vaga) para análise e decisão final sobre a contratação.
          O currículo do CANDIDATO será guardado em ambiente virtual eletrônico, de forma segura, e cujo acesso só é liberado aos responsáveis pela seleção. Os currículos cadastrados serão mantidos pela CONTROLADORA pelo prazo de 1 (um) ano a partir da assinatura deste Termo, para que eventual vaga futura, que se encaixe no perfil profissional do candidato possa ser-lhe ofertada. Após este período, em caso de não contratação do CANDIDATO (titular dos dados pessoais), ou ainda no caso da revogação deste Termo de Consentimento, deverá ocorrer o término do tratamento de dados pessoais realizado, momento em que serão eliminados na sua totalidade de seu banco de dados, ressalvadas as hipóteses em que por obrigações legais e regulatórias e/ou ainda nas hipóteses previstas no Art. 7o da Lei no 13.709/18, a CONTROLADORA deverá mantê-los para a realização de todas as ações relacionadas ao tratamento após o seu término.
          São vedadas as impressões de currículos pelos colaboradores da CONTROLADORA. No entanto, em caso excepcional, no qual seja imprescindível a impressão, efetuar-se-á a eliminação do documento tão logo finalize o processo seletivo. Os currículos eventualmente impressos serão acondicionados em pastas, alocadas em armários seguros, com acesso limitado e fechados com chave.
          A CONTROLADORA mantém vigilância sobre seus sistemas de tecnologia bem como ambientes físicos, visando evitar violações e acessos não autorizados aos dados ou a destruição, perda, alteração, difusão e comunicação acidentais ou ilícitas às informações. Em caso de incidentes com informações, serão adotadas as providências previstas na LGPD. Para fazer frente a eventuais medidas de controle de incidentes, será mantida comunicação ao TITULAR através
          dos canais por ele informados, sendo de sua responsabilidade manter atualizados os seus dados de contato.
          O TITULAR poderá, a qualquer momento, solicitar a exclusão de seus dados do banco de currículos, bem como pedir a alteração de seus dados, no caso de ser necessário atualizar as suas competências ou outros dados.
          No caso de o CANDIDATO (titular dos dados pessoais) ingressar na fase Contratual, os dados pessoais coletados e tratados serão mantidos sob o domínio da CONTROLADORA, momento em que será solicitado do CANDIDATO, quando necessário, novo consentimento para finalidades específicas decorrentes da fase de contratação.
          São garantidos ao TITULAR todos os direitos previstos no artigo 18 da LGPD, com destaque para: acesso aos dados; correção dos dados incompletos, inexatos e desatualizados e a revogação do consentimento previsto neste termo. Para o exercício dos direitos, o TITULAR deverá entrar em contato com a CONTROLADORA através de seu Encarregado de Proteção de Dados Andrea Petini através do e-mail: dpo@gruporesolve.com.br
          O aceite aos termos acima é uma condicionante para que o TITULAR possa encaminhar seu currículo.
          O CANDIDATO ao clicar no ícone “Li e concordo” e em “enviar” os dados e currículo na plataforma da RESOLVE, DECLARA expressamente seu CONSENTIMENTO, dando plena ciência dos direitos e obrigações decorrentes deste instrumento, a fim de que seus dados sejam tratados na forma descrita acima com a finalidade única e exclusiva de finalidade específica de cadastro em banco de candidatos e participação em procedimento de recrutamento e seleção para contratação de profissionais, declarando ainda ter lido, compreendido e aceitado todos os termos e condições aqui descritos.
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn color="#662929" class="my-2 white--text" large @click="showTerms = false">FECHAR TERMO</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { cpf } from 'cpf-cnpj-validator'
import cep from 'cep-promise'
import moment from 'moment'

import createNumberMask from 'text-mask-addons/dist/createNumberMask'
const currencyMask = createNumberMask({
  prefix: 'R$',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
})

export default {
  name: 'Registry',
  data() {
    return {
      step: 1,
      showPass: false,
      form: {
        cpf: '',
        rg: '',
        email: '',
        password: '',
        rePassword: '',
        name: '',
        naturalness: '',
        nationality: '',
        height: '',
        birth: '',
        birth2: '',
        sex: 0,
        marital_status: 0,
        tel1: '',
        wpp1: 2,
        tel2: '',
        wpp2: 2,
        cep: '',
        street: '',
        number: '',
        comp: '',
        district: '',
        state: '',
        city: '',
        schooling: 0,
        occupation: 0,
        computings: [],
        employee: 0,
        cnh: 0,
        cnh_categories: '',
        vehicle: 0,
        salary: 0,
        he_knew: 'Google',
        deficient: 0,
        deficient_apparatus: 2,
        deficient_type: 0,
        cid: '',
        description: '',
        exp: '',
        education: '',
        pis: '',
        zone: '',
      },
      step1: {
        valid: false,
        loading: false,
        error: false,
        rules: {
          rg: [
            v => !!v || 'RG obrigatório.',
            v => v.length >= 12 || 'RG incompleto.',
          ],
          cpf: [
            v => !!v || 'CPF obrigatório.',
            v => v.length == 14 || 'CPF incompleto.',
            v => cpf.isValid(v) || 'CPF invalido.',
          ],
        }
      },
      step2: {
        valid: false,
        loading: false,
        error: false,
        rules: {
          email: [
            v => !!v || 'E-mail obrigatório.',
            v => /.+@.+/.test(v) || 'E-mail inválido.',
            v => !/^[ \t]+|[ \t]+$/.test(v) || 'Remova o espaço em branco.',
          ],
          password: [
            v => !!v || 'Senha obrigatória.',
            v => v.length >= 6 || 'Senha não pode conter menos que 6 caracteres.',
          ],
          rePassword: [
            v => !!v || 'Senha obrigatória.',
            v => v == this.form.password || 'Senha não pode conter menos que 6 caracteres.',
          ],
        }
      },
      step3: {
        valid: false,
        rules: {
          name: [v => !!v || 'Nome obrigatório.'],
          naturalness: [v => !!v || 'Naturalidade obrigatória.'],
          nationality: [v => !!v || 'Nacionalidade obrigatória.'],
          birth: [v => !!v || 'Nascimento obrigatório.'],
          height: [v => !!v || 'Altura obrigatória.'],
          pis: [
            v => v.length <= 11 || 'SEU PIS DEVE CONTER APENAS 11 DIGITOS',
          ],
        }
      },
      step4: {
        valid: false,
        rules: {
          tel: [v => !!v || 'Telefone 1 obrigatório.'],
        }
      },
      step5: {
        valid: false,
        rules: {
          cep: [ v => !!v || 'CEP obrigatório.', v => v.length == 8 || 'CEP deve conter 8 digitos.' ],
          street: [ v => !!v || 'street obrigatório.' ],
          number: [ v => !!v || 'Numero obrigatório.' ],
          district: [ v => !!v || 'Bairro obrigatório.' ],
          city: [ v => !!v || 'Cidade obrigatória.' ],
          state: [ v => !!v || 'Estado obrigatório.' ],
        }
      },
      step6: {
        valid: false,
        rules: {
          
        }
      },
      step7: {
        valid: false,
        rules: {
          
        },
      },
      step8: {
        valid: false,
        rules: {
          
        },
        expCase: false
      },
      step9: {
        valid: false,
        rules: {
          
        }
      },
      yes_not: [
        { name: 'NÃO', code: 2 },
        { name: 'SIM', code: 4 },
      ],
      sex: [
        { name: 'FEMININO', code: 4 },
        { name: 'MASCULINO', code: 2 },
      ],
      marital_status: [
        { name: 'Solteiro(a)', code: 2 },
        { name: 'Casado(a)', code: 4 },
        { name: 'Desquitado(a)', code: 6 },
        { name: 'Divorciado(a)', code: 8 },
        { name: 'Viuvo(a)', code: 10 },
        { name: 'Outro', code: 12 },
      ],
      language_levels: [
        { name: 'Não possui', code: 0 },
        { name: 'Básico', code: 2 },
        { name: 'Intermediario', code: 4 },
        { name: 'Fluente', code: 6 },
      ],
      deficient_type: [
        { name: 'Auditiva', code: 2 },
        { name: 'Fisica', code: 4 },
        { name: 'Visual', code: 6 },
        { name: 'Multiplas', code: 8 },
        { name: 'Outra(s)', code: 10 }
      ],
      he_knew_list: [
        { name: 'GOOGLE', code: 'Google' },
        { name: 'FACEBOOK', code: 'Facebook' },
        { name: 'LINKEDIN', code: 'Linkedin' },
        { name: 'WHATSAPP', code: 'Whatsapp' },
        { name: 'INDICAÇÃO', code: 'Indicação' },
        { name: 'OUTRO', code: 'Outro' },
      ],
      zones: [
        { name: 'CENTRO', code: 'CENTRO' },
        { name: 'LESTE', code: 'LESTE' },
        { name: 'NORTE', code: 'NORTE' },
        { name: 'OESTE', code: 'OESTE' },
        { name: 'SUL', code: 'SUL' }
      ],
      menu: false,
      terms: false,
      showTerms: false,
      schoolings: [],
      occupations: [],
      salaries: [],
      infos: [],
      languages: [],
      exps: {
        comp1: {
          name: '',
          office: '',
          activity: '',
          admission: '',
          resignation: '',
          salary: ''
        },
        comp2: {
          name: '',
          office: '',
          activity: '',
          admission: '',
          resignation: '',
          salary: ''
        },
        comp3: {
          name: '',
          office: '',
          activity: '',
          admission: '',
          resignation: '',
          salary: ''
        },
      },
      mask: currencyMask,
    }
  },
  created() {
    this.getDataNew()
    this.logout()
  },
  methods: {
    // Nascimento pickert. //
    save (date) {
      this.$refs.menu.save(date)
      this.form.birth2 = moment(date).format('DD/MM/YYYY')
    },
    // Obtem endereço através do CEP informado. //
    getCEP(code) {
      cep(code).then(success => {
        this.form.street = success.street
        this.form.district = success.neighborhood
        this.form.city = success.city
        this.form.state = success.state
      }).catch(err => {

      })
    },
    // Obtem dados auxiliares para cadastro. //
    getDataNew() {
      this.$axios.get(`/candidates/data/new`).then(success => {
        this.schoolings = success.data.schoolings
        this.occupations = success.data.occupations
        this.salaries = success.data.salaries
        this.infos = success.data.infos
        this.languages = success.data.languages.map(item => {
          return {
            ...item,
            level: 0
          }
        })
        this.loads.dataNew = false
      })
    },
    // Cadastro de novo candidato. //
    registry() {
      let expFinal = '*'
      if (this.step8.expCase) {
        expFinal = 'AINDA NÃO POSSUO EXPERIÊNCIAS!'
      } else {
        expFinal = `EMPRESA 1 >
NOME: ${this.exps.comp1.name}
CARGO: ${this.exps.comp1.office}
ATIVIDADES: ${this.exps.comp1.activity}
ADMISSÃO: ${this.exps.comp1.admission}
DEMISSÃO: ${this.exps.comp1.resignation}
SALARIO: ${this.exps.comp1.salary}
------------------------------------
EMPRESA 2 >
NOME: ${this.exps.comp2.name}
CARGO: ${this.exps.comp2.office}
ATIVIDADES: ${this.exps.comp2.activity}
ADMISSÃO: ${this.exps.comp2.admission}
DEMISSÃO: ${this.exps.comp2.resignation}
SALARIO: ${this.exps.comp2.salary}
------------------------------------
EMPRESA 3 >
NOME: ${this.exps.comp3.name}
CARGO: ${this.exps.comp3.office}
ATIVIDADES: ${this.exps.comp3.activity}
ADMISSÃO: ${this.exps.comp3.admission}
DEMISSÃO: ${this.exps.comp3.resignation}
SALARIO: ${this.exps.comp3.salary}
------------------------------------
`
      }

      this.$axios.post(`/candidates`, {
        email: this.form.email.toLowerCase(),
        password: this.form.password,
        cpf: this.form.cpf,
        rg: this.form.rg,
        name: this.form.name,
        naturalness: this.form.naturalness,
        nationality: this.form.nationality,
        birth : this.form.birth,
        height: this.form.height,
        sex: this.form.sex,
        marital_status: this.form.marital_status,
        address: this.form.street,
        address_number: this.form.number,
        address_comp: this.form.comp,
        address_district: this.form.district,
        address_cep: this.form.cep,
        address_state: this.form.state,
        address_city: this.form.city,
        tel1: this.form.tel1,
        tel1_wpp: this.form.wpp1,
        tel2: this.form.tel2,
        tel2_wpp: this.form.wpp2,
        schooling: this.form.schooling,
        occupation: this.form.occupation,
        description: this.form.description,
        exp: expFinal,
        employee: this.form.employee,
        cnh: this.form.cnh,
        cnh_categories: this.form.cnh_categories,
        vehicle: this.form.vehicle,
        salary: this.form.salary,
        he_knew: this.form.he_knew,
        deficient: this.form.deficient,
        deficient_apparatus: this.form.deficient_apparatus,
        deficient_type: this.form.deficient_type,
        cid: this.form.cid,
        infos: this.form.computings,
        languages: this.languages,
        education: this.form.education,
        pis: this.form.pis,
        zone: this.form.zone,
      }).then(success => {
        this.login()
      })
    },
    // Acesso ao sistema e aquisição de credenciais. //
    login() {
      this.$axios.post(`/users/login`, {
        email: this.form.email,
        password: this.form.password
      }).then(success => {
        localStorage.setItem('miolocat', success.data.token)
        localStorage.setItem('miolocat_userType', success.data.type)
        axios.defaults.headers.common['Authorization'] = `Bearer ${success.data.token}`
        this.$store.commit('SET_USER', {
          id: success.data.id,
          name: success.data.name,
          email: success.data.email,
          type: success.data.type,
          created: success.data.created,
        })
        this.$router.push({ name: 'Home' })
      })
    },
    // Remove todas as credenciais. //
    logout() {
      if (localStorage.getItem('miolocat')) {
        localStorage.removeItem('miolocat')
        localStorage.removeItem('miolocat_userType')
        this.$store.commit('SET_USER', {
          id: '',
          name: '',
          email: '',
          type: '',
          created: '',
        })
        location.reload()
      }
    },
    // Verifica documento. //
    verifyDocument() {
      this.step1.loading = true
      this.$axios.post(`/candidates/verify/document`, {
        document: this.form.cpf
      }).then(success => {
        if (success.data.document) {
          this.step = 2
          this.step1.loading = false
        } else {
          this.step1.loading = false
          this.step1.error = true
        }
      })
    },
    // Verifica email. //
    verifyEmail() {
      this.step2.loading = true
      this.$axios.post(`/candidates/verify/email`, {
        email: this.form.email
      }).then(success => {
        if (success.data.email) {
          this.step = 3
          this.step2.loading = false
        } else {
          this.step2.loading = false
          this.step2.error = true
        }
      })
    }
  },
  computed: {
    user() { return this.$store.getters.GET_USER },
    verifyCPF() {
      return !cpf.isValid(this.form.cpf)
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
}
</script>

<style scoped>
  .registry-container, .stepper-box {
    height: 100%;
  }
</style>


<template>
  <v-container>

    <v-app-bar id="system-bar" color="#662929" height="46" app>
      <v-app-bar-nav-icon color="#dddddd" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer />
      <template v-if="!this.$vuetify.breakpoint.mobile">
        <v-btn class="mr-2" @click="$router.push({ name: `Home` })" small>PÁGINA PRINCIPAL</v-btn>
        <v-btn @click="logout()" small>SAIR</v-btn>
      </template>
    </v-app-bar>

    <v-navigation-drawer id="system-sidebar" v-model="drawer" width="240" app>
      <v-list-item>
        <v-list-item-content>
          <img src="@/assets/logoDash.jpg" width="100" alt="logo">
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          <v-list-item-subtitle>Tipo: <span class="text-capitalize">{{ user.type | userType }}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item link @click="$router.push({ name: `Resume` })">
          <v-list-item-icon>
            <v-icon>fas fa-tachometer-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>PAINEL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="user.type == 2" link @click="$router.push({ name: `Users` })">
          <v-list-item-icon>
            <v-icon>fas fa-users</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>USUÁRIOS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$router.push({ name: `Companies` })">
          <v-list-item-icon>
            <v-icon>fas fa-building</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>EMPRESAS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$router.push({ name: `NewProspects` })">
          <v-list-item-icon>
            <v-icon>fas fa-user-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>PROSPECÇÃO</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$router.push({ name: `Candidates` })">
          <v-list-item-icon>
            <v-icon>fas fa-address-card</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>CANDIDATOS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$router.push({ name: `Jobs` })">
          <v-list-item-icon>
            <v-icon>fas fa-list</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>VAGAS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="$router.push({ name: `Reports` })">
          <v-list-item-icon>
            <v-icon>fas fa-file-export</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>RELATÓRIOS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group :value="false" prepend-icon="fas fa-plus-square" no-action>
          <template v-slot:activator>
            <v-list-item-title>CADASTROS</v-list-item-title>
          </template>
          <v-list-item link @click="$router.push({ name: `Schooling` })">
            <v-list-item-title>ESCOLARIDADE</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ name: `Salary` })">
            <v-list-item-title>FAIXA SALARIAL</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ name: `Computing` })">
            <v-list-item-title>CURSOS</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ name: `Deadlines` })">
            <v-list-item-title>PRAZOS DE VAGAS</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ name: `Branches` })">
            <v-list-item-title>RAMOS DE ATIVIDADE</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ name: `Units` })">
            <v-list-item-title>UNIDADES</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item link @click="$router.push({ name: `Cities` })">
            <v-list-item-title>CIDADES</v-list-item-title>
          </v-list-item> -->
          <v-list-item link @click="$router.push({ name: `Languages` })">
            <v-list-item-title>IDIOMAS</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ name: `Professions` })">
            <v-list-item-title>OCUPAÇÕES</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ name: `Tests` })">
            <v-list-item-title>TESTES</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$router.push({ name: `Benefits` })">
            <v-list-item-title>BENEFICIOS</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item link @click="$router.push({ name: `Setup` })">
          <v-list-item-icon>
            <v-icon>fas fa-tools</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>CONFIGURAÇÕES</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="this.$vuetify.breakpoint.mobile" link @click="$router.push({ name: `Home` })">
          <v-list-item-icon>
            <v-icon>fas fa-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>PÁGINA PRINCIPAL</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="this.$vuetify.breakpoint.mobile" link @click="logout()">
          <v-list-item-icon>
            <v-icon>fas fa-power-off</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>SAIR DO SISTEMA</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container fluid>
      <router-view />
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      drawer: false
    }
  },
  mounted() {
    if (!this.$vuetify.breakpoint.mobile) { this.drawer = true }

    window.onbeforeprint = (event) => {
      this.drawer = false
    }

    this.$root.$on('evt::close', () => {
      this.drawer = false
    })
  },
  methods: {
    logout() {
      localStorage.removeItem('miolocat')
      localStorage.removeItem('miolocat_userType')
      this.$store.commit('SET_USER', {
        id: '',
        name: '',
        email: '',
        type: '',
        created: '',
      })
      this.$router.push({ name: 'Home' })
    }
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style scoped>

</style>


<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading">
          <v-card-title>FILTRO DE VAGAS</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-select v-model="filters.object.consultant" :loading="loading" :disabled="loading" :items="filters.lists.consultants" item-text="name" item-value="code" label="CONSULTOR(A)" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select v-model="filters.object.state" :loading="loading" :disabled="loading" :items="filters.lists.states" item-text="name" item-value="code" label="ESTADO" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select v-model="filters.object.period" :loading="loading" :disabled="loading" :items="filters.lists.periods" item-text="name" item-value="code" label="PERÍODO" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="filters.object.id" :loading="loading" :disabled="loading" label="IDENTIFICADOR DA VAGA" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <v-autocomplete v-model="filters.object.occupation" :items="filters.lists.occupations" item-text="name" item-value="code" label="PROFISSÃO" :loading="loadingOccupations || loading" :disabled="loadingOccupations || loading" filled/>
              </v-col>
              <v-col cols="12">
                <v-btn color="#662929" class="white--text" :loading="loading" @click="search()">BUSCAR FILTROS</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>NOME / RAZÃO SOCIAL</th>
                      <th>RAMO / PROFISSÃO</th>
                      <th>IDENTIFICADOR / DATA DE CADASTRO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(job, index) in searched" :key="index" @click="openJob(job)">
                      <td>
                        <div>{{ job.comp_fantasy }}</div>
                        <div>{{ job.comp_name }}</div>
                      </td>
                      <td>
                        <div>{{ job.bran_name }}</div>
                        <div>{{ job.occu_name }}</div>
                      </td>
                      <td>
                        <div>{{ job.jobs_id }}</div>
                        <div>{{ job.jobs_created | birth }}</div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Search',
  data() {
    return {
      filters: {
        object: {
          consultant: 0,
          state: 0,
          period: 0,
          id: '',
          occupation: 0
        },
        lists: {
          consultants: [
            { name: 'TODOS', code: 0 },
          ],
          states: [
            { name: 'TODOS', code: 0 },
            { name: 'CANCELADA', code: 2 },
            { name: 'ABERTA', code: 4 },
            { name: 'FECHADA PELA CONCORRENCIA', code: 6 },
            { name: 'FECHADA PELA RESOLVE', code: 8 },
            { name: 'INDICAÇÃO', code: 10 },
            { name: 'SÓ ADMINISTRAÇÃO', code: 12 },
          ],
          periods: [
            { name: 'TODOS', code: 0 },
            { name: '1 MÊS', code: 1 },
            { name: '2 MÊS', code: 2 },
            { name: '3 MÊS', code: 3 },
            { name: '4 MÊS', code: 4 },
            { name: '5 MÊS', code: 5 },
            { name: '6 MÊS', code: 6 },
            { name: '7 MÊS', code: 7 },
            { name: '8 MÊS', code: 8 },
            { name: '9 MÊS', code: 9 },
            { name: '10 MÊS', code: 10 },
            { name: '11 MÊS', code: 11 },
            { name: '12 MÊS', code: 12 },
          ],
          occupations: [
            { name: 'TODAS', code: 0 }
          ]
        },
      },
      query: '',
      searched: [],
      loading: false,
      loadingOccupations: true,
    }
  },
  created() {
    this.getConsultants()
    this.getOccupations()
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  },
  methods: {
    // Buscar consultores(a). //
    getConsultants() {
      this.$axios.get(`/users/consultants`).then(success => {
        success.data.users.forEach(consultant => {
          this.filters.lists.consultants.push({
            name: consultant.user_name,
            code: consultant.user_id,
          })
        })
      })
    },
    // Busca ocupações. //
    getOccupations() {
      this.$axios.get(`/occupations`).then(success => {
        success.data.occupations.forEach(item => {
          this.filters.lists.occupations.push({
            name: item.occu_name,
            code: item.occu_id,
          })
        })
        this.loadingOccupations = false
      })
    },
    // Buscar filtros. //
    search() {
      this.loading = true
      this.searched = []
      this.query = ''
      if (this.filters.object.consultant) {
        this.query += `jobs_consultant = ${this.filters.object.consultant}`
      }
      if (this.filters.object.state) {
        if (this.filters.object.consultant) { this.query += ` and ` }
        this.query += `jobs_status = ${this.filters.object.state}`
      }
      if (this.filters.object.period) {
        if (this.filters.object.consultant || this.filters.object.state) { this.query += ` and ` }
        this.query += `jobs_created between '${moment().subtract(this.filters.object.period, 'month').format('YYYY-MM-DD 00:00:00')}' and '${moment().format('YYYY-MM-DD 23:59:00')}'`
      }
      if (this.filters.object.id) {
        if (this.filters.object.period) { this.query += ` and ` }
        this.query += `jobs_id = ${this.filters.object.id}`
      }
      if (this.filters.object.occupation) {
        if (this.filters.object.id) { this.query += ` and ` }
        this.query += `jobs_occupation = ${this.filters.object.occupation}`
      }
      this.$axios.post(`/jobs/search`, {
        query: this.query
      }).then(success => {
        this.searched = success.data.jobs
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    // Abre vaga. //
    openJob(job) {
      if (this.user.type != 8) {
        // this.$router.push({ path: `/dashboard/jobs/${job.jobs_code}` })
        window.open(this.$router.resolve({ path: `/dashboard/jobs/${job.jobs_code}` }).href, '_blank')
      } else {
        // this.$router.push({ path: `/consultant/jobs/${job.jobs_code}` })
        window.open(this.$router.resolve({ path: `/consultant/jobs/${job.jobs_code}` }).href, '_blank')
      }
    },
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>


<template>
  <v-container>
    <v-row id="no-print">
      <v-col cols="12" sm="12" md="7">
        <v-card :loading="loads.company" class="bottom-detail">
          <v-card-title>DETALHES</v-card-title>
          <v-card-subtitle>{{ id }}</v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4">
            <p><strong>IDENTIFICADOR: </strong>{{ company.comp_id }}</p>
            <p><strong>RAZÃO SOCIAL: </strong>{{ company.comp_name }}</p>
            <p><strong>NOME FANTASIA: </strong>{{ company.comp_fantasy }}</p>
            <p><strong>CNPJ: </strong>{{ company.comp_cnpj }}</p>
            <p><strong>I. ESTADUAL: </strong>{{ company.comp_ie }}</p>
            <p><strong>I. MUNICIPAL: </strong>{{ company.comp_im }}</p>
            <p><strong>RAMO DE ATIVIDADE: </strong>{{ company.bran_name }}</p>
            <p><strong>WEBSITE: </strong>{{ company.comp_site }}</p>
            <p><strong>E-MAIL: </strong>{{ company.comp_email }}</p>
            <p><strong>CONSULTOR: </strong>{{ company.user_name }} - {{ company.user_email }}</p>
            <p><strong>COMERCIAL: </strong>{{ company.come_name }} - {{ company.come_email }}</p>
            <p><strong>RECRUTAMENTO E SELEÇÃO: </strong>{{ company.comp_recruitment }}%</p>
            <p><strong>MÃO DE OBRA TEMPORÁRIA: </strong>{{ company.comp_labor }}%</p>
            <p><strong>CLIENTE GRUPO RESOLVE: </strong>{{ company.comp_gpresolve | gpResolve }}</p>
            <p><strong>EMPRESA LIBERADA: </strong>{{ company.comp_state | unblocked }}</p>
            <p><strong>TIPO DE CONTRATO: </strong>{{ company.comp_contract | contract }}</p>
            <v-divider class="mb-5"></v-divider>
            <p><strong>RESPONSAVEL FINANCEIRO: </strong>{{ company.comp_responsible_name }}</p>
            <p><strong>EMAIL: </strong>{{ company.comp_responsible_email }}</p>
            <p><strong>TELEFONE 1: </strong>{{ company.comp_responsible_tel }} <span v-if="company.comp_responsible_tel_wpp == 4"><v-icon color="success" @click="openWhatsapp(company.comp_responsible_tel)">fab fa-whatsapp-square</v-icon></span></p>
            <p><strong>TELEFONE 2: </strong>{{ company.comp_responsible_cel }} <span v-if="company.comp_responsible_cel_wpp == 4"><v-icon color="success" @click="openWhatsapp(company.comp_responsible_cel)">fab fa-whatsapp-square</v-icon></span></p>
            <v-divider class="mb-5"></v-divider>
            <p><strong>RESPONSAVEL RH: </strong>{{ company.comp_rh_name }}</p>
            <p><strong>E-MAIL: </strong>{{ company.comp_rh_email }}</p>
            <p><strong>TELEFONE 1: </strong>{{ company.comp_rh_tel }} <span v-if="company.comp_rh_tel_wpp == 4"><v-icon color="success" @click="openWhatsapp(company.comp_rh_tel)">fab fa-whatsapp-square</v-icon></span></p>
            <p><strong>TELEFONE 2: </strong>{{ company.comp_rh_cel }} <span v-if="company.comp_rh_cel_wpp == 4"><v-icon color="success" @click="openWhatsapp(company.comp_rh_cel)">fab fa-whatsapp-square</v-icon></span></p>
            <v-divider class="mb-5"></v-divider>
            <p><strong>END. COBRANÇA: </strong>{{ company.comp_correspondence }}, {{ company.comp_correspondence_number }}. {{ company.comp_correspondence_comp }}</p>
            <p>{{ company.comp_correspondence_district }}, {{ company.comp_correspondence_city }}/{{ company.comp_correspondence_state }} - {{ company.comp_correspondence_cep }}</p>
            <v-divider class="mb-5"></v-divider>
            <p><strong>END. CORRESPONDENCIA: </strong>{{ company.comp_billing }}, {{ company.comp_billing_number }}. {{ company.comp_billing_comp }}</p>
            <p>{{ company.comp_billing_district }}, {{ company.comp_billing_city }}/{{ company.comp_billing_state }} - {{ company.comp_billing_cep }}</p>
            <v-divider class="mb-5"></v-divider>
            <p><strong>DESCRIÇÃO: </strong>{{ company.comp_description }}</p>
            <v-divider class="mb-5"></v-divider>
            <p><strong>DETALHES PARA CONTRATAÇÃO: </strong>{{ company.comp_hiring }}</p>
            <v-divider></v-divider>
            <v-btn color="#828a03" class="white--text mt-4" @click="print()" block>IMPRIMIR</v-btn>
            <!-- <v-divider class="mb-5"></v-divider>
            <v-btn color="#e98b0d" class="white--text" @click="$router.push({ path: `/dashboard/companies/${company.comp_code}/edit` })" block>EDITAR</v-btn>
            <v-btn color="#a93121" class="white--text mt-3" @click="remove()" block>EXCLUIR</v-btn> -->
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-btn class="mb-6 white--text" color="#662929" @click="$router.push({ path: `/consultant/jobs/new/${company.comp_code}/${company.comp_id}` })" large block>CADASTRAR VAGA</v-btn>
        <v-card class="bottom-detail">
          <v-card-title>HISTORICO</v-card-title>
          <v-card-subtitle>ULTIMOS REGISTROS</v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
              <v-list-item-content>
                <v-list-item-title>{{ history.user_name }}</v-list-item-title>
                <v-list-item-content>{{ history.hs_comp_message }}</v-list-item-content>
                <v-list-item-subtitle>{{ history.hs_comp_created | datetime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mb-4 mt-4"/>
            <v-textarea v-model="message" label="MENSAGEM" auto-grow filled/>
            <v-btn class="white--text" color="#662929" :disabled="!message.length >= 1" large block @click="newHistorical()" :loading="newHistoricalLoading">ADICIONAR</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12">
        <v-card class="bottom-detail">
          <v-card-title>VAGAS</v-card-title>
          <v-card-subtitle>ULTIMOS REGISTROS</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>RAMO / PROFISSÃO</th>
                  <th>INICIO / FIM</th>
                  <th>CÓDIGO / UNIDADE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(job, index) in jobs" :key="index" @click="$router.push({ path: `/consultant/jobs/${job.jobs_code}` })">
                  <td>
                    <div>{{ job.bran_name }}</div>
                    <div>{{ job.occu_name }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_start | birth }}</div>
                    <div>{{ job.jobs_end | birth }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_code }}</div>
                    <div>{{ job.unit_name }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div id="section-to-print">
      <print id="section-to-print" message="RELATÓRIO DE EMPRESA">
        <div class="mt-4">
          <p><strong>IDENTIFICADOR: </strong>{{ company.comp_id }}</p>
          <p><strong>RAZÃO SOCIAL: </strong>{{ company.comp_name }}</p>
          <p><strong>NOME FANTASIA: </strong>{{ company.comp_fantasy }}</p>
          <p><strong>CNPJ: </strong>{{ company.comp_cnpj }}</p>
          <p><strong>I. ESTADUAL: </strong>{{ company.comp_ie }}</p>
          <p><strong>I. MUNICIPAL: </strong>{{ company.comp_im }}</p>
          <p><strong>RAMO DE ATIVIDADE: </strong>{{ company.bran_name }}</p>
          <p><strong>WEBSITE: </strong>{{ company.comp_site }}</p>
          <p><strong>E-MAIL: </strong>{{ company.comp_email }}</p>
          <p><strong>CONSULTOR: </strong>{{ company.user_name }} - {{ company.user_email }}</p>
          <p><strong>COMERCIAL: </strong>{{ company.come_name }} - {{ company.come_email }}</p>
          <p><strong>RECRUTAMENTO E SELEÇÃO: </strong>{{ company.comp_recruitment }}%</p>
          <p><strong>MÃO DE OBRA TEMPORÁRIA: </strong>{{ company.comp_labor }}%</p>
          <p><strong>CLIENTE GRUPO RESOLVE: </strong>{{ company.comp_gpresolve | gpResolve }}</p>
          <p><strong>EMPRESA LIBERADA: </strong>{{ company.comp_state | unblocked }}</p>
          <p><strong>TIPO DE CONTRATO: </strong>{{ company.comp_contract | contract }}</p>
          <v-divider class="mb-5"></v-divider>
          <p><strong>RESPONSAVEL FINANCEIRO: </strong>{{ company.comp_responsible_name }}</p>
          <p><strong>EMAIL: </strong>{{ company.comp_responsible_email }}</p>
          <p><strong>TELEFONE 1: </strong>{{ company.comp_responsible_tel }} <span v-if="company.comp_responsible_tel_wpp == 4"><v-icon color="success" @click="openWhatsapp(company.comp_responsible_tel)">fab fa-whatsapp-square</v-icon></span></p>
          <p><strong>TELEFONE 2: </strong>{{ company.comp_responsible_cel }} <span v-if="company.comp_responsible_cel_wpp == 4"><v-icon color="success" @click="openWhatsapp(company.comp_responsible_cel)">fab fa-whatsapp-square</v-icon></span></p>
          <v-divider class="mb-5"></v-divider>
          <p><strong>RESPONSAVEL RH: </strong>{{ company.comp_rh_name }}</p>
          <p><strong>E-MAIL: </strong>{{ company.comp_rh_email }}</p>
          <p><strong>TELEFONE 1: </strong>{{ company.comp_rh_tel }} <span v-if="company.comp_rh_tel_wpp == 4"><v-icon color="success" @click="openWhatsapp(company.comp_rh_tel)">fab fa-whatsapp-square</v-icon></span></p>
          <p><strong>TELEFONE 2: </strong>{{ company.comp_rh_cel }} <span v-if="company.comp_rh_cel_wpp == 4"><v-icon color="success" @click="openWhatsapp(company.comp_rh_cel)">fab fa-whatsapp-square</v-icon></span></p>
          <v-divider class="mb-5"></v-divider>
          <p><strong>END. COBRANÇA: </strong>{{ company.comp_correspondence }}, {{ company.comp_correspondence_number }}. {{ company.comp_correspondence_comp }}</p>
          <p>{{ company.comp_correspondence_district }}, {{ company.comp_correspondence_city }}/{{ company.comp_correspondence_state }} - {{ company.comp_correspondence_cep }}</p>
          <v-divider class="mb-5"></v-divider>
          <p><strong>END. CORRESPONDENCIA: </strong>{{ company.comp_billing }}, {{ company.comp_billing_number }}. {{ company.comp_billing_comp }}</p>
          <p>{{ company.comp_billing_district }}, {{ company.comp_billing_city }}/{{ company.comp_billing_state }} - {{ company.comp_billing_cep }}</p>
          <v-divider class="mb-5"></v-divider>
          <p><strong>DESCRIÇÃO: </strong>{{ company.comp_description }}</p>
          <v-divider class="mb-5"></v-divider>
          <p><strong>DETALHES PARA CONTRATAÇÃO: </strong>{{ company.comp_hiring }}</p>
        </div>
        <v-divider />
        <div class="mt-2">
          <h1>VAGAS</h1>
          <v-simple-table>
            <thead>
              <tr>
                <th>RAMO / PROFISSÃO</th>
                <th>INICIO / FIM</th>
                <th>IDENT. / UNIDADE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(job, index) in jobs" :key="index" @click="$router.push({ path: `/dashboard/jobs/${job.jobs_code}` })">
                <td>
                  <div>{{ job.bran_name }}</div>
                  <div>{{ job.occu_name }}</div>
                </td>
                <td>
                  <div>{{ job.jobs_start | birth }}</div>
                  <div>{{ job.jobs_end | birth }}</div>
                </td>
                <td>
                  <div>{{ job.jobs_id }}</div>
                  <div>{{ job.unit_name }}</div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <!-- <v-divider class="my-6"></v-divider>
        <div class="mt-4">
          <h1>MOVIMENTAÇÃO</h1>
          <v-list-item v-for="(history, index) in historicals" :key="index" three-line>
            <v-list-item-content>
              <v-list-item-title>{{ history.user_name }} - {{ history.user_email }}</v-list-item-title>
              <v-list-item-subtitle>{{ history.hs_comp_message }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ history.hs_comp_created | datetime }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div> -->
      </print>
    </div>
  </v-container>
</template>

<script>
import print from '../../components/Print.vue'

export default {
  name: 'ConsultantCompany',
  props: [ 'id' ],
  components: { print },
  data() {
    return {
      loads: {
        company: true
      },
      company: {},
      jobs: [],
      message: '',
      historicals: [],
      newHistoricalLoading: false,
    }
  },
  created() {
    this.getCompany()
  },
  methods: {
    // Obter dados da empresa. //
    getCompany() {
      this.$axios.get(`/companies/${this.id}`).then(success => {
        this.company = success.data
        this.loads.company = false
        this.listHistoricals()
        this.getJobs()
      })
    },
    // Obtem vagas da empresa. //
    getJobs() {
      this.$axios.get(`/jobs/company/${this.company.comp_id}`).then(success => {
        this.jobs = success.data.jobs
      })
    },
    // Abrir conversa no Whatsapp. //
    openWhatsapp(number) {
      window.open(`https://api.whatsapp.com/send?phone=55${number}`)
    },
    // Remove empresa. //
    remove() {
      this.$axios.delete(`/companies/${this.id}`).then(success => {
        this.$router.push({ path: `/dashboard/companies` })
      })
    },
    // Adiciona historico a empresa. //
    newHistorical() {
      this.newHistoricalLoading = true
      this.$axios.post(`/historicals/companies/${this.company.comp_id}`, {
        message: this.message,
        messages: this.historicals,
        company: {
          name: this.company.comp_name,
          cnpj: this.company.comp_cnpj,
          code: this.company.comp_code,
          id: this.company.comp_id,
          commercial_name: this.company.come_name,
          commercial_email: this.company.come_email
        }
      }).then(success => {
        this.message = ''
        this.listHistoricals()
        this.newHistoricalLoading = false
      })
    },
    // Obtem historicos da empresa. //
    listHistoricals() {
      this.$axios.get(`/historicals/companies/${this.company.comp_id}`).then(success => {
        this.historicals = success.data.historicals
      })
    },
    // Imprime curriculo do candidato. //
    print() {
      this.$root.$emit('evt::close')
      setTimeout(() => {
        window.print()
      }, 1000)
    },
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>


<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>CADASTRO DE USUÁRIOS</v-card-title>
          <v-card-subtitle>PERMITA QUE OUTRAS PESSOAS ACESSEM SEU SISTEMA DE FORMA SEGURA E CONTROLADA</v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-form v-model="form.valid" ref="userForm" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.name" label="NOME COMPLETO" :rules="form.rules.name" counter="60" :disabled="loading" solo/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.email" type="email" label="E-MAIL SEGURO" :rules="form.rules.email" counter="255" :disabled="loading" solo/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.password" type="password" label="SENHA TEMPORARIA" :rules="form.rules.password" :disabled="loading" counter="20" solo/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.type" :items="form.types" item-text="name" item-value="code" return-object :disabled="loading" solo/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.tel" label="TELEFONE FIXO" counter="40" :disabled="loading" solo/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.cel" label="TELEFONE CELULAR" :disabled="loading" counter="40" solo/>
                </v-col>
                <v-col cols="12">
                  <v-alert v-model="errorAlert" border="left" close-text="Close Alert" color="error" dark dismissible>ERRO AO CADASTRAR USUÁRIOS!</v-alert>
                  <v-divider></v-divider>
                  <v-btn color="#662929" class="mt-4 btn-resize white--text" :loading="loading" @click="registryUser()">CADASTRAR USUÁRIO(A)</v-btn>
                  <v-btn color="#e98b0d" class="mt-4 btn-resize white--text" :disabled="loading" @click="cleanUserForm()">LIMPAR CAMPOS</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="3">
        <v-card dark>
          <v-card-title>INSTRUÇÕES</v-card-title>
          <v-divider></v-divider>
          <div class="pa-4">
            <p>ABCD</p>
          </div>
        </v-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NewUser',
  data() {
    return {
      loading: false,
      errorAlert: false,
      form: {
        valid: true,
        name: '',
        email: '',
        password: '',
        tel: '',
        cel: '',
        type: { name: 'COMERCIAL', code: 2 },
        types: [
          { name: 'COMERCIAL', code: 2 },
          { name: 'CONSULTOR(A)', code: 8 },
          { name: 'ADMINISTRATIVO', code: 4 },
        ],
        rules: {
          name: [
            v => !!v || 'Nome obrigatório.',
            v => v.length >= 4 || 'Nome não pode conter menos que 4 caracteres.',
            v => v.length <= 60 || 'Nome não pode conter mais que 60 caracteres.',
          ],
          email: [
            v => !!v || 'E-mail obrigatório.',
            v => /.+@.+/.test(v) || 'E-mail inválido.',
          ],
          password: [
            v => !!v || 'Senha obrigatória.',
            v => v.length >= 6 || 'Senha não pode conter menos que 6 caracteres.',
          ]
        }
      }
    }
  },
  created() {

  },
  methods: {

    // Limpa campos do formuário. //
    cleanUserForm() {
      this.$refs.userForm.reset()
      this.form.type = { name: 'COMERCIAL', code: 2 }
    },

    // Cadastro de novo usuário. //
    registryUser() {
      if (this.$refs.userForm.validate()) {
        this.loading = true
        this.$axios.post(`/users`, {
          name: this.form.name,
          email: this.form.email,
          password: this.form.password,
          type: this.form.type.code,
          tel: this.form.tel,
          cel: this.form.cel,
        }).then(success => {
          this.$router.push({ name: 'Users' })
        }).catch(err => {
          this.loading = false
          this.errorAlert = true
        })
      }
    }

  }
}
</script>

<style scoped>
  @media screen and (max-width: 600px) {
    .btn-resize {
      width: 100%;
    }
  }
  @media screen and (min-width: 601px) {
    .btn-resize {
      margin-left: 10px;
    }
  }
</style>


<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading" :disabled="loading">
          <v-card-title>FILTRO DE CANDIDATOS <span class="ml-1"> | {{ searched.length }}</span></v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-select v-model="filters.object.schooling" :items="filters.list.schoolings" item-text="scho_name" item-value="scho_id" label="ESCOLARIDADE" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-select v-model="filters.object.sex" label="SEXO" :items="filters.list.sex" item-text="name" item-value="code" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-autocomplete v-model="filters.object.city" chips multiple small-chips label="CIDADE" :items="filters.list.cities" item-text="cand_address_city" item-value="cand_address_city" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-select v-model="filters.object.zone" :disabled="false" label="ZONA" :items="filters.list.zones" item-text="name" item-value="code" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="filters.object.height" v-mask="`#.##`" label="ALTURA MINIMA (METROS)" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="filters.object.height2" v-mask="`#.##`" label="ALTURA MAXIMA (METROS)" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field v-model="filters.object.age" v-mask="`##`" label="IDADE MINIMA" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="2">
                <v-text-field v-model="filters.object.age2" v-mask="`##`" label="IDADE MAXIMA" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <v-text-field v-model="filters.object.exp" label="EXPERIENCIA" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete v-model="filters.object.occupation" label="PROFISSÃO" :items="filters.list.occupations" item-text="occu_name" item-value="occu_id" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-select v-model="filters.object.language" :items="filters.list.languages" item-text="lang_name" item-value="lang_id" label="IDIOMA" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-select v-model="filters.object.pcd" label="PCD" :items="filters.list.pcd" item-text="name" item-value="code" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-select v-model="filters.object.pcdType" :disabled="filters.object.pcd != 4" label="TIPO DE DEFICIENCIA" :items="filters.list.pcdTypes" item-text="name" item-value="code" filled/>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="filters.object.cpf" label="CPF SEM PONTUAÇÃO" v-mask="'###.###.###.##'" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-select v-model="filters.object.cnh" :disabled="false" label="POSSUI CNH" :items="filters.list.yes_not" item-text="name" item-value="code" filled/>
              </v-col>
              <v-col cols="12" sm="12" md="3">
                <v-select v-model="filters.object.vehicle" :disabled="filters.object.cnh != 4" label="POSSUI VEICULO" :items="filters.list.yes_not" item-text="name" item-value="code" filled/>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field v-model="filters.object.key" label="PALAVRA CHAVE DE CANDIDATO" filled />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-btn color="#662929" class="white--text" :loading="loading" :disabled="loading" @click="search()">BUSCAR FILTROS</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>NOME / EMAIL</th>
                      <th>CPF / RG</th>
                      <th>PROFISSÃO / CADASTRO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(candidade, index) in searched" :key="index" @click="openCand(candidade)">
                      <td>
                        <div>{{ candidade.cand_name }}</div>
                        <div>{{ candidade.cand_email }}</div>
                      </td>
                      <td>
                        <div>{{ candidade.cand_cpf }}</div>
                        <div>{{ candidade.cand_rg }}</div>
                      </td>
                      <td>
                        <div>{{ candidade.occu_name }}</div>
                        <div>{{ candidade.cand_created | birth }}</div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'SearchCandidade',
  data() {
    return {
      loading: false,
      filters: {
        object: {
          schooling: 0,
          age: 0,
          age2: 0,
          sex: 0,
          city: '',
          occupation: 0,
          language: 0,
          height: '0.0',
          height2: '0.0',
          exp: '',
          key: '',
          pcd: 0,
          pcdType: 0,
          cnh: 0,
          vehicle: 0,
          zone: 0,
        },
        list: {
          schoolings: [
            { scho_name: 'TODAS', scho_id: 0 },
          ],
          sex: [
            { name: 'TODOS', code: 0 },
            { name: 'MASCULINO', code: 2 },
            { name: 'FEMININO', code: 4 },
          ],
          cities: [],
          occupations: [
            { occu_name: 'TODAS', occu_id: 0 },
          ],
          pcd: [
            { name: 'TODOS', code: 0 },
            { name: 'COM DEFICIENCIA', code: 4 },
            { name: 'SEM DEFICIENCIA', code: 2 },
          ],
          pcdTypes: [
            { name: 'TODAS', code: 0 },
            { name: 'Auditiva', code: 2 },
            { name: 'Fisica', code: 4 },
            { name: 'Visual', code: 6 },
            { name: 'Multiplas', code: 8 },
            { name: 'Outra(s)', code: 10 }
          ],
          languages: [
            { lang_name: 'TODAS', lang_id: 0 }
          ],
          yes_not: [
            { name: 'TODOS', code: 0 },
            { name: 'NÃO', code: 2 },
            { name: 'SIM', code: 4 },
          ],
          zones: [
            { name: 'TODAS', code: 0 },
            { name: 'CENTRO', code: 'CENTRO' },
            { name: 'LESTE', code: 'LESTE' },
            { name: 'NORTE', code: 'NORTE' },
            { name: 'OESTE', code: 'OESTE' },
            { name: 'SUL', code: 'SUL' }
          ],
        },
      },
      query: '',
      multiQuery: false,
      searched: [],
    }
  },
  created() {
    this.getSchoolings()
    this.getOccupations()
    this.getCities()
    this.getLanguages()
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  },
  methods: {
    // Obtem escolaridades. //
    getSchoolings() {
      this.$axios.get(`/schoolings`).then(success => {
        this.filters.list.schoolings = this.filters.list.schoolings.concat(success.data.schoolings)
      })
    },
    // Obtem profissões. //
    getOccupations() {
      this.$axios.get(`/occupations`).then(success => {
        this.filters.list.occupations = this.filters.list.occupations.concat(success.data.occupations)
      })
    },
    // Obtem idiomas. //
    getLanguages() {
      this.$axios.get(`/languages`).then(success => {
        this.filters.list.languages = this.filters.list.languages.concat(success.data.languages)
      })
    },
    // Buscar filtros. //
    search() {
      this.searched = []
      this.query = '' // Reset query. //
      this.multiQuery = false
      if (this.filters.object.schooling) {
        this.query += `cand_schooling = ${this.filters.object.schooling}`
        this.multiQuery = true
      }
      if (this.filters.object.age2 >= 1) {
        if (this.multiQuery) { this.query += ` and cand_birth >= "${moment().subtract(this.filters.object.age2, 'years').format('YYYY')}-01-01"` }
        else { this.query += `cand_birth >= "${moment().subtract(this.filters.object.age2, 'years').format('YYYY')}-01-01"` }
        this.multiQuery = true
      }
      if (this.filters.object.age >= 1) {
        if (this.multiQuery) { this.query += ` and cand_birth <= "${moment().subtract(this.filters.object.age, 'years').format('YYYY')}-12-31"` }
        else { this.query += `cand_birth <= "${moment().subtract(this.filters.object.age, 'years').format('YYYY')}-12-31"` }
        this.multiQuery = true
      }
      if (this.filters.object.sex) {
        if (this.multiQuery) { this.query += ` and cand_sex = ${this.filters.object.sex}` }
        else { this.query += `cand_sex = ${this.filters.object.sex}` }
        this.multiQuery = true
      }
      if (this.filters.object.city) {
        if (this.multiQuery) { this.query += ` and cand_address_city in(${(JSON.stringify(this.filters.object.city).replace('[', '')).replace(']', '')})` }
        else { this.query += `cand_address_city in(${(JSON.stringify(this.filters.object.city).replace('[', '')).replace(']', '')})` }
        this.multiQuery = true
      }
      if (this.filters.object.occupation) {
        if (this.multiQuery) { this.query += ` and cand_occupation = ${this.filters.object.occupation}` }
        else { this.query += `cand_occupation = ${this.filters.object.occupation}` }
        this.multiQuery = true
      }
      if (this.filters.object.language) {
        if (this.multiQuery) { this.query += ` and (select 'LANG_OK' from nn_cand_lang where nncl_cand = cand_id and nncl_lang = ${this.filters.object.language} limit 1) = 'LANG_OK'` }
        else { this.query += `(select 'LANG_OK' from nn_cand_lang where nncl_cand = cand_id and nncl_lang = ${this.filters.object.language} limit 1) = 'LANG_OK'` }
        this.multiQuery = true
      }
      if (this.filters.object.pcd) {
        if (this.multiQuery) { this.query += ` and cand_deficient = ${this.filters.object.pcd}` }
        else { this.query += `cand_deficient = ${this.filters.object.pcd}` }
        this.multiQuery = true
      }
      if (this.filters.object.pcd && this.filters.object.pcdType != 0) {
        if (this.multiQuery) { this.query += ` and cand_deficient_type = ${this.filters.object.pcdType}` }
        else { this.query += `cand_deficient_type = ${this.filters.object.pcdType}` }
        this.multiQuery = true
      }
      if (this.filters.object.height >= 1) {
        if (this.multiQuery) { this.query += ` and cand_height >= "${this.filters.object.height}"` }
        else { this.query += `cand_height >= "${this.filters.object.height}"` }
        this.multiQuery = true
      }
      if (this.filters.object.height2 >= 1) {
        if (this.multiQuery) { this.query += ` and cand_height <= "${this.filters.object.height2}"` }
        else { this.query += `cand_height <= "${this.filters.object.height2}"` }
        this.multiQuery = true
      }
      if (this.filters.object.exp) {
        if (this.multiQuery) { this.query += ` and cand_exp like "%${this.filters.object.exp}%"` }
        else { this.query += `cand_exp like "%${this.filters.object.exp}%"` }
        this.multiQuery = true
      }
      if (this.filters.object.cpf) {
        if (this.multiQuery) { this.query += ` and cand_cpf like "%${this.filters.object.cpf}%"` }
        else { this.query += `cand_cpf like "%${this.filters.object.cpf}%"` }
        this.multiQuery = true
      }
      if (this.filters.object.cnh) {
        if (this.multiQuery) { this.query += ` and cand_cnh like "%${this.filters.object.cnh}%"` }
        else { this.query += `cand_cnh like "%${this.filters.object.cnh}%"` }
        this.multiQuery = true
      }
      if (this.filters.object.vehicle) {
        if (this.multiQuery) { this.query += ` and cand_vehicle like "%${this.filters.object.vehicle}%"` }
        else { this.query += `cand_vehicle like "%${this.filters.object.vehicle}%"` }
        this.multiQuery = true
      }
      if (this.filters.object.zone) {
        if (this.multiQuery) { this.query += ` and cand_address_zone like "${this.filters.object.zone}"` }
        else { this.query += `cand_address_zone like "${this.filters.object.zone}"` }
        this.multiQuery = true
      }
      if (this.filters.object.key) {
        if (this.multiQuery) { this.query += ` and (cand_education like "%${this.filters.object.key}%" or cand_description like "%${this.filters.object.key}%" or cand_name like "%${this.filters.object.key}%" or cand_email like "%${this.filters.object.key}%" or cand_cpf like "%${this.filters.object.key}%" or cand_rg like "%${this.filters.object.key}%")` }
        else { this.query += `(cand_education like "%${this.filters.object.key}%" or cand_description like "%${this.filters.object.key}%" or cand_name like "%${this.filters.object.key}%" or cand_email like "%${this.filters.object.key}%" or cand_cpf like "%${this.filters.object.key}%" or cand_rg like "%${this.filters.object.key}%")` }
        this.multiQuery = true
      }

      if (this.query) {
        this.loading = true
        this.$axios.post(`/candidates/search`, {
          query: this.query
        }).then(success => {
          this.searched = success.data.candidates
          this.loading = false
        })
      }
    },
    // Abre candidato. //
    openCand(cand) {
      if (this.user.type != 8) {
        let newRoute = this.$router.resolve({ path: `/dashboard/candidates/${cand.cand_code}` })
        window.open(newRoute.href, '_blank')
      } else {
        let newRoute = this.$router.resolve({ path: `/consultant/candidates/${cand.cand_code}` })
        window.open(newRoute.href, '_blank')
      }
    },
    // Obtem cidades. //
    getCities() {
      this.$axios.get(`/candidates/cities`).then(success => {
        this.filters.list.cities = success.data
      })
    },
  },
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>


<template>
  <v-container fluid>
    <v-row>
      <v-col cols='12'>
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>CURRÍCULO</v-card-title>
          <v-card-subtitle>MANTENHA SEMPRE SEUS DADOS ATUALIZADOS</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-form>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.email" label="E-MAIL *" filled/>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.password" label="SENHA *" filled/>
                </v-col> -->
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.name" label="NOME *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.cpf" label="CPF *" v-mask="'###.###.###.##'" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.rg" label="RG *" v-mask="'##.###.###-NN'" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.pis" label="NUMERO PIS (APENAS NUMEROS)" counter="11" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.naturalness" label="NATURALIDADE *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.nationality" label="NACIONALIDADE *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.birth2"
                        label="NASCIMENTO *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="form.birth"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.height" v-mask="`#.##`" label="ALTURA *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.sex" label="SEXO *" :items="sex" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.marital_status" label="ESTADO CIVIL *" :items="marital_status" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.address_cep" label="CEP *" v-mask="`########`" @blur="getCEP(form.address_cep)" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.address" label="LOGRADOURO *" disabled filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field v-model="form.address_number" label="NUMERO *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="7">
                  <v-text-field v-model="form.address_comp" label="COMPLEMENTO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select v-model="form.zone" label="ZONA" :items="zones" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.address_district" label="BAIRRO *" disabled filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.address_city" label="CIDADE *" disabled filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.address_state" label="ESTADO *" disabled filled/>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12" md="10">
                  <v-text-field v-model="form.tel1" v-mask="'(##) #########'" label="TELEFONE 1 *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-select v-model="form.tel1_wpp" label="WHATSAPP" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="10">
                  <v-text-field v-model="form.tel2" v-mask="'(##) #########'" label="TELEFONE 2" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-select v-model="form.tel2_wpp" label="WHATSAPP" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.schooling" label="ESCOLARIDADE *" :items="schoolings" item-text="scho_name" item-value="scho_id" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.occupation" label="OCUPAÇÃO *" :items="occupations" item-text="occu_name" item-value="occu_id" filled/>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="form.education" label="DESCRIÇÃO DA FORMAÇÃO" filled/>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" class="px-6">
                  <h2 class="pb-6">CURSOS</h2>
                  <v-row>
                    <v-col cols="12" sm="12" md="4" v-for="(info, index) in infos" :key="index" v-show="!verifyInfo(info.info_code)">
                      <v-switch v-if="!verifyInfo(info.info_code)" v-model="newInfos" :label="info.info_name" :value="info.info_id" inset/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <ul>
                        <li v-for="(info, index) in computings" :key="index" class="py-2">{{ info.info_name }} <v-icon color="error" @click="removeInfo(info.nnci_code)" small>fas fa-trash-alt</v-icon></li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" class="px-6">
                  <h2 class="pb-6">IDIOMAS</h2>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" v-for="(language, index) in languages" :key="index" v-show="!verifyLang(language.lang_code)">
                      <v-select v-model="language.level" :label="language.lang_name" :items="language_levels" item-text="name" item-value="code" filled/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <ul>
                        <li v-for="(language, index) in form.languages" :key="index" class="py-2">{{ language.lang_name }} ({{ language.nncl_level | language }}) <v-icon color="error" @click="removeLang(language.nncl_code)" small>fas fa-trash-alt</v-icon></li>
                      </ul>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.description" label="DESCRIÇÃO" filled></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.exp" label="EXPERIENCIA" filled></v-textarea>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.employee" label="EMPREGADO *" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.cnh" label="TEM CNH *" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.cnh_categories" :disabled="form.cnh == 2" label="CATEGORIAS *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.vehicle" label="TEM VEICULO *" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.salary" label="PRETENSÃO SALARIAL *" :items="salaries" item-text="sala_name" item-value="sala_id" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <!-- <v-text-field v-model="form.he_knew" label="COMO NOS CONHECEU?" filled/> -->
                  <v-select v-model="form.he_knew" :items="he_knew_list" item-text="name" item-value="code" label="COMO NOS CONHECEU?" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.deficient" label="DEFICIENTE *" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.deficient_apparatus" label="UTILIZA APARELHOS *" :items="yes_not" item-text="name" item-value="code" :disabled="form.deficient == 2" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.deficient_type" label="TIPO DE DEFICIENCIA" :items="deficient_type" item-text="name" item-value="code" :disabled="form.deficient == 2" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.cid" label="SE POSSUIR, INFORME O CID" filled/>
                </v-col>
                <v-col cols="12">
                  <hr class="mb-6">
                  <v-btn color="#662929" class="white--text mb-2" @click="update()">SALVAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import cep from 'cep-promise'

export default {
  name: 'CandidateProfile',
  data() {
    return {
      form: {
        email: '',
        password: '',
        cpf: '',
        rg: '',
        name: '',
        naturalness: '',
        nationality: '',
        birth: '',
        birth2: '',
        height: '0.0',
        sex: '',
        marital_status: '',
        address: '',
        address_number: '',
        address_comp: '',
        address_district: '',
        address_cep: '',
        address_state: '',
        address_city: '',
        tel1: '',
        tel1_wpp: 2,
        tel2: '',
        tel2_wpp: 2,
        schooling: 0,
        occupation: 0,
        description: '',
        exp: '',
        employee: 2,
        cnh: 2,
        cnh_categories: '',
        vehicle: 2,
        salary: '',
        he_knew: '',
        deficient: 2,
        deficient_apparatus: 2,
        deficient_type: 0,
        cid: '',
        zone: '',
        languages: [],
      },
      zones: [
        { name: 'CENTRO', code: 'CENTRO' },
        { name: 'LESTE', code: 'LESTE' },
        { name: 'NORTE', code: 'NORTE' },
        { name: 'OESTE', code: 'OESTE' },
        { name: 'SUL', code: 'SUL' }
      ],
      yes_not: [
        { name: 'NÃO', code: 2 },
        { name: 'SIM', code: 4 },
      ],
      sex: [
        { name: 'FEMININO', code: 4 },
        { name: 'MASCULINO', code: 2 },
      ],
      marital_status: [
        { name: 'Solteiro(a)', code: 2 },
        { name: 'Casado(a)', code: 4 },
        { name: 'Desquitado(a)', code: 6 },
        { name: 'Divorciado(a)', code: 8 },
        { name: 'Viuvo(a)', code: 10 },
        { name: 'Outro', code: 12 },
      ],
      he_knew_list: [
        { name: 'GOOGLE', code: 'Google' },
        { name: 'FACEBOOK', code: 'Facebook' },
        { name: 'LINKEDIN', code: 'Linkedin' },
        { name: 'WHATSAPP', code: 'Whatsapp' },
        { name: 'INDICAÇÃO', code: 'Indicação' },
        { name: 'OUTRO', code: 'Outro' },
      ],
      schoolings: [],
      occupations: [],
      salaries: [],
      infos: [],
      computings: [],
      newInfos: [],
      languages: [],
      candidateLangs: [],
      language_levels: [
        { name: 'Não conheço', code: 0 },
        { name: 'Básico', code: 2 },
        { name: 'Intermediario', code: 4 },
        { name: 'Fluente', code: 6 },
      ],
      deficient_type: [
        { name: 'Auditiva', code: 2 },
        { name: 'Fisica', code: 4 },
        { name: 'Visual', code: 6 },
        { name: 'Multiplas', code: 8 },
        { name: 'Outra(s)', code: 10 }
      ],
      loads: {
        dataNew: true
      },
      menu: false
    }
  },
  created() {
    this.getDataNew()
    this.getCandidate()
  },
  methods: {
    // Obtem dados auxiliares para cadastro. //
    getDataNew() {
      this.$axios.get(`/candidates/data/new`).then(success => {
        this.schoolings = success.data.schoolings
        this.occupations = success.data.occupations
        this.salaries = success.data.salaries
        this.infos = success.data.infos
        this.languages = success.data.languages
        this.loads.dataNew = false
      })
    },
    // Nascimento pickert. //
    save (date) {
      this.$refs.menu.save(date)
      this.form.birth2 = moment(date).utc.format(`DD/MM/YYYY`)
    },
    // Obtem dados candidato. //
    getCandidate() {
      this.$axios.get(`/candidates/profile`).then(success => {
        this.form.email = success.data.cand_email
        this.form.password = success.data.cand_password
        this.form.cpf = success.data.cand_cpf
        this.form.rg = success.data.cand_rg
        this.form.name = success.data.cand_name
        this.form.naturalness = success.data.cand_naturalness
        this.form.nationality = success.data.cand_nationality
        this.form.birth = moment(success.data.cand_birth).utc().format(`YYYY-MM-DD`)
        this.form.birth2 = moment(success.data.cand_birth).utc().format(`DD/MM/YYYY`)
        this.form.height = success.data.cand_height
        this.form.sex = success.data.cand_sex
        this.form.marital_status = success.data.cand_marital_status
        this.form.address = success.data.cand_address
        this.form.address_number = success.data.cand_address_number
        this.form.address_comp = success.data.cand_address_comp
        this.form.address_district = success.data.cand_address_district
        this.form.address_cep = success.data.cand_address_cep
        this.form.address_state = success.data.cand_address_state
        this.form.address_city = success.data.cand_address_city
        this.form.tel1 = success.data.cand_tel1
        this.form.tel1_wpp = success.data.cand_tel1_wpp
        this.form.tel2 = success.data.cand_tel2
        this.form.tel2_wpp = success.data.cand_tel2_wpp
        this.form.schooling = success.data.cand_schooling
        this.form.occupation = success.data.cand_occupation
        this.form.description = success.data.cand_description
        this.form.exp = success.data.cand_exp
        this.form.employee = success.data.cand_employee
        this.form.cnh = success.data.cand_cnh
        this.form.cnh_categories = success.data.cand_cnh_categories
        this.form.vehicle = success.data.cand_vehicle
        this.form.salary = success.data.cand_salary
        this.form.he_knew = success.data.cand_he_knew
        this.form.deficient = success.data.cand_deficient
        this.form.deficient_apparatus = success.data.cand_deficient_apparatus
        this.form.deficient_type = success.data.cand_deficient_type
        this.form.cid = success.data.cand_cid
        this.computings = success.data.infos
        this.candID = success.data.cand_id
        this.form.languages = success.data.languages
        this.form.education = success.data.cand_education
        this.form.pis = success.data.cand_pis
        this.form.zone = success.data.cand_address_zone
      })
    },
    // Edita candidato. //
    update() {
      this.$axios.put(`/candidates/profile`, {
        email: this.form.email,
        password: this.form.password,
        cpf: this.form.cpf,
        rg: this.form.rg,
        name: this.form.name,
        naturalness: this.form.naturalness,
        nationality: this.form.nationality,
        birth : this.form.birth,
        height: this.form.height,
        sex: this.form.sex,
        marital_status: this.form.marital_status,
        address: this.form.address,
        address_number: this.form.address_number,
        address_comp: this.form.address_comp,
        address_district: this.form.address_district,
        address_cep: this.form.address_cep,
        address_state: this.form.address_state,
        address_city: this.form.address_city,
        tel1: this.form.tel1,
        tel1_wpp: this.form.tel1_wpp,
        tel2: this.form.tel2,
        tel2_wpp: this.form.tel2_wpp,
        schooling: this.form.schooling,
        occupation: this.form.occupation,
        description: this.form.description,
        exp: this.form.exp,
        employee: this.form.employee,
        cnh: this.form.cnh,
        cnh_categories: this.form.cnh_categories,
        vehicle: this.form.vehicle,
        salary: this.form.salary,
        he_knew: this.form.he_knew,
        deficient: this.form.deficient,
        deficient_apparatus: this.form.deficient_apparatus,
        deficient_type: this.form.deficient_type,
        cid: this.form.cid,
        infos: this.newInfos,
        languages: this.languages,
        candID: this.candID,
        education: this.form.education,
        pis: this.form.pis,
        zone: this.form.zone,
      }).then(success => {
        location.reload()
      })
    },
    // Verifica se info já é cadastrada. //
    verifyInfo(info) {
      return this.computings.find(item => {
        return info == item.info_code
      })
    },
    // Verifica se idioma já é cadastrada. //
    verifyLang(lang) {
      return this.form.languages.find(item => {
        return lang == item.lang_code
      })
    },
    // Remove info. //
    removeInfo(code) {
      this.$axios.delete(`/candidates/profile/infos/${code}`).then(success => {
        location.reload()
      })
    },
    // Remove idioma. //
    removeLang(code) {
      this.$axios.delete(`/candidates/profile/languages/${code}`).then(success => {
        location.reload()
      })
    },
    // Obtem endereço através do CEP informado. //
    getCEP(code) {
      cep(code).then(success => {
        this.form.address = success.street
        this.form.address_district = success.neighborhood
        this.form.address_city = success.city
        this.form.address_state = success.state
      }).catch(err => {

      })
    },
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style scoped>

</style>

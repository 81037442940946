
import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/pt-br'

Vue.filter('userType', (type) => {
  switch(type) {
    case 0:
      return 'candidato'
    case 2:
      return 'comercial'
    case 4:
      return 'administrador'
    case 6:
      return 'desenvolvedor'
    case 8:
      return 'consultor'
    default:
      return 'ERRO!'
  }
})

Vue.filter('userState', (state) => {
  switch(state) {
    case 2:
      return 'bloqueado'
    case 4:
      return 'liberado'
    default:
      return 'ERRO!'
  }
})

Vue.filter('gpResolve', (state) => {
  switch(state) {
    case 2:
      return 'NÃO'
    case 4:
      return 'SIM'
    default:
      return 'ERRO!'
  }
})

Vue.filter('unblocked', (state) => {
  switch(state) {
    case 2:
      return 'NÃO'
    case 4:
      return 'SIM'
    default:
      return 'ERRO!'
  }
})

Vue.filter('contract', (state) => {
  switch(state) {
    case 2:
      return 'CONTRATO RECRUTAMENTO'
    case 4:
      return 'CONTRATO TEMPORARIO'
    default:
      return 'ERRO!'
  }
})

Vue.filter('sex', (state) => {
  switch(state) {
    case 2:
      return 'Masculino'
    case 4:
      return 'Feminino'
    case 6:
      return 'Todos'
    default:
      return 'ERRO!'
  }
})

Vue.filter('civil', (state) => {
  switch(state) {
    case 2:
      return 'Solteiro(a)'
    case 4:
      return 'Casado(a)'
    case 6:
      return 'Desquitado(a)'
    case 8:
      return 'Divorciado(a)'
    case 10:
      return 'Viuvo(a)'
    case 12:
      return 'Outro'
    case 14:
      return 'Todos'
    default:
      return 'ERRO!'
  }
})

Vue.filter('yes_not', (state) => {
  switch(state) {
    case 2:
      return 'NÃO'
    case 4:
      return 'SIM'
    case 6:
      return 'TODOS'
    default:
      return 'ERRO!'
  }
})

Vue.filter('language', (level) => {
  switch(level) {
    case 2:
      return 'Básico'
    case 4:
      return 'Intermediario'
    case 6:
      return 'Fluente'
    default:
      return 'ERRO!'
  }
})

Vue.filter('jobState', (state) => {
  switch(state) {
    case 2:
      return 'FECHADA'
    case 4:
      return 'ABERTA'
    case 6:
    default:
      return 'ERRO!'
  }
})

Vue.filter('jobType', (type) => {
  switch(type) {
    case 2:
      return 'EFETIVA'
    case 4:
      return 'ESTAGIO'
    case 6:
      return 'PJ/MEI'
    case 8:
      return 'TEMPORARIA'
    case 10:
      return 'TODOS'
    default:
      return 'ERRO!'
  }
})

Vue.filter('applyForStatus', (type) => {
  switch(type) {
    case 2:
      return 'PENDENTE'
    case 4:
      return 'SELECIONADO(A)'
    default:
      return 'ERRO!'
  }
})

Vue.filter('jobStatusCandidate', (type) => {
  switch(type) {
    case 4:
      return 'ABERTA'
    default:
      return 'FECHADA'
  }
})

Vue.filter('jobStatus', (type) => {
  switch(type) {
    case 2:
      return 'CANCELADA'
    case 4:
      return 'ABERTA'
    case 6:
      return 'FECHADA PELA CONCORRENCIA'
    case 8:
      return 'FECHADA PELA RESOLVE'
    case 10:
      return 'INDICAÇÃO'
    case 12:
      return 'SÓ ADMINISTRAÇÃO'
    default:
      return 'ERRO!'
  }
})

Vue.filter('profile', (type) => {
  switch(type) {
    case 2:
      return 'FORA DO PERFIL'
    case 4:
      return 'DENTRO DO PERFIL'
    default:
      return 'ERRO!'
  }
})

Vue.filter('jobSelection', (type) => {
  switch(type) {
    case 2:
      return 'NÃO SELECIONADO'
    case 4:
      return 'SELECIONADO'
    default:
      return 'ERRO!'
  }
})

Vue.filter('candidateProgress', (type) => {
  switch(type) {
    case 2:
      return 'INDEFINIDO'
    case 4:
      return 'INDISPONIVEL'
    case 6:
      return 'CV ENCAMINHADO'
    case 8:
      return 'NÃO TEVE INTERESSE'
    case 10:
      return 'PROCESSO ANTERIOR'
    case 12:
      return 'PRÓXIMO AO PERFIL'
    default:
      return 'ERRO!'
  }
})

Vue.filter('sector', (type) => {
  switch(type) {
    case 2:
      return 'DEPT. RH'
    case 4:
      return 'DEPT. COMERCIAL'
    case 6:
      return 'DIRETORIA'
    default:
      return 'NENHUM'
  }
})

Vue.filter('birth', (datetime) => {
  return moment(datetime).format('DD/MM/YYYY')
})

Vue.filter('birthutc', (datetime) => {
  return moment(datetime).utc().format('DD/MM/YYYY')
})

Vue.filter('datetime', (datetime) => {
  return moment(datetime).format('DD/MM/YYYY HH:mm:ss')
})

Vue.filter('cities', (value) => {
  return JSON.parse(value).join(', ')
})

Vue.filter('cities2', (value) => {
  return JSON.parse(value).toString().replace(',', ', ')
})

Vue.filter('deficientApparatus', (value) => {
  switch(value) {
    case 2:
      return 'NÃO'
    case 4:
      return 'SIM'
    default:
      return 'ERRO!'
  }
})

Vue.filter('deficientType', (value) => {
  switch(value) {
    case 2:
      return 'Auditiva'
    case 4:
      return 'Fisica'
    case 6:
      return 'Visual'
    case 8:
      return 'Multiplas'
    case 10:
      return 'Outra(s)'
    default:
      return 'ERRO!'
  }
})

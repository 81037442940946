
<template>
  <v-container fluid>
    <v-row>
      <v-col cols='12'>
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>SUAS VAGAS</v-card-title>
          <v-card-subtitle>ACOMPANHE AS VAGAS AS QUAIS VOCÊ CANDIDATOU-SE</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>OCUPAÇÃO</th>
                  <th>STATUS DA VAGA</th>
                  <th>IDENTIFICADOR DA VAGA / DATA DE INSCRIÇÃO NA VAGA</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(job, index) in jobs" :key="index">
                  <td>
                    <!-- <div>{{ job.bran_name }}</div> -->
                    <div>{{ job.occu_name }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_status | jobStatusCandidate }}</div>
                    <!--<div>{{ job.nncj_status | applyForStatus }}</div>-->
                  </td>
                  <td>
                    <div>{{ job.jobs_id }}</div>
                    <div>{{ job.nncj_created | birth }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CandidateJobs',
  data() {
    return {
      jobs: []
    }
  },
  created() {
    this.getJobs()
  },
  methods: {
    getJobs() {
      this.$axios.get(`/jobs/applyfor`).then(success => {
        this.jobs = success.data.jobs
      })
    }
  }
}
</script>

<style scoped>

</style>

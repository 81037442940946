
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>VOCÊ PESQUISOU "{{ $route.query.q }}" EM EMPRESAS</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME FANTASIA / RAZÃO SOCIAL</th>
                  <th>CNPJ / E-MAIL</th>
                  <th>RESPONSAVEL / CADASTRO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(company, index) in companies" :key="index" @click="openCompany(company)">
                  <td>
                    <div>{{ company.comp_fantasy }}</div>
                    <div>{{ company.comp_name }}</div>
                  </td>
                  <td>
                    <div>{{ company.comp_cnpj }}</div>
                    <div>{{ company.comp_email }}</div>
                  </td>
                  <td>
                    <div>{{ company.comp_responsible_name }}</div>
                    <div>{{ company.comp_created | birth }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SearchCompany',
  data() {
    return {
      companies: []
    }
  },
  created() {
    this.searchCompanies()
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  },
  methods: {
    // Obtem empresas. //
    searchCompanies() {
      this.$axios.get(`/companies/search?q=${this.$route.query.q}`).then(success => {
        this.companies = success.data.companies
      })
    },
    // Abre cadastro da empresa. //
    openCompany(company) {
      if (this.user.type != 8) {
        this.$router.push({ path: `/dashboard/companies/${company.comp_code}` })
      } else {
        this.$router.push({ path: `/consultant/companies/${company.comp_code}` })
      }
    },
  },
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>

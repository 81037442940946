
<template>
  <h1>CIDADES</h1>
</template>

<script>
export default {
  name: 'Cities',
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>


<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="9">
        <v-card>
          <v-card-title>PROSPECÇÃO DE CLIENTES</v-card-title>
          <v-card-subtitle>VISUALIZAÇÃO</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME / CNPJ</th>
                  <th>E-MAIL / IDENT.</th>
                  <th>TELEFONE 1 / TELEFONE 2</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(prospect, index) in prospects" :key="index" @click="$router.push({ name: 'ShowProspects', params: { id: prospect.pros_code } })">
                  <td>
                    <div>{{ prospect.pros_name || 'NÃO INFORMADO' }}</div>
                    <div>{{ prospect.pros_cnpj || 'NÃO INFORMADO' }}</div>
                  </td>
                  <td>
                    <div>{{ prospect.pros_email || 'NÃO INFORMADO' }}</div>
                    <div>ID: {{ prospect.pros_id }}</div>
                  </td>
                  <td>
                    <div>{{ prospect.pros_tel1 || 'NÃO INFORMADO' }}</div>
                    <div>{{ prospect.pros_tel2 || 'NÃO INFORMADO' }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-card>
          <v-card-title>AÇÕES</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-btn color="#662929" class="white--text" block @click="newProspect = true">CADASTRAR</v-btn>
          </div>
        </v-card>
      </v-col>
      
      <v-dialog v-model="newProspect" width="760" persistent>
        <v-card>
          <v-card-title>CADASTRO DE NOVA PROSPECÇÃO</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.name" label="RAZÃO SOCIAL" hide-details filled counter/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.fantasy" label="NOME FANTASIA" hide-details filled counter/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.cnpj" label="CNPJ" v-mask="`##.###.###/####-##`" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.ie" label="INSCRIÇÃO ESTADUAL" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.im" label="INSCRIÇÃO MUNICIPAL" hide-details filled counter/>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete v-model="form.branch" label="RAMO DE ATIVIDADE" :items="branches" item-text="bran_name" item-value="bran_id" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.resolve" label="CLIENTE RESOLVE" :items="yes_not" item-text="name" item-value="code" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.customer" label="NOME DO CONTATO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.email" label="E-MAIL DE CONTATO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.telephone1" label="TELEFONE 1" v-mask="`(##) #########`" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.wpp1" label="WHATSAPP?" :items="yes_not" item-text="name" item-value="code" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.telephone2" label="TELEFONE 2" v-mask="`(##) #########`" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.wpp2" label="WHATSAPP?" :items="yes_not" item-text="name" item-value="code" hide-details filled counter/>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="form.sector" label="SETOR DE CONTATO" :items="sectors" item-text="name" item-value="code" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.zipcode" label="CEP" v-mask="`########`" @blur="getCEP(form.zipcode)" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.address" label="LOGRADOURO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.number" label="NUMERO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.complement" label="COMPLEMENTO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.district" label="BAIRRO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.city" label="CIDADE" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.state" label="UF ESTADO" hide-details filled counter/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.description" label="DESCRIÇÃO" filled />
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-divider />
          <div>
            <v-btn class="ml-4 my-4" color="warning" @click="newProspect = false">FECHAR</v-btn>
            <v-btn color="#828a03" class="white--text ml-4 my-4" @click="save()">SALVAR</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>  
</template>

<script>
import cep from 'cep-promise'

export default {
  name: 'NewProspects',
  data() {
    return {
      loadingProspects: false,
      newProspect: false,
      form: {
        name: '',
        fantasy: '',
        cnpj: '',
        ie: '',
        im: '',
        branch: null,
        resolve: 4,
        customer: '',
        email: '',
        telephone1: '',
        wpp1: 2,
        telephone2: '',
        wpp2: 2,
        sector: null,
        zipcode: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        description: '',
      },
      sectors: [
        { name: 'NENHUM', code: null },
        { name: 'DEPTO. RH', code: 2 },
        { name: 'DEPTO. COMERCIAL', code: 4 },
        { name: 'DIRETORIA', code: 6 },
      ],
      yes_not: [
        { name: 'NÃO', code: 2 },
        { name: 'SIM', code: 4 },
      ],
      branches: [],
      prospects: [],
    }
  },
  created() {
    this.getDataNew()
    this.load()
  },
  methods: {
    // Obtem dados base. //
    getDataNew() {
      this.$axios.get(`/prospects/datanew`).then(success => {
        this.branches = success.data.branches
        this.branches.unshift({ bran_id: null, bran_name: 'NENHUM' })
      })
    },
    // Obtem endereço através do CEP informado. //
    getCEP(code) {
      cep(code).then(success => {
        this.form.address = success.street
        this.form.district = success.neighborhood
        this.form.city = success.city
        this.form.state = success.state
      })
    },
    // Registra nova prospecção. //
    save() {
      this.$axios.post(`/prospects`, {
        form: this.form
      }).then(success => {
        this.newProspect = false
        this.$router.push({ name: 'ShowProspects', params: { id: success.data.code } })
      })
    },
    // Lista as prospecções. //
    load() {
      this.$axios.get(`/prospects`).then(success => {
        this.prospects = success.data
      })
    },
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
</style>


<template>
  <v-row>
    <v-col cols="12" sm="12" md="4" v-for="(job, index) in jobs" :key="index">
      <v-card>
        <v-card-title><p class="text-truncate ma-0">{{ job.jobs_title }}</p></v-card-title>
        <v-card-subtitle >{{ job.jobs_created | birth }}</v-card-subtitle>
        <v-divider></v-divider>
        <div class="pa-4">
          <p class="mb-0"><strong>UNIDADE: </strong>{{ job.unit_name }}.</p>
          <p class="mb-0"><strong>QUANT. DE VAGAS: </strong>{{ job.jobs_qttd }}.</p>
          <p class="mb-0"><strong>IDENTIFICADOR: </strong>{{ job.jobs_id }}.</p>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="openJob(job.jobs_code)" text>Detalhes</v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn draggable="" icon @click="job.showDatails = !job.showDatails">
            <v-icon>{{ job.showDatails ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}</v-icon>
          </v-btn> -->
        </v-card-actions>
        <v-expand-transition>
          <div v-show="job.showDatails">
            <v-divider></v-divider>
            <div class="pa-4">
              <p><strong>GRAU DE ENSINO: </strong>{{ job.scho_name }}</p>
              <p><strong>RAMO DE ATIVIVIDADE: </strong>{{ job.bran_name }}</p>
              <p><strong>OCUPAÇÃO: </strong>{{ job.jobs_title }}</p>
              <p><strong>TIPO DE VAGA: </strong>{{ job.jobs_type | jobType }}</p>
              <p><strong>VAGA CADASTRADA: </strong>{{ job.jobs_created | birth }}</p>
            </div>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'IframeJobs',
  data() {
    return {
      jobs: []
    }
  },
  created() {
    this.getJobs()
  },
  methods: {
    getJobs() {
      this.$axios.get(`/home/jobs`).then(success => {
        this.jobs = success.data.map(item => {
          return {
            ...item,
            showDatails: false
          }
        })
      })
    },
    openJob(job) {
      const obj = this.$router.resolve({ path: `/jobs/${job}` })
      window.open(obj.href, '_blank')
    }
  },
}
</script>

<style>
  /* html {
    overflow: hidden;
  } */
  main {
    background-color: #f6f6f6 !important;
    /* background-color: #f6f6f6 !important; */
  }
</style>

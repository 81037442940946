
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>CADASTRO DE CANDIDATO</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-form>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.email" label="E-MAIL *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.password" label="SENHA *" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.name" label="NOME *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.cpf" label="CPF *" v-mask="'###.###.###.##'" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="form.rg" label="RG *" v-mask="'##.###.###-NN'" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.naturalness" label="NATURALIDADE *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.nationality" label="NACIONALIDADE *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.birth2"
                        label="NASCIMENTO *"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="form.birth"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.height" v-mask="`#.##`" label="ALTURA *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.sex" label="GENERO *" :items="sex" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.marital_status" label="ESTADO CIVIL *" :items="marital_status" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.address_cep" label="CEP *" v-mask="`########`" @blur="getCEP(form.address_cep)" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-text-field v-model="form.address" label="LOGRADOURO *" disabled filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-text-field v-model="form.address_number" label="NUMERO *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="10">
                  <v-text-field v-model="form.address_comp" label="COMPLEMENTO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.address_district" label="BAIRRO *" disabled filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.address_city" label="CIDADE *" disabled filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.address_state" label="ESTADO *" disabled filled/>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12" md="10">
                  <v-text-field v-model="form.tel1" v-mask="'(##) #########'" label="TELEFONE 1 *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-select v-model="form.tel1_wpp" label="WHATSAPP" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="10">
                  <v-text-field v-model="form.tel2" v-mask="'(##) #########'" label="TELEFONE 2" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-select v-model="form.tel2_wpp" label="WHATSAPP" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="form.schooling" label="ESCOLARIDADE *" :items="schoolings" item-text="scho_name" item-value="scho_id" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <!-- <v-select v-model="form.occupation" label="PROFISSÃO *" :items="occupations" item-text="occu_name" item-value="occu_id" filled/> -->
                  <v-autocomplete v-model="form.occupation" label="PROFISSÃO *" :items="occupations" item-text="occu_name" item-value="occu_id" filled/>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" class="px-8">
                  <h2 class="mb-6">INFORMATICA</h2>
                  <v-row>
                    <v-col cols="12" sm="12" md="4" v-for="(info, index) in infos" :key="index">
                      <v-switch v-model="computings" :label="info.info_name" :value="info.info_id" inset/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" class="px-8">
                  <h2 class="mb-6">IDIOMAS</h2>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" v-for="(language, index) in languages" :key="index">
                      <v-row>
                        <!-- <v-col cols="12" sm="6">
                          <v-switch v-model="form.languages" :label="language.lang_name" :value="language.lang_id" inset/>
                        </v-col> -->
                        <v-col cols="12" sm="12">
                          <v-select v-model="language.level" :label="language.lang_name" :items="language_levels" item-text="name" item-value="code" filled/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.description" label="DESCRIÇÃO" filled></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea v-model="form.exp" label="EXPERIENCIA" filled></v-textarea>
                </v-col>
                <v-col cols="12"><v-divider class="mb-6"/></v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.employee" label="EMPREGADO *" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.cnh" label="TEM CNH *" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.cnh_categories" :disabled="form.cnh == 2" label="CATEGORIAS *" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.vehicle" label="TEM VEICULO *" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.salary" label="PRETENSÃO SALARIAL *" :items="salaries" item-text="sala_name" item-value="sala_id" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.he_knew" label="COMO NOS CONHECEU?" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.deficient" label="DEFICIENTE *" :items="yes_not" item-text="name" item-value="code" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.deficient_apparatus" label="UTILIZA APARELHOS *" :items="yes_not" item-text="name" item-value="code" :disabled="form.deficient == 2" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.deficient_type" label="TIPO DE DEFICIENCIA" :items="deficient_type" item-text="name" item-value="code" :disabled="form.deficient == 2" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="form.cid" label="SE POSSUIR, INFORME O CID" filled/>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-switch v-model="terms" label="ACEITAR TERMOS DE USO E POLITICA DE PRIVACIDADE"/>
                </v-col>
                <v-col cols="12">
                  <hr class="mb-6">
                  <v-btn color="#662929" class="white--text mb-2" :disabled="!terms" @click="registry()">CADASTRAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cep from 'cep-promise'
import moment from 'moment'

export default {
  name: 'NewCandidate',
  data() {
    return {
      form: {
        email: '',
        password: '',
        cpf: '',
        rg: '',
        name: '',
        naturalness: '',
        nationality: '',
        birth: '',
        birth2: '',
        height: '0.0',
        sex: '',
        marital_status: '',
        address: '',
        address_number: '',
        address_comp: '',
        address_district: '',
        address_cep: '',
        address_state: '',
        address_city: '',
        tel1: '',
        tel1_wpp: 2,
        tel2: '',
        tel2_wpp: 2,
        schooling: 0,
        occupation: 0,
        description: '',
        exp: '',
        employee: 2,
        cnh: 2,
        cnh_categories: '',
        vehicle: 2,
        salary: '',
        he_knew: '',
        deficient: 2,
        deficient_apparatus: 2,
        deficient_type: 0,
        cid: '',
        languages: [],
      },
      yes_not: [
        { name: 'NÃO', code: 2 },
        { name: 'SIM', code: 4 },
      ],
      sex: [
        { name: 'FEMININO', code: 4 },
        { name: 'MASCULINO', code: 2 },
      ],
      marital_status: [
        { name: 'Solteiro(a)', code: 2 },
        { name: 'Casado(a)', code: 4 },
        { name: 'Desquitado(a)', code: 6 },
        { name: 'Divorciado(a)', code: 8 },
        { name: 'Viuvo(a)', code: 10 },
        { name: 'Outro', code: 12 },
      ],
      schoolings: [],
      occupations: [],
      salaries: [],
      infos: [],
      languages: [],
      computings: [],
      deficient_type: [
        { name: 'Auditiva', code: 2 },
        { name: 'Fisica', code: 4 },
        { name: 'Visual', code: 6 },
        { name: 'Multiplas', code: 8 },
        { name: 'Outra(s)', code: 10 }
      ],
      language_levels: [
        { name: 'Não possui', code: 0 },
        { name: 'Básico', code: 2 },
        { name: 'Intermediario', code: 4 },
        { name: 'Fluente', code: 6 },
      ],
      loads: {
        dataNew: true
      },
      menu: false,
      terms: false,
    }
  },
  created() {
    this.getDataNew()
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    // Obtem dados auxiliares para cadastro. //
    getDataNew() {
      this.$axios.get(`/candidates/data/new`).then(success => {
        this.schoolings = success.data.schoolings
        this.occupations = success.data.occupations
        this.salaries = success.data.salaries
        this.infos = success.data.infos
        this.languages = success.data.languages.map(item => {
          return {
            ...item,
            level: 0
          }
        })
        this.loads.dataNew = false
      })
    },
    // Nascimento pickert. //
    save (date) {
      this.form.birth2 = moment(date).format('DD/MM/YYYY')
      this.$refs.menu.save(date)
    },
    // Obtem endereço através do CEP informado. //
    getCEP(code) {
      cep(code).then(success => {
        this.form.address = success.street
        this.form.address_district = success.neighborhood
        this.form.address_city = success.city
        this.form.address_state = success.state
      }).catch(err => {

      })
    },
    // Cadastra candidato. //
    registry() {
      this.$axios.post(`/candidates`, {
        email: this.form.email,
        password: this.form.password,
        cpf: this.form.cpf,
        rg: this.form.rg,
        name: this.form.name,
        naturalness: this.form.naturalness,
        nationality: this.form.nationality,
        birth : this.form.birth,
        height: this.form.height,
        sex: this.form.sex,
        marital_status: this.form.marital_status,
        address: this.form.address,
        address_number: this.form.address_number,
        address_comp: this.form.address_comp,
        address_district: this.form.address_district,
        address_cep: this.form.address_cep,
        address_state: this.form.address_state,
        address_city: this.form.address_city,
        tel1: this.form.tel1,
        tel1_wpp: this.form.tel1_wpp,
        tel2: this.form.tel2,
        tel2_wpp: this.form.tel2_wpp,
        schooling: this.form.schooling,
        occupation: this.form.occupation,
        description: this.form.description,
        exp: this.form.exp,
        employee: this.form.employee,
        cnh: this.form.cnh,
        cnh_categories: this.form.cnh_categories,
        vehicle: this.form.vehicle,
        salary: this.form.salary,
        he_knew: this.form.he_knew,
        deficient: this.form.deficient,
        deficient_apparatus: this.form.deficient_apparatus,
        deficient_type: this.form.deficient_type,
        cid: this.form.cid,
        infos: this.computings,
        languages: this.languages
      }).then(success => {
        this.$router.push({ path: `/dashboard/candidates/${success.data.code}` })
      })
    }
  }
}
</script>

<style scoped>

</style>

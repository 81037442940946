
<template>
  <v-row>
    <v-col cols="12" xs="12" sm="6" md="3">
      <v-card class="bottom-cards" elevation="16" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-1">
          <v-icon class="icon-link" @click="$router.push({ name: 'Units' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.units }}
        </div>
        <div class="card-title">UNIDADES</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3">
      <v-card class="bottom-cards" elevation="16" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-1">
          <v-icon class="icon-link" @click="$router.push({ name: 'Branches' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.branches }}
        </div>
        <div class="card-title">ATIVIDADES</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3">
      <v-card class="bottom-cards" elevation="3" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-1">
          <v-icon class="icon-link" @click="$router.push({ name: 'Professions' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.occupations }}
        </div>
        <div class="card-title">PROFISSÕES</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3">
      <v-card class="bottom-cards" elevation="16" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-1">
          <v-icon class="icon-link" @click="$router.push({ name: 'Languages' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.languages }}
        </div>
        <div class="card-title">IDIOMAS</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3">
      <v-card class="bottom-cards" elevation="16" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-1">
          <v-icon class="icon-link" @click="$router.push({ name: 'Salary' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.salaries }}
        </div>
        <div class="card-title">SALARIOS</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3">
      <v-card class="bottom-cards" elevation="16" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-1">
          <v-icon class="icon-link" @click="$router.push({ name: 'Computing' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.infos }}
        </div>
        <div class="card-title">INFORMATICA</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3">
      <v-card class="bottom-cards" elevation="16" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-1">
          <v-icon class="icon-link" @click="$router.push({ name: 'Benefits' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.benefits }}
        </div>
        <div class="card-title">BENEFICIOS</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="3">
      <v-card class="bottom-cards" elevation="16" :loading="loading">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-1">
          <v-icon class="icon-link" @click="$router.push({ name: 'Schooling' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.schoolings }}
        </div>
        <div class="card-title">ESCOLARIDADES</div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ResumeSmallCards',
  data() {
    return {
      loading: true,
      registries: {
        "units": 0,
        "branches": 0,
        "occupations": 0,
        "languages": 0,
        "salaries": 0,
        "infos": 0,
        "benefits": 0,
        "schoolings": 0
      }
    }
  },
  created() {
    this.$axios.get(`/dashboard/bottom-cards`).then(success => {
      this.registries = success.data
      this.loading = false
    })
  }
}
</script>

<style scoped>
  .bottom-cards {
    border-bottom: 6px solid #662929 !important;
  }
  .bottom-cards .icon-link {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #dddddd;
    cursor: pointer;
  }
  .bottom-cards .card-value {
    font-size: 2em;
    color: #dddddd;
    background-color: #662929;
    text-align: center;
  }
  .bottom-cards .card-title {
    color: #662929;
    background-color: #dddddd;
    text-align: center;
  }
</style>


<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>EDIÇÃO DE VAGA</v-card-title>
          <v-card-subtitle>{{ form.comp_name }} - {{ form.comp_cnpj }}</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-form>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field v-model="form.jobs_age_min" label="IDADE MINIMA *" type="number" min="0" max="99" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field v-model="form.jobs_age_max" label="IDADE MÁXIMA *" type="number" min="1" max="100" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field v-model="form.jobs_height_min" label="ALTURA MINIMA *" v-mask="'#.##'" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field v-model="form.jobs_height_max" label="ALTURA MAXIMA *" v-mask="'#.##'" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <v-select v-model="form.jobs_sex" :items="sex" item-text="name" item-value="code" label="SEXO" filled/>
                </v-col>
                <!--<v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_state" label="ESTADO" :items="states" item-text="fullName" item-value="sigla" @change="getCitiesByUF()" disabled filled/>
                </v-col>-->
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete v-model="form.jobs_city" :items="cities" item-text="nome" item-value="nome" label="CIDADE" multiple chips single-line filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_schooling" :items="schoolings" item-text="scho_name" item-value="scho_id" label="GRAU DE ENSINO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_occupation" :items="occupations" item-text="occu_name" item-value="occu_id" label="PROFISSÃO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_branch" :items="branches" item-text="bran_name" item-value="bran_id" label="RAMO DE ATIVIDADE" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_type" :items="job_types" item-text="name" item-value="code" label="TIPO DE VAGA" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field v-model="form.jobs_title" label="TITULO DA VAGA" filled/>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="form.jobs_qttd" type="number" label="QUANTIDADE DE VAGAS" filled/>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="form.jobs_office_exercised" label="CARGO JÁ EXERCIDO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_marital_status" :items="marital_status" item-text="name" item-value="code" label="ESTADO CIVIL" filled/>
                </v-col>
                <v-col cols="12"><v-divider/></v-col>
                <v-col cols="12">
                  <h2 class="mb-6">INFORMATICA</h2>
                  <v-row class="ml-3">
                    <v-col cols="12" sm="12" md="4" v-for="(info, index) in computings" :key="index" v-show="!verifyInfo(info.info_code)">
                      <v-switch v-if="!verifyInfo(info.info_code)" v-model="newInfos" :label="info.info_name" :value="info.info_id" inset/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider/></v-col>
                <v-col cols="12">
                  <h2 class="mb-6">IDIOMAS</h2>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" v-for="(language, index) in languages" :key="index" v-show="!verifyLang(language.control)">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-select v-model="language.level" :label="language.name" :items="language_levels" item-text="name" item-value="code" filled/>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12"><v-divider/></v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select v-model="form.jobs_employee" :items="yes_not" item-text="name" item-value="code" label="EMPREGADO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select v-model="form.jobs_cnh" :items="yes_not" item-text="name" item-value="code" label="HABILITADO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-text-field v-model="form.jobs_cnh_categories" label="CATEGORIAS" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-select v-model="form.jobs_vehicle" :items="yes_not" item-text="name" item-value="code" label="TEM VEICULO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_unit" :items="units" item-text="unit_name" item-value="unit_id" label="UNIDADE" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.jobs_salary" label="SALARIO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field v-model="form.jobs_schedules" label="HORARIOS DE TRABALHO" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p><strong>DATA DE INICIO</strong></p>
                  <v-date-picker v-model="form.jobs_start" full-width/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p><strong>DATA DE FIM</strong></p>
                  <v-date-picker v-model="form.jobs_end" full-width/>
                </v-col>
                <v-col cols="12" sm="12" md="8">
                  <v-select v-model="form.jobs_consultant" :items="users" item-text="user_name" item-value="user_id" label="CONSULTOR" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select v-model="form.jobs_deficient" :items="yes_not" item-text="name" item-value="code" label="PCD" filled/>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea v-model="form.jobs_description" label="DESCRIÇÃO DA VAGA" filled></v-textarea>
                </v-col>
                <v-col cols="12">
                  <hr class="mb-6">
                  <v-btn color="#662929" class="white--text" @click="save()">SALVAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ConsultantEditJob',
  props: [ 'id', 'code' ],
  data() {
    return {
      job: {},
      schoolings: [],
      occupations: [],
      branches: [],
      units: [],
      users: [],
      infos: [],
      computings: [],
      languages: [],
      newInfos: [],
      loads: {
        company: true,
        schoolings: true,
        occupations: true,
        branches: true,
        units: true,
        consultants: true,
        states: true,
        cities: false
      },
      form: {
        jobs_age_min: 0,
        jobs_age_max: 100,
        jobs_sex: 6,
        jobs_state: 'SP',
        jobs_city: '',
        jobs_marital_status: 14,
        jobs_status: 4,
        jobs_schooling: '',
        jobs_occupation: '',
        jobs_branch: '',
        jobs_type: 0,
        jobs_office_exercised: '',
        jobs_employee: 6,
        jobs_cnh: 6,
        jobs_cnh_categories: '',
        jobs_vehicle: 6,
        jobs_unit: '',
        jobs_start: moment().format('YYYY-MM-DD'),
        jobs_end: moment().add(1, 'month').format('YYYY-MM-DD'),
        jobs_consultant: '',
        jobs_type: '',
        jobs_deficient: 6,
        jobs_salary: '',
        jobs_description: '',
        jobs_schedules: '',
        jobs_qttd: '',
        jobs_height_min: '',
        jobs_height_max: '',
        jobs_title: '',
      },
      yes_not: [
        { name: 'SIM', code: 4 },
        { name: 'NÃO', code: 2 },
        { name: 'TODOS', code: 6 },
      ],
      sex: [
        { name: 'TODOS', code: 6 },
        { name: 'FEMININO', code: 4 },
        { name: 'MASCULINO', code: 2 },
      ],
      marital_status: [
        { name: 'Solteiro(a)', code: 2 },
        { name: 'Casado(a)', code: 4 },
        { name: 'Desquitado(a)', code: 6 },
        { name: 'Divorciado(a)', code: 8 },
        { name: 'Viuvo(a)', code: 10 },
        { name: 'Outro', code: 12 },
        { name: 'Todos', code: 14 },
      ],
      job_types: [
        { name: 'Efetiva', code: 2 },
        { name: 'Estágio', code: 4 },
        { name: 'PJ/MEI', code: 6 },
        { name: 'Temporaria', code: 8 },
      ],
      language_levels: [
        { name: 'Não exigido', code: 0 },
        { name: 'Básico', code: 2 },
        { name: 'Intermediario', code: 4 },
        { name: 'Fluente', code: 6 },
      ],
      states: [],
      cities: []
    }
  },
  created() {
    this.getJob()
    this.getDataNew()
    this.getStatesFromIBGE()
    this.getCitiesByUF()
  },
  methods: {
    // Obter dados da empresa. //
    getJob() {
      this.$axios.get(`/jobs/${this.code}`).then(success => {
        this.form = success.data
        this.form.jobs_start = moment(success.data.jobs_start).format('YYYY-MM-DD')
        this.form.jobs_end = moment(success.data.jobs_end).format('YYYY-MM-DD')
        this.form.jobs_city = JSON.parse(this.form.jobs_city)
      })
    },
    // Obtem dados auxiliares para cadastro. //
    getDataNew() {
      this.$axios.get(`/jobs/data/new`).then(success => {
        this.branches = success.data.branches
        this.occupations = success.data.occupations
        this.schoolings = success.data.schoolings
        this.units = success.data.units
        this.users = success.data.users
        this.computings = success.data.infos
        this.languages = success.data.languages.map(item => {
          return {
            name: item.lang_name,
            code: item.lang_id,
            control: item.lang_code,
            level: 0
          }
        })
      })
    },
    // Obtem estados do Brasil. //
    getStatesFromIBGE() {
      this.$axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`).then(success => {
        this.states = success.data.map(item => {
          item.fullName = `${item.sigla} - ${item.nome}`
          return item
        })
      })
    },
    // Obtem cidades por estado. //
    getCitiesByUF() {
      this.$axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.form.jobs_state}/distritos`).then(success => {
        this.cities = success.data
      })
    },
    // Salva edição. //
    save() {
      this.$axios.put(`/jobs/${this.code}`, {
        ...this.form,
        computings: this.newInfos,
        languages: this.languages,
        jobID: this.id,
        jobs_city: JSON.stringify(this.form.jobs_city)
      }).then(success => {
        this.$router.push({ path: `/consultant/jobs/${this.code}` })
      })
    },
    // Verifica se info já é cadastrada. //
    verifyInfo(info) {
      return this.form.computings.find(item => {
        return info == item.info_code
      })
    },
    // Verifica se idioma já é cadastrada. //
    verifyLang(lang) {
      return this.form.languages.find(item => {
        return lang == item.lang_code
      })
    }
  }
}
</script>

<style scoped>

</style>

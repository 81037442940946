<template>
  <v-container fluid class="pa-0">

    <v-app-bar v-show="!$vuetify.breakpoint.mobile" inverted-scroll app>
      <img src="@/assets/gpr-logo.png" height="60" class="ml-10 py-2" alt="logo-grupo-resolve">
      <v-spacer></v-spacer>
      <v-btn color="#662929" class="mr-4 whitegr" href="https://vagas.gruporesolve.com.br"><v-icon>fas fa-home</v-icon></v-btn>
      <template v-if="user.id">
        <v-btn v-if="user.type == 0" color="#662929" @click="$router.push({ path: `/candidate` })" class="whitegr">PAINEL DE "{{ user.name }}"</v-btn>
        <v-btn v-else-if="user.type == 8" color="#662929" @click="$router.push({ path: `/consultant` })" class="whitegr">PAINEL DE "{{ user.name }}"</v-btn>
        <v-btn v-else color="#662929" class="whitegr" @click="$router.push({ path: `/dashboard` })">PAINEL DE "{{ user.name }}"</v-btn>
      </template>
      <template v-else>
        <v-btn color="#662929" class="white--text mr-4" @click="$router.push({ name: `Registry` })">CADASTRAR-SE</v-btn>
        <v-btn color="#662929" class="white--text" @click="$router.push({ name: `Login` })">FAZER LOGIN</v-btn>
      </template>
    </v-app-bar>

    <v-parallax height="540" src="@/assets/homeParallax.jpg">
      <img v-if="!$vuetify.breakpoint.mobile" src="@/assets/gpr-logo-white.png" alt="logo-grupo-resolve" class="logo-gpr">
      <img v-else src="@/assets/gpr-logo-white.png" alt="logo-grupo-resolve" class="logo-mob">
      <template v-if="user.id">
        <v-btn v-if="user.type == 0" v-show="!$vuetify.breakpoint.mobile" absolute top right large class="mt-8 mr-10" @click="$router.push({ path: `/candidate` })">PAINEL DE {{ user.name }}</v-btn>
        <v-btn v-else-if="user.type == 8" v-show="!$vuetify.breakpoint.mobile" absolute top right large class="mt-8 mr-10" @click="$router.push({ path: `/consultant` })">PAINEL DE {{ user.name }}</v-btn>
        <v-btn v-else v-show="!$vuetify.breakpoint.mobile" absolute top right large class="mt-8 mr-10" @click="$router.push({ path: `/dashboard` })">PAINEL DE "{{ user.name }}"</v-btn>
      </template>
      <template v-else>
        <v-btn v-show="!$vuetify.breakpoint.mobile" absolute top right large class="mt-8 mr-10" style="right: 170px;" @click="$router.push({ name: `Registry` })">CADASTRAR-SE</v-btn>
        <v-btn v-show="!$vuetify.breakpoint.mobile" absolute top right large class="mt-8 mr-10" @click="$router.push({ name: `Login` })">FAZER LOGIN</v-btn>
      </template>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h1 v-if="!$vuetify.breakpoint.mobile" class="text-center text-h1 font-weight-thin">Sua oportunidade está aqui!</h1>
          <h1 v-else class="text-center text-h2 font-weight-thin">Sua oportunidade está aqui!</h1>
        </v-col>
      </v-row>
      <template v-if="user.id">
        <v-btn v-if="user.type == 0" v-show="$vuetify.breakpoint.mobile" absolute bottom large class="mobile-btn" @click="$router.push({ path: `/candidate` })">PAINEL DE {{ user.name }}</v-btn>
        <v-btn v-else-if="user.type == 8" v-show="$vuetify.breakpoint.mobile" absolute bottom large class="mobile-btn" @click="$router.push({ path: `/consultant` })">PAINEL DE {{ user.name }}</v-btn>
        <v-btn v-else v-show="$vuetify.breakpoint.mobile" absolute bottom large class="mobile-btn" @click="$router.push({ path: `/dashboard` })">PAINEL DE "{{ user.name }}"</v-btn>
      </template>
      <template v-else>
        <v-btn v-show="$vuetify.breakpoint.mobile" absolute bottom large class="mobile-btn" @click="$router.push({ name: `Login` })">FAZER LOGIN</v-btn>
      </template>
    </v-parallax>

    <v-container class="my-10">
      <v-row>
        <v-col cols="12" class="mb-3">
          <v-card :loading="searchLoading">
            <v-row class="pa-4">
              <v-col cols="12" md="4">
                <v-text-field label="BUSCAR PELO IDENTIFICADOR DA VAGA (EX: 1234)" :loading="searchLoading" :disabled="searchLoading" v-mask="'##########'" v-model="searchID" hide-details filled/>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="occupation" :loading="searchLoading" :disabled="searchLoading || searchID.length > 0" :items="occupations" item-text="name" item-value="code" return-object filled hide-details label="OCUPAÇÕES" />
              </v-col>
              <v-col cols="12" md="2">
                <v-btn block style="height: 100%" class="white--text" :loading="searchLoading" color="#662929" @click="searchJobs()">BUSCAR</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col v-if="searchLoading == false && jobs.length < 1" cols="12">
              <h1 class="text-center" block>NÃO FORAM ENCONTRADOS RESULTADOS PARA SUA BUSCA</h1>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4" v-for="(job, index) in jobs" :key="index">
              <v-card>
                <v-card-title><p class="ma-0 text-truncate">{{ job.jobs_title }}</p></v-card-title>
                <v-card-subtitle >{{ job.jobs_created | birth }}</v-card-subtitle>
                <v-divider></v-divider>
                <div class="pa-4">
                  <p class="mb-0"><strong>UNIDADE: </strong>{{ job.unit_name }}.</p>
                   <p class="mb-0"><strong>QUANT. DE VAGAS: </strong>{{ job.jobs_qttd }}.</p>
                  <p class="mb-0"><strong>IDENTIFICADOR: </strong>{{ job.jobs_id }}.</p>
                </div>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="#662929" @click="openJob(job.jobs_code)" text>QUERO ME CANDIDATAR</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn draggable="" icon @click="job.showDatails = !job.showDatails">
                    <v-icon>{{ job.showDatails ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <div v-show="job.showDatails">
                    <v-divider></v-divider>
                    <div class="pa-4">
                      <p><strong>GRAU DE ENSINO: </strong>{{ job.scho_name }}</p>
                      <p><strong>RAMO DE ATIVIVIDADE: </strong>{{ job.bran_name }}</p>
                      <p><strong>OCUPAÇÃO: </strong>{{ job.jobs_title }}</p>
                      <p><strong>TIPO DE VAGA: </strong>{{ job.jobs_type | jobType }}</p>
                      <p><strong>VAGA CADASTRADA: </strong>{{ job.jobs_created | birth }}</p>
                    </div>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      jobs: [],
      searchID: '',
      occupation: 0,
      occupations: [
        { name: 'TODAS', code: 0 }
      ],
      searchLoading: false,
    }
  },
  created() {
    this.getDataNew()
    this.getJobs()
  },
  methods: {
    // Obtem dados base. //
    getDataNew() {
      this.$axios.get(`/home/datanew`).then(success => {
        this.occupations = this.occupations.concat(success.data.occupations)
      })
    },
    // Obtem vagas ativas. //
    getJobs() {
      this.$axios.get(`/home/jobs`).then(success => {
        this.jobs = success.data.map(item => {
          return {
            ...item,
            showDatails: false
          }
        })
      })
    },
    // Buscar vagas. //
    searchJobs() {
      this.searchLoading = true
      let query
      if (this.searchID) {
        query = `jobs_id = ${this.searchID}`
      } else if (this.occupation != 0) {
        query = `jobs_occupation = ${this.occupation.code}`
      }
      else { this.searchLoading = false; return }
      this.jobs = []
      this.$axios.post(`/home/search`, { query }).then(success => {
        this.jobs = success.data
        this.searchLoading = false
      })
    },
    // Abrir vaga. //
    openJob(job) {
      const jobURI = this.$router.resolve({ path: `/jobs/${job}` })
      window.open(jobURI.href, '_blank')
    }
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style scoped>
  .logo-gpr {
    position: absolute;
    top: 40px;
    left: 60px;
    width: 300px;
  }
  .logo-mob {
    position: absolute;
    top: 40px;
    width: 300px;
    left: 50%;
    margin-left: -150px;
  }
  .mobile-btn {
    width: 90vw;
    left: 5vw;
  }
</style>

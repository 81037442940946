
<template>
  <v-container>
    <v-row>
      <v-col cols=12 sm=12 md="12">
        <v-card class="bottom-detail" elevation="16" :loading="loading">
          <v-card-title>VAGAS</v-card-title>
          <v-card-subtitle>AQUI VOCÊ VISUALIZA TODAS AS VAGAS, PODE ACESSAR MAIS DETALHES E EXECUTAR AÇÕES</v-card-subtitle>
          <v-btn class="search-button" color="#662929" fab icon elevation="2" @click="$router.push({ name: 'SearchJob' })"><v-icon>fas fa-search</v-icon></v-btn>
          <v-btn class="closed-button" color="#662929" fab icon elevation="2" @click="$router.push({ name: 'ClosedJob' })"><v-icon>fas fa-lock</v-icon></v-btn>
          <hr>
          <div class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th>NOME / RAZÃO SOCIAL</th>
                  <th>IDENTIFICADOR / OCUPAÇÕES</th>
                  <th>INICIO / FIM</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(job, index) in jobs" :key="index" @click="$router.push({ path: `/dashboard/jobs/${job.jobs_code}` })">
                  <td>
                    <div>{{ job.comp_fantasy }}</div>
                    <div>{{ job.comp_name }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_id }}</div>
                    <div>{{ job.occu_name }}</div>
                  </td>
                  <td>
                    <div>{{ job.jobs_start | birth }}</div>
                    <div>{{ job.jobs_end | birth }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Jobs',
  data() {
    return {
      jobs: [],
      loading: true,
    }
  },
  created() {
    this.getJobs()
  },
  methods: {
    // Obtem todas as vagas. //
    getJobs() {
      this.$axios.get(`/jobs/open`).then(success => {
        this.jobs = success.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
  tbody tr {
    cursor: pointer;
  }
  .search-button {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .closed-button {
    position: absolute;
    right: 85px;
    top: 16px;
  }
</style>

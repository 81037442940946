import { render, staticRenderFns } from "./Closed.vue?vue&type=template&id=2a82193f&scoped=true&"
import script from "./Closed.vue?vue&type=script&lang=js&"
export * from "./Closed.vue?vue&type=script&lang=js&"
import style0 from "./Closed.vue?vue&type=style&index=0&id=2a82193f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a82193f",
  null
  
)

export default component.exports
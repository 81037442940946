
<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <v-footer id="system-footer" v-if="$route.name != `Login` && $route.name != `IframeJobs`" color="#662929" app><span class="footer-text">2021 - GRUPO RESOLVE</span></v-footer>

    <v-overlay id="over-cookies" v-model="cookiesDialog" v-show="$route.name == `Home` || $route.name == `Login` || $route.name == `Registry`">
      <div id="cookies-section" class="px-6 py-6">
        <p class="mb-0 text-center">Este site usa cookies para personalizar e melhorar a sua experiência. Ao continuar navegando, você concorda com a nossa <a href="https://site.gruporesolve.com.br/politica-de-privacidade/" target="_blank">Política de Privacidade</a>. <v-btn color="warning" small @click="cookiesDialog = false">ACEITAR E CONTINUAR</v-btn></p>
      </div>
    </v-overlay>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    cookiesDialog: true,
  }),
  created() {
    // verifica se há token, e caso sim, valida e busca usuário. //
    if (localStorage.getItem('miolocat')) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('miolocat')}`
      this.$axios.get(`/users/logged`).then(success => {
        this.$store.commit('SET_USER', {
          id: success.data.id,
          name: success.data.name,
          email: success.data.email,
          type: success.data.type,
          created: success.data.created,
        })
      }).catch(err => {
        localStorage.removeItem('miolocat')
        localStorage.removeItem('miolocat_userType')
      })
    }
  },
  mounted() {
    document.querySelector('#over-cookies').addEventListener('click', () => {
      this.cookiesDialog = false
    })
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style>
  p, h1, h2, h3, h4, h5, h6, span, strong, pre, div {
    text-transform: uppercase;
  }
  .footer-text {
    width: 100%;
    text-align: center;
    color: #dddddd;
    font-weight: 500;
  }
  .whitegr {
    color: #dddddd !important;
  }
  .bottom-detail {
    border-bottom: 6px solid #662929 !important;
  }
  #cookies-section {
    width: 100vw;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #662929;
  }
  #cookies-section a {
    color: white;
    font-weight: bold;
  }
  pre {
    font-family: Roboto,sans-serif !important;
    line-height: 1.5 !important;
  }

  #section-to-print {
    display: none;
  }
  @media print {
    #candidate-container, #no-print, #system-footer, #system-bar, #system-sidebar {
      display: none;
    }
    #section-to-print {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
    }
  }
</style>

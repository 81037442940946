
<template>
  <v-container fluid>
    <v-row>
      <v-col cols='12' sm='12' md='6'>
        <v-card class="bottom-detail" elevation="16" :loading="deletePopup.loading" :disabled="deletePopup.loading">
          <v-card-title>EXCLUIR SUA CONTA</v-card-title>
          <v-card-subtitle>EXCLUIR TODOS OS SEUS DADOS E ACESSO</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-btn color="error" @click="deletePopup.show = true" block>DESEJO CONTINUAR E EXCLUIR</v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols='12' sm='12' md='6'>
        <v-card class="bottom-detail" elevation="16">
          <v-card-title>MUDAR SENHA DE ACESSO</v-card-title>
          <v-card-subtitle>UTILIZE SEMPRE UMA SENHA SEGURA, NÃO INFORME A NINGUEM!</v-card-subtitle>
          <v-divider />
          <div class="pa-4">
            <v-form v-model="form.valid" ref="formPass" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.currentPass" :type="showPass ? 'text' : 'password'" label="SENHA ATUAL" :rules="form.rules.password" :append-icon="showPass ? 'fas fa-eye-slash' : 'fas fa-eye'" @click:append="showPass = !showPass" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.newPass" :type="showPass ? 'text' : 'password'" label="SENHA NOVA" :rules="form.rules.password" :append-icon="showPass ? 'fas fa-eye-slash' : 'fas fa-eye'" @click:append="showPass = !showPass" filled/>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="form.reNewPass" :type="showPass ? 'text' : 'password'" label="CONFIRME A NOVA SENHA" :append-icon="showPass ? 'fas fa-eye-slash' : 'fas fa-eye'" @click:append="showPass = !showPass" :rules="form.rules.rePassword" filled/>
                </v-col>
              </v-row>
            </v-form>
            <v-divider />
            <v-btn color="#662929" class="white--text mt-4" @click="changePass()">SALVAR NOVA SENHA</v-btn>
            <v-alert v-model="form.alert.show" class="mt-6" dense text dismissible :type="form.alert.type">{{ form.alert.message }}</v-alert>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="deletePopup.show" width="400" persistent>
      <v-card :loading="deletePopup.loading" :disabled="deletePopup.loading">
        <v-card-title>EXCLUIR CONTA</v-card-title>
        <v-divider />
        <div class="px-4 pt-4">
          <p>Este processo é irreversivel, você não podera restaurar seu cadastro. Deseja continuar?</p>
          <v-checkbox v-model="deletePopup.continue" label="SIM, CONCORDO!" />
        </div>
        <v-divider />
        <v-row class="ma-0">
          <v-col cols="12" sm="12" md="6">
            <v-btn :disabled="!deletePopup.continue" color="error" @click="deleteAccount()" block>FINALIZAR</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-btn color="warning" @click="deletePopup.show = false" block>VOLTAR</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'CandidateSetup',
  data() {
    return {
      showPass: false,
      form: {
        valid: true,
        currentPass: '',
        newPass: '',
        reNewPass: '',
        rules: {
          password: [
            v => !!v || 'Senha obrigatória.',
            v => v.length >= 6 || 'Senha não pode conter menos que 6 caracteres.',
          ],
          rePassword: [
            v => v == this.form.newPass || 'As senhas devem ser iguais.',
          ],
        },
        alert: {
          show: false,
          type: '',
          message: ''
        }
      },
      deletePopup: {
        show: false,
        continue: false,
        loading: false,
      }
    }
  },
  methods: {
    // Alterar senha de acesso. //
    changePass() {
      if (this.$refs.formPass.validate()) {
        this.$axios.put(`/candidates/pssw`, {
          currentPass: this.form.currentPass,
	        newPass: this.form.newPass
        }).then(success => {
          this.form.alert.type = 'success'
          this.form.alert.message = 'SENHA ALTERADA COM SUCESSO!'
          this.form.alert.show = true
          this.form.currentPass = ''
          this.form.newPass = ''
          this.form.reNewPass = ''
        }).catch(err => {
          this.form.alert.type = 'error'
          this.form.alert.message = 'ERRO AO ALTERAR SENHA!'
          this.form.alert.show = true
        })
      }
    },
    // Excluir conta e dados. //
    deleteAccount() {
      this.deletePopup.loading = true
      this.$axios.delete(`/candidates`).then(success => {
        localStorage.removeItem('miolocat')
        localStorage.removeItem('miolocat_userType')
        this.$store.commit('SET_USER', {
          id: '',
          name: '',
          email: '',
          type: '',
          created: '',
        })
        this.$router.push({ name: 'Home' })
      }).catch(err => {
        this.deletePopup.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>


<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading" class="bottom-detail" elevation="16">
          <v-card-title>EDIÇÃO DE USUÁRIO</v-card-title>
          <v-card-subtitle><strong>CÓDIGO: {{ id }}</strong></v-card-subtitle>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-form v-model="form.valid" ref="userForm" lazy-validation>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="user.user_name" label="NOME COMPLETO" :rules="form.rules.name" counter="60" :disabled="loading" solo/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="user.user_tel" label="TELEFONE FIXO" counter="40" :disabled="loading" solo/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="user.user_cel" label="TELEFONE CELULAR" :disabled="loading" counter="40" solo/>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select v-model="user.user_type" label="TIPO DE ACESSO" :items="types" item-text="name" item-value="code" solo/>
                </v-col>
                <v-col cols="12">
                  <v-alert v-model="errorAlert" border="left" close-text="Close Alert" color="error" dark dismissible>ERRO AO EDITAR USUÁRIOS!</v-alert>
                  <v-divider></v-divider>
                  <v-btn color="#662929" class="mt-4 btn-resize white--text" :loading="loading" @click="updateUser()">SALVAR USUÁRIO(A)</v-btn>
                  <v-btn color="#e98b0d" class="mt-4 btn-resize white--text" :loading="loading" @click="$router.push({ path: `/dashboard/users/${user.user_code}` })">CENCELAR</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'EditUser',
  props: [ 'id' ],
  data() {
    return {
      loading: true,
      errorAlert: false,
      user: {},
      form: {
        valid: true,
        rules: {
          name: [
            v => !!v || 'Nome obrigatório.',
            v => v.length >= 4 || 'Nome não pode conter menos que 4 caracteres.',
            v => v.length <= 60 || 'Nome não pode conter mais que 60 caracteres.',
          ]
        }
      },
      types: [
        { name: 'COMERCIAL', code: 2 },
        { name: 'CONSULTOR(A)', code: 8 },
        { name: 'ADMINISTRATIVO', code: 4 },
      ],
    }
  },
  created() {
    this.getUser()
  },
  methods: {

    // Retorna usuário. //
    getUser() {
      this.$axios.get(`/users/${this.id}`).then(success => {
        this.user = success.data
        this.loading = false
      }).catch(err => {
        this.errorAlert = true
        this.loading = false
      })
    },

    // Salva edição. //
    updateUser() {
      if (this.$refs.userForm.validate()) {
        this.$axios.put(`/users/${this.user.user_code}`, {
          name: this.user.user_name,
          tel: this.user.user_tel,
          cel: this.user.user_cel,
          type: this.user.user_type
        }).then(success => {
          this.$router.push({ path: `/dashboard/users/${this.user.user_code}` })
        }).catch(err => {

        })
      }
    }

  }
}
</script>

<style scoped>
  @media screen and (max-width: 600px) {
    .btn-resize {
      width: 100%;
    }
  }
  @media screen and (min-width: 601px) {
    .btn-resize {
      margin-left: 10px;
    }
  }
</style>

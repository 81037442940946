
<template>
  <v-container>
    <v-app-bar app>
      <img src="@/assets/gpr-logo.png" height="60" class="ml-10 py-2" alt="logo-grupo-resolve">
      <v-spacer></v-spacer>
      <v-btn color="#662929" class="mr-4 whitegr" href="https://vagas.gruporesolve.com.br"><v-icon>fas fa-home</v-icon></v-btn>
      <template v-if="user.id">
        <v-btn v-if="user.type == 0" color="#662929" @click="$router.push({ path: `/candidate` })" class="whitegr">PAINEL DE "{{ user.name }}"</v-btn>
        <v-btn v-else color="#662929" class="whitegr" @click="$router.push({ path: `/dashboard` })">PAINEL DE "{{ user.name }}"</v-btn>
      </template>
      <template v-else>
        <v-btn color="#662929" class="white--text" @click="$router.push({ name: `Login` })">FAZER LOGIN</v-btn>
      </template>
    </v-app-bar>

    <v-card class="mt-6 bottom-detail" elevation="16">
      <v-card-title>DETALHES DA VAGA</v-card-title>
      <v-card-subtitle>CÓDIGO DE CONTROLE: <strong class="text-uppercase">{{ job.jobs_id }}</strong></v-card-subtitle>
      <v-divider />
      <div class="pa-4">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <p><strong>RESIDENTES NAS CIDADES: </strong>{{ job.jobs_city | cities }}</p>
            <p><strong>UNIDADE: </strong>{{ job.unit_name }} - <strong>TELEFONE: </strong> {{ job.unit_tel }}</p>
            <p><strong>RAMO DE ATIVIDADE: </strong>{{ job.bran_name }}</p>
            <p><strong>OCUPAÇÃO: </strong>{{ job.jobs_title }}</p>
            <p><strong>QUANTIDADE DE VAGAS: </strong>{{ job.jobs_qttd }}</p>
            <p><strong>ESCOLARIDADE: </strong>{{ job.scho_name }}</p>
            <p v-if="job.jobs_office_exercised"><strong>CARGO JÁ EXERCIDO: </strong>{{ job.jobs_office_exercised }}</p>
            <template v-if="job.benefits.length > 0">
              <p><strong>BENEFICIOS OFERECIDOS:</strong></p>
              <ul class="mb-4">
                <li v-for="(bene, index) in job.benefits" :key="index">{{ bene.bene_name }} <span v-if="bene.nnbj_value > 0"> - <strong>Valor</strong> R${{ bene.nnbj_value }}</span></li>
              </ul>
            </template>
            <p v-show="job.jobs_salary"><strong>SALARIO: </strong>{{ job.jobs_salary }}</p>
            <p v-show="job.jobs_schedules"><strong>HORÁRIOS: </strong>{{ job.jobs_schedules }}</p>
            <p v-show="job.jobs_cnh != 6"><strong>HABILITADO: </strong>{{ job.jobs_cnh | yes_not }}</p>
            <p v-show="job.jobs_vehicle != 6"><strong>DEVE POSSUIR VEICULO PRÓPRIO? </strong> {{ job.jobs_vehicle | yes_not }}</p>
            <v-divider />
            <v-btn v-if="inscribed" class="white--text" disabled color="#662929" large block>VOCÊ JÁ É UM CANDIDATO(A) DESTA VAGA</v-btn>
            <v-btn v-else-if="user.name && user.type == 0" class="white--text mt-4" color="#662929" @click="dialog = true" large block>QUERO ME CANDIDATAR A ESTA VAGA!</v-btn>
            <v-btn v-else-if="user.type > 0" class="white--text mt-4" color="#662929" disabled large block>VOCÊ É UM COLABORADOR</v-btn>
            <div v-else>
              <v-row class="mt-1">
                <v-col cols="12" sm="12" md="6">
                  <v-btn class="white--text" color="#662929" large block @click="$router.push({ name: 'Registry' })">CADASTRE-SE</v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-btn class="white--text" color="#662929" large block @click="$router.push({ name: 'Login' })">FAZER LOGIN</v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <p><strong>DESCRIÇÃO DA VAGA </strong> {{ job.jobs_description }}</p>
            <template v-if="job.infos.length > 0">
              <p><strong>CURSOS REQUERIDOS:</strong></p>
              <ul class="mb-4">
                <li v-for="(info, index) in job.infos" :key="index">{{ info.info_name }}</li>
              </ul>
            </template>
            <template v-if="job.languages.length > 0">
              <p><strong>IDIOMAS REQUERIDOS:</strong></p>
              <ul class="mb-4">
                <li v-for="(lang, index) in job.languages" :key="index">{{ lang.lang_name }} (<strong>{{ lang.nnjl_level | language }}</strong>)</li>
              </ul>
            </template>
            <!--<iframe
              style="border:0"
              loading="lazy"
              allowfullscreen
              :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyAwtM41wan3nhspt4eVeDjlCpUOLQZgUWc&q=${job.jobs_city},${job.jobs_state}&zoom=11`">
            </iframe>-->
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog v-model="dialog" width="400" persistent>
      <v-card>
        <v-card-title>CONFIRMAR CANDIDATURA</v-card-title>
        <v-divider />
        <div class="pa-4">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-btn color="#828a03" :loading="applyLoading" class="white--text" @click="inscribe()" block>SIM, CONTINUAR!</v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-btn color="#a93121" :disabled="applyLoading" class="white--text" @click="dialog = false" block>NÃO, CANCELAR!</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
export default {
  name: 'DetailsJob',
  props: [ 'code' ],
  data() {
    return {
      job: {},
      dialog: false,
      inscribed: false,
      applyLoading: false,
    }
  },
  created() {
    this.getJob()
  },
  methods: {
    // Obtem vaga e seus componentes. //
    getJob() {
      this.$axios.get(`/home/jobs/${this.code}`).then(success => {
        this.job = success.data
        this.verifyInscribe()
      })
    },
    // Verify inscribe. //
    verifyInscribe() {
      this.$axios.get(`/jobs/verifyapply/${this.job.jobs_id}`).then(success => {
        this.inscribed = success.data.status
      })
    },
    // Candidata-se a vaga em questão. //
    inscribe() {
      this.applyLoading = true
      this.$axios.post(`/jobs/applyfor/${this.code}`).then(success => {
        this.$router.push({ path: '/candidate' })
      }).catch(err => {
        this.applyLoading = false
      })
    }
  },
  computed: {
    user() { return this.$store.getters.GET_USER }
  }
}
</script>

<style scoped>
  iframe {
    width: 100%;
    height: 100%;
    min-height: 50vh;
  }
</style>

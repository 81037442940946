
<template>
  <v-row>
    <v-col cols="12" xs="12" sm="12" md="4">
      <v-card class="top-cards" :loading="loading" elevation="16">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-2">
          <v-icon class="icon-link" @click="$router.push({ name: 'Candidates' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.candidates }}
        </div>
        <div class="card-title py-1">CANDIDATOS</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="4">
      <v-card class="top-cards" :loading="loading" elevation="16">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-2">
          <v-icon class="icon-link" @click="$router.push({ name: 'Companies' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.companies }}
        </div>
        <div class="card-title py-1">EMPRESAS</div>
      </v-card>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="4">
      <v-card class="top-cards" :loading="loading" elevation="16">
        <template slot="progress">
          <v-progress-linear color="grey" indeterminate></v-progress-linear>
        </template>
        <div class="card-value py-2">
          <v-icon class="icon-link" @click="$router.push({ name: 'Jobs' })">fas fa-external-link-square-alt</v-icon>
          {{ registries.jobs }}
        </div>
        <div class="card-title py-1">VAGAS</div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ResumeCards',
  data() {
    return {
      loading: true,
      registries: {
        "candidates": 0,
        "companies": 0,
        "jobs": 0,
      }
    }
  },
  created() {
    this.$axios.get(`/dashboard/top-cards`).then(success => {
      this.registries = success.data
      this.loading = false
    })
  }
}
</script>

<style scoped>
  .top-cards {
    border-bottom: 6px solid #662929 !important;
  }
  .top-cards .card-value {
    color: #dddddd;
    background-color: #662929;
    text-align: center;
    font-size: 2.6em;
    font-weight: 500;
  }
  .top-cards .card-value .icon-link {
    position: absolute;
    right: 10px;
    color: #dddddd;
    cursor: pointer;
  }
  .top-cards .card-value .icon-link:hover {
    color: white;
  }
  .top-cards .card-title {
    color: #662929;
    background-color: #dddddd;
    text-align: center;
    font-weight: 500;
  }
</style>


<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>VAGA DE TODOS OS CONSULTORES</v-card-title>
          <v-divider />
          <div class="pa-4">
            <v-row>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>NOME / RAZÃO SOCIAL</th>
                      <th>TITULO / PROFISSÃO</th>
                      <th>INICIO / FIM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(job, index) in jobs" :key="index" @click="$router.push({ path: `/consultant/jobs/${job.jobs_code}` })">
                      <td>
                        <div>{{ job.comp_fantasy }}</div>
                        <div>{{ job.comp_name }}</div>
                      </td>
                      <td>
                        <div>{{ job.jobs_title }}</div>
                        <div>{{ job.occu_name }}</div>
                      </td>
                      <td>
                        <div>{{ job.jobs_start | birth }}</div>
                        <div>{{ job.jobs_end | birth }}</div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AllJobs',
  data() {
    return {
      jobs: []
    }
  },
  created() {
    this.getJobs()
  },
  methods: {
    getJobs() {
      this.$axios.get(`/jobs/consultant`).then(success => {
        this.jobs = success.data.jobs
      })
    }
  }
}
</script>

<style scoped>

</style>
